import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from '@mui/material';
import { useModalStore } from 'app/stores/modalStore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Banner from './Banner';
import Category from './Category';

const Home = () => {
  const {
    homepageDisclaimerOpen,
    homepageDisclaimerId,
    setHomepageDisclaimerOpen,
  } = useModalStore();
  const navigate = useNavigate();

  return (
    <Box>
      <Banner />
      <Category />
      {/* add product popup */}
      <Dialog
        open={homepageDisclaimerOpen}
        onClose={() => setHomepageDisclaimerOpen(false, -1)}
        scroll="body"
        fullWidth
        maxWidth="md"
      >
        {homepageDisclaimerId === 9 && (
          <>
            <DialogTitle>Disclaimer for Field Apparel</DialogTitle>
            <DialogContent>
              <Alert severity="info" sx={{ fontSize: 20 }}>
                The Field Apparel Store is intended for those in positions that
                have been determined as full time in the field. All others may
                purchase items through payroll deductions at checkout.
                Additional Items will be added throughout the year, so check
                back often!
              </Alert>
            </DialogContent>
          </>
        )}

        {homepageDisclaimerId === 7 && (
          <>
            <DialogTitle>
              Disclaimer for Client, Events & Office Promo Material
            </DialogTitle>
            <DialogContent>
              <Alert severity="info" sx={{ fontSize: 20 }}>
                The promotional items in this store are intended for those
                responsible for promotional office stock, client gifts and large
                events. All promotional items for personal use may be purchased
                through our{' '}
                <Link href="https://business.landsend.com/store/colliersengineering/">
                  CED Land's End Store
                </Link>
                . Please contact{' '}
                <Link href="mailto:carol.rech@collierseng.com">Carol Rech</Link>{' '}
                with any questions or special requests.
              </Alert>
            </DialogContent>
          </>
        )}

        {homepageDisclaimerId === 5 && (
          <>
            <DialogTitle>
              Disclaimer for Tradeshow Supplies and Brochures
            </DialogTitle>
            <DialogContent>
              <Alert severity="info" sx={{ fontSize: 20 }}>
                The Tradeshow Supplies are intended for conference/event
                attendees only. Due to a heavy volume of conference exhibiting,
                we need to ensure our CED exhibitors have the appropriate
                signage and presence at these events. Please contact{' '}
                <Link href="mailto:EventsTeam@collierseng.com">
                  EventsTeam@collierseng.com
                </Link>{' '}
                with any inquiries or questions regarding usage.
              </Alert>
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Button
            onClick={() => {
              navigate(`/inventory/${homepageDisclaimerId}`);
              setHomepageDisclaimerOpen(false, -1);
            }}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Box height={128}></Box>
    </Box>
  );
};

export default Home;
