import { Box, Grid, TextField } from '@mui/material';
import React from 'react';

interface RequestTextFieldProps {
  label: string;
  onChange: (...args: any[]) => void;
  value?: string | number | boolean;
  type?: string;
  helperText?: JSX.Element | string;
  required?: boolean;
  error?: boolean;
}

const RequestTextField = ({
  label,
  onChange,
  value,
  type,
  helperText,
  required,
  error,
}: RequestTextFieldProps) => {
  return (
    <Grid item xs={12} lg>
      <Box m={1}>
        <TextField
          label={label}
          fullWidth
          variant="standard"
          value={value}
          onChange={onChange}
          type={type}
          InputProps={{
            inputProps: { min: 1 },
          }}
          helperText={helperText}
          required={required}
          error={error}
          sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
        />
      </Box>
    </Grid>
  );
};

export default RequestTextField;
