import { Box } from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import NotFound from 'app/common/modals/NotFound';
import { IOrder } from 'app/models/order';
import OrderContent from 'features/admin/order/OrderContent';
import React from 'react';
import { useLocation } from 'react-router-dom';

const OrderConfirm = () => {
  /* State and functions from custom hooks */
  const location = useLocation();
  const order = location?.state?.order as IOrder;

  /* Render not found when order is empty */
  if (!order) return <NotFound />;

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={false} message="Thank you for your order!" />
      </Box>
      {/* Order content */}
      <Box m={2}>
        <OrderContent order={order} />
      </Box>

      {/* Footer spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default OrderConfirm;
