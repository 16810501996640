import Agent from 'app/api/agent';
import { ICedCoinItem, ICedCoinOrder } from 'app/models/cedCoin';

import { AxiosResponse } from 'axios';
import { create } from 'zustand';

interface ICedCoinStore {
  /* submit cedCoin order */
  submitCedCoinOrder: (
    cedCoinOrder: ICedCoinOrder
  ) => Promise<void | AxiosResponse>;

  /* adjust cedCoin balance */
  adjustCedCoin: (cedCoinOrder: ICedCoinOrder) => Promise<void | AxiosResponse>;

  /* fetch all the cedCoin items */
  totalCedCoinItemList: ICedCoinItem[];
  accumulatedCoinItemList: ICedCoinItem[];
  totalCedCoinItemListLoading: boolean;
  setTotalCedCoinItemList: () => void;

  /* fetch cedCoin items by email */
  cedCoinItemList: ICedCoinItem[];
  cedCoinItemListLoading: boolean;
  userCedCoinBalance: number;
  setCedCoinItemList: (email: string) => void;

  /* delete cedCoin */
  deleteCedCoin: (
    email: string,
    deletedBy: string
  ) => Promise<void | AxiosResponse>;
}

export const useCedCoinStore = create<ICedCoinStore>((set) => ({
  /* submit cedCoin order */
  submitCedCoinOrder: async (cedCoinOrder) => {
    return Agent.CedCoin.createCedCoinOrder(cedCoinOrder)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* adjust cedCoin balance */
  adjustCedCoin: async (cedCoinOrder) => {
    return Agent.CedCoin.adjustCedCoin(cedCoinOrder)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* fetch all the cedCoin items */
  totalCedCoinItemList: [],
  totalCedCoinItemListLoading: false,
  accumulatedCoinItemList: [],
  setTotalCedCoinItemList: () => {
    set({ totalCedCoinItemListLoading: true });
    Agent.CedCoin.loadAllCedCoins()
      .then((response) => {
        const transformedArray = Object.values(
          response.reduce((accumulator: any, currentItem: ICedCoinItem) => {
            const { email, balance, issuedAmount, isDeleted, ...rest } =
              currentItem;

            if (!isDeleted) {
              if (accumulator[email]) {
                accumulator[email].balance += balance;
                accumulator[email].issuedAmount += issuedAmount;
              } else {
                accumulator[email] = {
                  email,
                  balance,
                  issuedAmount,
                  ...rest,
                } as ICedCoinItem;
              }
            }

            return accumulator;
          }, {})
        );

        set({
          accumulatedCoinItemList: transformedArray as ICedCoinItem[],
          totalCedCoinItemList: response,
          totalCedCoinItemListLoading: false,
        });
      })
      .catch((error) =>
        set({
          totalCedCoinItemListLoading: false,
        })
      );
  },

  /* fetch cedCoin items by email */
  cedCoinItemList: [],
  cedCoinItemListLoading: false,
  userCedCoinBalance: 0,
  setCedCoinItemList: (email) => {
    set({ cedCoinItemListLoading: true });
    Agent.CedCoin.loadCedCoins(email)
      .then((response) => {
        set({
          cedCoinItemList: response,
          userCedCoinBalance: response.reduce(
            (sum, item) => (item.isDeleted ? sum : sum + item.balance),
            0
          ),
          cedCoinItemListLoading: false,
        });
      })
      .catch(() => set({ cedCoinItemListLoading: false }));
  },

  /* delete cedCoin */
  deleteCedCoin: async (email, deletedBy) => {
    return Agent.CedCoin.deleteCedCoin(email, deletedBy)
      .then((response) => response)
      .catch((error) => console.log(error));
  },
}));
