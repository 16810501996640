import { useEffect, useState } from 'react';
import agent from './agent';

interface UseGetQueryProps {
  data: string | null;
  loading: boolean;
  error: string;
}

const UseGetQuery = (email?: string): UseGetQueryProps => {
  const [data, setData] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (email) {
      setLoading(true);
      agent.Graph.loadRequesterPhoto(email)
        .then((response) => {
          const url = window.URL || window.webkitURL;
          const blobUrl = url.createObjectURL(response);

          setData(blobUrl);
          setLoading(false);
          setError('');
        })
        .catch((error) => {
          setError(error?.message);
          setLoading(false);
        });
    }
  }, [email]);

  return { data, loading, error };
};

export default UseGetQuery;
