import ListAltIcon from '@mui/icons-material/ListAlt';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Button, Grid } from '@mui/material';
import { useSmallScreen } from 'app/common/util/util';
import { IOrder } from 'app/models/order';
import { useModalStore } from 'app/stores/modalStore';
import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import OrderContent from './OrderContent';

export interface OrderPreviewProps {
  order?: IOrder;
}

const OrderPreview = ({ order }: OrderPreviewProps) => {
  /* State and functions from custom hooks */
  const { setOrderPreviewOpen } = useModalStore();
  const smallScreen = useSmallScreen();

  /* Reference to the component for printing */
  const componentRef = useRef(null);

  return (
    <Box>
      <Grid container spacing={smallScreen ? 0 : 2}>
        {/* Component to handle the order */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          {/* Button to manage the order */}
          <Link to={`/admin/order/${order?.id}`}>
            <Button
              variant="contained"
              sx={{ width: '100%', mb: 2, mt: 2 }}
              onClick={() => setOrderPreviewOpen(false)}
              startIcon={<ListAltIcon />}
            >
              Manage Order
            </Button>
          </Link>
          {/* Button to print the order */}
          <ReactToPrint
            content={() => componentRef.current}
            trigger={() => (
              <Button
                variant="contained"
                sx={{ width: '100%', mb: 2, mt: 2 }}
                startIcon={<PrintIcon />}
              >
                Print Order
              </Button>
            )}
          />
        </Grid>
        {/* Component displaying the order content */}
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <OrderContent order={order} ref={componentRef} />
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderPreview;
