import AddIcon from '@mui/icons-material/Add';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import GradeIcon from '@mui/icons-material/Grade';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@mui/lab';
import {
  Alert,
  Box,
  Button,
  Chip,
  Grid,
  IconButton,
  Paper,
  SelectChangeEvent,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { ppeItems } from 'app/common/options/ppeOptions';
import { IRequest, IRequestItem } from 'app/models/request';
import { useModalStore } from 'app/stores/modalStore';
import { useRequestStore } from 'app/stores/requestStore';
import { useUserStore } from 'app/stores/userStore';
import RequestDropdown from 'features/PPERequest/RequestDropdown';
import RequestTextField from 'features/PPERequest/RequestTextField';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

const RequestDetails = () => {
  /* State and functions from custom hooks */
  const { requestPageSize, requestPage, requestId }: any = useParams();
  const {
    requestForReview,
    requestForReviewLoading,
    setRequestForReview,
    handleRequest,
  } = useRequestStore();
  const { user } = useUserStore();
  const { setSnackBar } = useModalStore();
  const navigate = useNavigate();
  const componentRef = useRef(null);

  /* Define component state variables */
  const [request, setRequest] = useState<IRequest>({} as IRequest);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const initialItemState = {
    category: '',
    item: '',
    type: '',
    color: '',
    size: '',
    make: '',
    model: '',
    quantity: undefined,
    isStandard: undefined,
    itemId: undefined,
    isAdminAdded: true,
  };

  /* Function to handle removing item */
  const removeItem = (employeeIndex: number, itemIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    const updatedEmployee = { ...updatedEmployees[employeeIndex] };
    updatedEmployee.requestItems.splice(itemIndex, 1);
    updatedEmployees[employeeIndex] = updatedEmployee;
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  /* Function to handle adding item */
  const addItem = (employeeIndex: number) => {
    const updatedEmployees = [...request.requestEmployees];
    const updatedEmployee = { ...updatedEmployees[employeeIndex] };
    updatedEmployee.requestItems.push({ ...initialItemState });
    updatedEmployees[employeeIndex] = updatedEmployee;
    setRequest({
      ...request,
      requestEmployees: updatedEmployees,
    });
  };

  /* Handle form submission */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    handleRequest(request)
      .then((response) => {
        if (response?.status === 200) {
          setRequestForReview(requestId);
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Request submitting is not completed.`,
          });
        }
        setSubmitLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Fetch request details on component mount */
  useEffect(() => {
    setRequestForReview(requestId);
  }, [requestId, setRequestForReview]);

  /* Update request details when fetched */
  useEffect(() => {
    if (requestForReview) {
      const updatedRequest: IRequest = {
        ...requestForReview,
        requestEmployees: requestForReview.requestEmployees.map((employee) => ({
          ...employee,
          status:
            employee.status === 'Pending' ||
            employee.status === 'Additional Review'
              ? 'Approved'
              : employee.status,
        })),
        status: 'Reviewed',
        reviewer: user?.displayName!,
      };
      setRequest(updatedRequest);
    }
  }, [requestForReview, user]);

  /* Render loading state while request details are loading */
  /* Render not found state if request details are not available */
  if (requestForReviewLoading) {
    return <Loading />;
  } else if (!requestForReview) {
    return <NotFound />;
  }

  console.log(request);

  return (
    <Box mx={2} mt={2} component="form" onSubmit={handleSubmit}>
      {/* Buttons for navigation and printing */}
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            navigate(`/admin/ppeRequest/${requestPageSize}/${requestPage}`);
          }}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Request
            </Button>
          )}
        />
      </Box>
      <Box ref={componentRef}>
        {/* Request header */}
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            margin: 2,
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Request ID # : {requestForReview.id}
            </Typography>
            {requestForReview.newHire && (
              <Button
                variant="text"
                startIcon={<GradeIcon />}
                color="secondary"
                sx={{ m: 1 }}
              >
                New Hire
              </Button>
            )}
          </Box>
          {/* show request status */}
          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            sx={{
              bgcolor: `status${requestForReview.status.replaceAll(
                ' ',
                ''
              )}.light`,
              color: `status${requestForReview.status.replaceAll(
                ' ',
                ''
              )}.main`,
              borderColor: `status${requestForReview.status.replaceAll(
                ' ',
                ''
              )}.main`,
              '&:hover': {
                bgcolor: `status${requestForReview.status.replaceAll(
                  ' ',
                  ''
                )}.dark`,
              },
            }}
          >
            {requestForReview.status}
          </Button>
        </Toolbar>
        {/* Request details table */}
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    backgroundColor: 'secondary.dark',
                  },
                }}
              >
                <TableCell>Request Date</TableCell>
                <TableCell>Requester</TableCell>
                <TableCell>Requester Email</TableCell>
                <TableCell>Discipline</TableCell>
                <TableCell>DSC</TableCell>
                <TableCell>Reviewer</TableCell>
                <TableCell>Reviewed Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {new Date(
                    requestForReview.createdTime as Date
                  ).toLocaleDateString()}
                </TableCell>
                <TableCell>{requestForReview.requester}</TableCell>
                <TableCell>{requestForReview.requesterEmail}</TableCell>
                <TableCell>{requestForReview.discipline}</TableCell>
                <TableCell>{requestForReview.dsc}</TableCell>
                <TableCell>{requestForReview.reviewer}</TableCell>
                {requestForReview.reviewed && (
                  <TableCell>
                    {new Date(
                      requestForReview.reviewed as Date
                    ).toLocaleDateString()}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* Request details table for each employee */}
        <Box m={2}>
          {(requestForReview.status === 'Requested' ||
          requestForReview.status === 'Awaiting Additional Review'
            ? request
            : requestForReview
          )?.requestEmployees?.map((e, employeeIndex) => (
            <Box key={e.id}>
              {/* print page break */}
              <Box
                sx={{
                  '@media print': {
                    marginTop: '1rem',
                    display: 'block',
                    pageBreakBefore: 'always',
                  },
                }}
              ></Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Chip label={employeeIndex + 1} sx={{ m: 1 }} />
                </Box>
                <TableContainer component={Paper} elevation={2}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          '& th': {
                            color: 'white',
                            backgroundColor: 'primary.main',
                          },
                        }}
                      >
                        <TableCell>Employee Number</TableCell>
                        <TableCell>Recipient Name</TableCell>
                        <TableCell>Co Brand</TableCell>
                        <TableCell>Office</TableCell>
                        <TableCell>Ship To</TableCell>
                        <TableCell>Training Completed</TableCell>
                        {requestForReview.newHire && (
                          <TableCell>WorkFit</TableCell>
                        )}

                        <TableCell>Project No</TableCell>
                        <TableCell>Task</TableCell>
                        <TableCell>Org</TableCell>
                        <TableCell>Comments</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{e.employeeNumber}</TableCell>
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.coBrand}</TableCell>
                        <TableCell>{e.office}</TableCell>
                        <TableCell>{e.shipTo}</TableCell>
                        <TableCell>
                          {e.trainingCompleted ? 'Yes' : 'No'}
                        </TableCell>
                        {requestForReview.newHire && (
                          <TableCell>{e.workFit ? 'Yes' : 'No'}</TableCell>
                        )}

                        <TableCell>{e.projectNo}</TableCell>
                        <TableCell>{e.task}</TableCell>
                        <TableCell>{e.org}</TableCell>
                        <TableCell>{e.comments}</TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>

                {/* Request details table for each item */}
                <Box m={2}>
                  <TableContainer component={Paper} elevation={1} key={e.id}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            '& th': {
                              backgroundColor: 'secondary.dark',
                            },
                          }}
                        >
                          <TableCell>Category</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Size</TableCell>
                          <TableCell>Color</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell
                            align={
                              requestForReview.status === 'Requested' ||
                              requestForReview.status ===
                                'Awaiting Additional Review'
                                ? 'center'
                                : 'left'
                            }
                          >
                            Quantity
                          </TableCell>
                          {(requestForReview.status === 'Requested' ||
                            requestForReview.status ===
                              'Awaiting Additional Review') && (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {e?.requestItems.map((i, itemIndex) => {
                          if (!i.isAdminAdded) {
                            return (
                              <TableRow key={itemIndex}>
                                <TableCell>
                                  {i.isStandard ? 'Standard' : 'Non-Standard'}
                                </TableCell>
                                <TableCell>{i.item}</TableCell>
                                <TableCell>{i.type}</TableCell>
                                <TableCell>{i.size}</TableCell>
                                <TableCell>{i.color}</TableCell>
                                <TableCell>{i.make}</TableCell>
                                <TableCell>{i.model}</TableCell>
                                {requestForReview.status === 'Requested' ||
                                requestForReview.status ===
                                  'Awaiting Additional Review' ? (
                                  <TableCell align="center">
                                    <Button
                                      disableRipple
                                      size="small"
                                      startIcon={
                                        <IndeterminateCheckBoxIcon
                                          sx={{
                                            m: { xs: 0, sm: 1 },
                                            '&:hover': {
                                              color: 'secondary.main',
                                            },
                                            color: 'green',
                                          }}
                                          onClick={() =>
                                            setRequest((prevRequest) => ({
                                              ...prevRequest,
                                              requestEmployees:
                                                prevRequest.requestEmployees.map(
                                                  (employee, empIndex) => {
                                                    if (
                                                      empIndex === employeeIndex
                                                    ) {
                                                      return {
                                                        ...employee,
                                                        requestItems:
                                                          employee.requestItems.map(
                                                            (item, idx) => {
                                                              if (
                                                                idx ===
                                                                itemIndex
                                                              ) {
                                                                if (
                                                                  !item.quantity
                                                                )
                                                                  return item;
                                                                if (
                                                                  item.quantity ===
                                                                    1 &&
                                                                  employee
                                                                    .requestItems
                                                                    .length > 1
                                                                )
                                                                  removeItem(
                                                                    employeeIndex,
                                                                    itemIndex
                                                                  );
                                                                return {
                                                                  ...item,
                                                                  quantity:
                                                                    item.quantity >
                                                                    1
                                                                      ? item.quantity -
                                                                        1
                                                                      : 1,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return employee;
                                                  }
                                                ),
                                            }))
                                          }
                                        />
                                      }
                                      endIcon={
                                        <AddBoxIcon
                                          sx={{
                                            m: { xs: 0, sm: 1 },
                                            '&:hover': {
                                              color: 'secondary.main',
                                            },
                                            color: 'green',
                                          }}
                                          onClick={() =>
                                            setRequest((prevRequest) => ({
                                              ...prevRequest,
                                              requestEmployees:
                                                prevRequest.requestEmployees.map(
                                                  (employee, empIndex) => {
                                                    if (
                                                      empIndex === employeeIndex
                                                    ) {
                                                      return {
                                                        ...employee,
                                                        requestItems:
                                                          employee.requestItems.map(
                                                            (item, idx) => {
                                                              if (
                                                                idx ===
                                                                itemIndex
                                                              ) {
                                                                if (
                                                                  !item.quantity
                                                                )
                                                                  return item;

                                                                return {
                                                                  ...item,
                                                                  quantity:
                                                                    item.quantity +
                                                                    1,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return employee;
                                                  }
                                                ),
                                            }))
                                          }
                                        />
                                      }
                                      sx={{
                                        color: 'black',
                                        border: 'none',
                                        '&:hover': {
                                          bgcolor: 'transparent',
                                        },
                                      }}
                                    >
                                      {i.quantity}
                                    </Button>
                                  </TableCell>
                                ) : (
                                  <TableCell>{i.quantity}</TableCell>
                                )}

                                {(requestForReview.status === 'Requested' ||
                                  requestForReview.status ===
                                    'Awaiting Additional Review') && (
                                  <TableCell align="center">
                                    <IconButton
                                      aria-label="delete"
                                      size="large"
                                      color="primary"
                                      onClick={() =>
                                        removeItem(employeeIndex, itemIndex)
                                      }
                                      disabled={e.requestItems.length === 1}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </TableCell>
                                )}
                              </TableRow>
                            );
                          }
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  {(requestForReview.status === 'Requested' ||
                    requestForReview.status ===
                      'Awaiting Additional Review') && (
                    <Box sx={{ m: 2, bgcolor: 'secondary.dark', p: 1 }}>
                      {/* add item button */}
                      <Box
                        display="flex"
                        justifyContent="left"
                        alignItems="center"
                        m={1}
                      >
                        <Button
                          onClick={() => addItem(employeeIndex)}
                          startIcon={<AddIcon />}
                        >
                          Add Equipment
                        </Button>
                      </Box>
                      {e.requestItems.map((item, itemIndex) => {
                        if (item.isAdminAdded) {
                          return (
                            <Grid
                              container
                              spacing={2}
                              columns={{ md: 66 }}
                              key={itemIndex}
                              m={1}
                            >
                              {/* item detail fields */}
                              <Grid item xs md={60}>
                                <Grid container spacing={2}>
                                  <RequestDropdown
                                    label="Equipment"
                                    onChange={(event: SelectChangeEvent) =>
                                      setRequest((prevRequest) => ({
                                        ...prevRequest,
                                        requestEmployees:
                                          prevRequest.requestEmployees.map(
                                            (employee, empIndex) => {
                                              if (empIndex === employeeIndex) {
                                                return {
                                                  ...employee,
                                                  requestItems:
                                                    employee.requestItems.map(
                                                      (item, idx) => {
                                                        console.log(item);
                                                        if (idx === itemIndex) {
                                                          const isStandard =
                                                            event.target
                                                              .value !==
                                                            'Other Non-Standard Equipment';
                                                          return {
                                                            category:
                                                              event.target
                                                                .value,
                                                            isStandard:
                                                              isStandard,
                                                            item: isStandard
                                                              ? event.target
                                                                  .value
                                                              : '',
                                                            type: '',
                                                            color: '',
                                                            size: '',
                                                            make: '',
                                                            model: '',
                                                            quantity: undefined,
                                                            isAdminAdded: true,
                                                          };
                                                        }
                                                        return item;
                                                      }
                                                    ),
                                                };
                                              }
                                              return employee;
                                            }
                                          ),
                                      }))
                                    }
                                    value={item.category}
                                    options={ppeItems.map((i) => i.category)}
                                    required
                                  />

                                  {ppeItems
                                    .find((i) => i.category === item.category)
                                    ?.dropdownFields?.map((fieldItem) => (
                                      <RequestDropdown
                                        key={fieldItem.field}
                                        label={fieldItem.label}
                                        onChange={(event: SelectChangeEvent) =>
                                          setRequest((prevRequest) => ({
                                            ...prevRequest,
                                            requestEmployees:
                                              prevRequest.requestEmployees.map(
                                                (employee, empIndex) => {
                                                  if (
                                                    empIndex === employeeIndex
                                                  ) {
                                                    return {
                                                      ...employee,
                                                      requestItems:
                                                        employee.requestItems.map(
                                                          (item, idx) => {
                                                            if (
                                                              idx === itemIndex
                                                            ) {
                                                              return {
                                                                ...item,
                                                                [fieldItem.field]:
                                                                  event.target
                                                                    .value,
                                                              };
                                                            }
                                                            return item;
                                                          }
                                                        ),
                                                    };
                                                  }
                                                  return employee;
                                                }
                                              ),
                                          }))
                                        }
                                        value={
                                          item[
                                            fieldItem.field as keyof IRequestItem
                                          ]
                                        }
                                        options={
                                          (
                                            ppeItems.find(
                                              (i) =>
                                                i.category === item.category
                                            ) as any
                                          )?.[fieldItem.field] || []
                                        }
                                        required
                                      />
                                    ))}
                                  {ppeItems
                                    .find((i) => i.category === item.category)
                                    ?.textFields?.map((fieldItem) => (
                                      <RequestTextField
                                        key={fieldItem.field}
                                        label={fieldItem.label}
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) =>
                                          setRequest((prevRequest) => ({
                                            ...prevRequest,
                                            requestEmployees:
                                              prevRequest.requestEmployees.map(
                                                (employee, empIndex) => {
                                                  if (
                                                    empIndex === employeeIndex
                                                  ) {
                                                    return {
                                                      ...employee,
                                                      requestItems:
                                                        employee.requestItems.map(
                                                          (item, idx) => {
                                                            if (
                                                              idx === itemIndex
                                                            ) {
                                                              return {
                                                                ...item,
                                                                [fieldItem.field]:
                                                                  event.target
                                                                    .value,
                                                              };
                                                            }
                                                            return item;
                                                          }
                                                        ),
                                                    };
                                                  }
                                                  return employee;
                                                }
                                              ),
                                          }))
                                        }
                                        value={
                                          item[
                                            fieldItem.field as keyof IRequestItem
                                          ] || ''
                                        }
                                        type={
                                          fieldItem.field === 'quantity'
                                            ? 'number'
                                            : 'text'
                                        }
                                        required
                                      />
                                    ))}
                                </Grid>
                              </Grid>
                              {/* remove item button */}
                              <Grid item xs md={6}>
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    mt: 2,
                                  }}
                                >
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    color="primary"
                                    onClick={() =>
                                      removeItem(employeeIndex, itemIndex)
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </Box>
                              </Grid>
                            </Grid>
                          );
                        }
                      })}
                    </Box>
                  )}

                  {(requestForReview.status === 'Requested' ||
                    requestForReview.status ===
                      'Awaiting Additional Review') && (
                    <Box m={2}>
                      <TextField
                        label="Comments"
                        multiline
                        fullWidth
                        variant="standard"
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            requestEmployees: prevRequest.requestEmployees.map(
                              (employee, index) => {
                                if (index === employeeIndex) {
                                  return {
                                    ...employee,
                                    comments: event.target.value,
                                  };
                                }
                                return employee;
                              }
                            ),
                          }))
                        }
                        value={e.comments ?? ''}
                      />
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    m: 2,
                  }}
                >
                  {requestForReview.status === 'Awaiting Additional Review' &&
                    requestForReview.requestEmployees[employeeIndex]?.status ===
                      'Additional Review' && (
                      <Button
                        variant="contained"
                        size="small"
                        style={{ marginLeft: 16 }}
                        sx={{
                          bgcolor: `statusAdditionalReview.light`,
                          color: `statusAdditionalReview.main`,
                          borderColor: `statusAdditionalReview.main`,
                          '&:hover': {
                            bgcolor: `statusAdditionalReview.dark`,
                          },
                        }}
                      >
                        Additional Review
                      </Button>
                    )}
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {requestForReview.status === 'Requested' ||
                  (requestForReview.status === 'Awaiting Additional Review' &&
                    requestForReview.requestEmployees[employeeIndex]?.status ===
                      'Additional Review') ? (
                    <Stack direction="row" spacing={1} alignItems="center">
                      <Typography>Reject</Typography>
                      <Switch
                        defaultChecked
                        sx={{
                          '&.MuiSwitch-root .MuiSwitch-switchBase': {
                            color: 'red',
                          },

                          '&.MuiSwitch-root .Mui-checked': {
                            color: 'green',
                          },
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) => {
                          setRequest((prevRequest) => ({
                            ...prevRequest,
                            requestEmployees: prevRequest.requestEmployees.map(
                              (employee, index) => {
                                if (index === employeeIndex) {
                                  return {
                                    ...employee,
                                    status: event.target.checked
                                      ? 'Approved'
                                      : 'Rejected',
                                  };
                                }
                                return employee;
                              }
                            ),
                          }));
                        }}
                      />
                      <Typography>Approve</Typography>
                    </Stack>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      style={{ marginLeft: 16 }}
                      sx={{
                        bgcolor: `status${e.status.replaceAll(' ', '')}.light`,
                        color: `status${e.status.replaceAll(' ', '')}.main`,
                        borderColor: `status${e.status.replaceAll(
                          ' ',
                          ''
                        )}.main`,
                        '&:hover': {
                          bgcolor: `status${e.status.replaceAll(' ', '')}.dark`,
                        },
                      }}
                    >
                      {e.status}
                    </Button>
                  )}
                </Box>
                <Box></Box>
              </Box>
            </Box>
          ))}
        </Box>

        {/* submit button */}
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={10}
          ml={2}
          mr={2}
        >
          {requestForReview.status === 'Requested' ||
          requestForReview.status === 'Awaiting Additional Review' ? (
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              loading={submitLoading}
            >
              Submit
            </LoadingButton>
          ) : (
            <Alert severity="info">
              This request has been reviewed and processed.
            </Alert>
          )}
        </Box>
        {/* Space filler for footer top */}
        <Box height={128}></Box>
      </Box>
    </Box>
  );
};

export default RequestDetails;
