import { ISnackBar } from 'app/models/modal';
import { create } from 'zustand';

interface IModalStore {
  /* order preview popup */
  orderPreviewOpen: boolean;
  setOrderPreviewOpen: (open: boolean) => void;

  /* search popup */
  searchOpen: boolean;
  setSearchOpen: (open: boolean) => void;

  /* snackBar popup */
  snackBar: ISnackBar;
  setSnackBar: (snackBar: ISnackBar) => void;

  /* add product popup */
  addProductOpen: boolean;
  setAddProductOpen: (open: boolean) => void;

  /* add cedCoin popup */
  addCedCoinOpen: boolean;
  setAddCedCoinOpen: (open: boolean) => void;

  /* replace cart popup */
  replaceCartOpen: boolean;
  setReplaceCartOpen: (open: boolean) => void;

  /* about popup */
  aboutOpen: boolean;
  setAboutOpen: (open: boolean) => void;

  /* homepage disclaimer popup */
  homepageDisclaimerOpen: boolean;
  homepageDisclaimerId: number;
  setHomepageDisclaimerOpen: (open: boolean, catId: number) => void;

  /* edit product confirm popup */
  editProductOpen: boolean;
  setEditProductOpen: (open: boolean) => void;

  /* add customized items confirm popup */
  addCustomizedItemsOpen: boolean;
  setAddCustomizedItemsOpen: (open: boolean) => void;

  /* delete cedcoin confirm popup */
  deleteCedCoinOpen: boolean;
  setDeleteCedCoinOpen: (open: boolean) => void;

  /* delete cedcoin successfully popup */
  deleteCedCoinSuccessOpen: boolean;
  setDeleteCedCoinSuccessOpen: (open: boolean) => void;

  /* mark ppe request order shipped confirm popup */
  requestOrderShippedOpen: boolean;
  setRequestOrderShippedOpen: (open: boolean) => void;
}

export const useModalStore = create<IModalStore>((set) => ({
  /* search popup */
  searchOpen: false,
  setSearchOpen: (open) => {
    set({
      searchOpen: open,
    });
  },

  /* order preview popup */
  orderPreviewOpen: false,
  setOrderPreviewOpen: (open) => {
    set({
      orderPreviewOpen: open,
    });
  },

  /* snackBar popup */
  snackBar: { open: false, type: 'success', content: '' },
  setSnackBar: (snackBar) => {
    set({
      snackBar: snackBar,
    });
  },

  /* add product popup */
  addProductOpen: false,
  setAddProductOpen: (open) => {
    set({
      addProductOpen: open,
    });
  },

  /* add cedCoin popup */
  addCedCoinOpen: false,
  setAddCedCoinOpen: (open) => {
    set({
      addCedCoinOpen: open,
    });
  },

  /* replace cart popup */
  replaceCartOpen: false,
  setReplaceCartOpen: (open) => {
    set({
      replaceCartOpen: open,
    });
  },

  /* about popup */
  aboutOpen: false,
  setAboutOpen: (open) => {
    set({
      aboutOpen: open,
    });
  },

  /* homepage disclaimer popup */
  homepageDisclaimerOpen: false,
  homepageDisclaimerId: -1,
  setHomepageDisclaimerOpen: (open, catId) => {
    set({
      homepageDisclaimerOpen: open,
      homepageDisclaimerId: catId,
    });
  },

  /* edit product confirm popup */
  editProductOpen: false,
  setEditProductOpen: (open) => {
    set({
      editProductOpen: open,
    });
  },

  /* add customized items confirm popup */
  addCustomizedItemsOpen: false,
  setAddCustomizedItemsOpen: (open) => {
    set({
      addCustomizedItemsOpen: open,
    });
  },

  /* delete cedcoin confirm popup */
  deleteCedCoinOpen: false,
  setDeleteCedCoinOpen: (open) => {
    set({
      deleteCedCoinOpen: open,
    });
  },

  /* delete cedcoin successfully popup */
  deleteCedCoinSuccessOpen: false,
  setDeleteCedCoinSuccessOpen: (open) => {
    set({
      deleteCedCoinSuccessOpen: open,
    });
  },

  /* mark ppe request order shipped confirm popup */
  requestOrderShippedOpen: false,
  setRequestOrderShippedOpen: (open) => {
    set({
      requestOrderShippedOpen: open,
    });
  },
}));
