import AddIcon from '@mui/icons-material/Add';
import CancelIcon from '@mui/icons-material/Cancel';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  IconButton,
} from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import { IItem } from 'app/models/item';
import { useCartStore } from 'app/stores/cartStore';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useModalStore } from 'app/stores/modalStore';
import CustomizedItemTextField from 'features/PPERequest/RequestTextField';
import React, { useEffect, useState } from 'react';

const PpeCustom = () => {
  const {
    addCustomizedItemsToCart,
    customizedItems,
    setCartCategory,
    cartCategory,
    replaceCartWithCustomizedItems,
    items,
  } = useCartStore();
  const {
    addCustomizedItemsOpen,
    setAddCustomizedItemsOpen,
    replaceCartOpen,
    setReplaceCartOpen,
  } = useModalStore();
  const { categoryList, setCategoryList } = useCategoryStore();
  const startingId = customizedItems.length;

  const initialItemState: IItem = {
    name: '',
    desc: '',
    qty: -1,
    price: null,
    size: '',
    color: '',
    categoryId: 6,
    groupId: -1,
    hasSize: false,
    hasColor: false,
    hasPrice: false,
    images: [],
    files: [],
    isDeleted: false,
    isDiscontinued: false,
    isOrderable: true,
    make: '',
    model: '',
  };

  const [currentItems, setCurrentItems] = useState<IItem[]>([
    {
      ...initialItemState,
      id: startingId,
    },
  ]);

  const [nameError, setNameError] = useState(false);

  /* Function to handle adding item */
  const addItem = () => {
    setCurrentItems([
      ...currentItems,
      { ...initialItemState, id: startingId + 1 },
    ]);
  };

  /* Function to handle removing item */
  const removeItem = (itemIndex: number) => {
    const updatedItems = [...currentItems];
    updatedItems.splice(itemIndex, 1);
    setCurrentItems(updatedItems);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (items.length && cartCategory && cartCategory.id !== 6) {
      setReplaceCartOpen(true);
    } else {
      currentItems.forEach((i) => addCustomizedItemsToCart(i, i.qty));
      setCartCategory(categoryList?.find((c) => c.id === 6));
      setAddCustomizedItemsOpen(true);
    }
  };

  useEffect(() => {
    setCategoryList();
  }, [setCategoryList]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* header */}
      <Box m={2}>
        <MessageContainer
          icon={<DashboardCustomizeIcon />}
          message="Customized Items"
        />
      </Box>

      {/* standard section */}
      <Box sx={{ m: 2, bgcolor: 'secondary.dark', p: 1 }}>
        <Box p={2}>
          {/* items section*/}
          <Box>
            {/* add item button */}
            <Box display="flex" justifyContent="left" alignItems="center" m={1}>
              <Button onClick={addItem} startIcon={<AddIcon />}>
                Add A New Customized Item
              </Button>
            </Box>
            {/* item rows */}
            {currentItems.map((item, itemIndex) => (
              <Grid
                container
                spacing={2}
                columns={{ md: 66 }}
                key={itemIndex}
                m={1}
              >
                {/* item detail fields */}
                <Grid item xs md={60}>
                  <Grid container spacing={2}>
                    <CustomizedItemTextField
                      label="Name"
                      onChange={(
                        event: React.ChangeEvent<HTMLInputElement>
                      ) => {
                        setCurrentItems((prevItems) => {
                          const updatedItems = [...prevItems];
                          updatedItems[itemIndex] = {
                            ...updatedItems[itemIndex],
                            name: event.target.value,
                          };
                          return updatedItems;
                        });

                        const trimmedNewName = event.target.value.trim();
                        const isDuplicate = currentItems.some(
                          (item, index) =>
                            index !== itemIndex &&
                            item.name.trim() === trimmedNewName
                        );
                        setNameError(isDuplicate);
                      }}
                      value={item.name}
                      required
                      error={nameError}
                      helperText={
                        nameError ? 'Each item must have a unique name' : ''
                      }
                    />
                    <CustomizedItemTextField
                      label="Qty"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentItems((prevItems) => {
                          const updatedItems = [...prevItems];
                          updatedItems[itemIndex] = {
                            ...updatedItems[itemIndex],
                            qty: Number(event.target.value),
                          };
                          return updatedItems;
                        })
                      }
                      value={item.qty === -1 ? '' : item.qty}
                      type="number"
                      required
                    />
                    <CustomizedItemTextField
                      label="Make"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentItems((prevItems) => {
                          const updatedItems = [...prevItems];
                          updatedItems[itemIndex] = {
                            ...updatedItems[itemIndex],
                            make: event.target.value,
                          };
                          return updatedItems;
                        })
                      }
                      value={item.make}
                    />
                    <CustomizedItemTextField
                      label="Model"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setCurrentItems((prevItems) => {
                          const updatedItems = [...prevItems];
                          updatedItems[itemIndex] = {
                            ...updatedItems[itemIndex],
                            model: event.target.value,
                          };
                          return updatedItems;
                        })
                      }
                      value={item.model}
                    />
                  </Grid>
                </Grid>
                {/* remove item button */}
                <Grid item xs md={6}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      mt: 2,
                    }}
                  >
                    <IconButton
                      aria-label="delete"
                      size="large"
                      color="primary"
                      onClick={() => removeItem(itemIndex)}
                      disabled={currentItems.length === 1}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
      </Box>
      {/* confirmation popup */}
      <Box>
        <Dialog
          open={addCustomizedItemsOpen}
          onClose={() => setAddCustomizedItemsOpen(false)}
          scroll="body"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <DialogContentText>
              Customized Items has been added.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => {
                setAddCustomizedItemsOpen(false);
                setCurrentItems([
                  {
                    ...initialItemState,
                    id: customizedItems.length,
                  },
                ]);
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      {/* replace cart popup */}
      <Dialog
        open={replaceCartOpen}
        onClose={() => setReplaceCartOpen(false)}
        scroll="body"
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="responsive-dialog-title">
          Replace the cart?
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            The product you're adding is from a different category than the
            products in the cart. All products in the cart must be from one
            category. Do you want to replace the cart?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={() => setReplaceCartOpen(false)}>
            No
          </Button>
          <Button
            autoFocus
            onClick={() => {
              setReplaceCartOpen(false);
              setCartCategory(categoryList?.find((c) => c.id === 6));
              replaceCartWithCustomizedItems(currentItems);
              setAddCustomizedItemsOpen(true);
            }}
          >
            Yes
          </Button>
        </DialogActions>
      </Dialog>
      {/* submit button */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        mt={10}
        ml={2}
        mr={2}
      >
        <Button variant="contained" type="submit" size="large">
          Add To Cart
        </Button>
      </Box>
      {/* Footer Spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default PpeCustom;
