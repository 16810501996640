import { User as IUser } from '@microsoft/microsoft-graph-types';
import Agent from 'app/api/agent';
import {
  IAdGroupUser,
  IAdminUser,
  IEmployee,
  ILocation,
} from 'app/models/user';
import { create } from 'zustand';

interface IUserStore {
  /* fetch user */
  user?: IUser;
  userLoading: boolean;
  setUser: () => void;

  /* fetch user photo */
  userPhoto?: string;
  userPhotoLoading: boolean;
  setUserPhoto: () => void;

  /* fetch requester photo */
  requesterPhoto?: string;
  requesterPhotoLoading: boolean;
  setRequesterPhoto: (email: string) => void;

  /* fetch admin by email */
  adminUser: IAdminUser[];
  adminUserLoading: boolean;
  setAdminUser: (email: string) => void;

  /* fetch office list */
  officeList?: { [key: string]: string[] };
  officeListLoading: boolean;
  setOfficeList: () => void;

  /* fetch all employees  */
  employeeList: IEmployee[];
  employeeListLoading: boolean;
  setEmployeeList: () => void;

  /* fetch all discipline */
  disciplineList: string[];
  disciplineListLoading: boolean;
  setDisciplineList: () => void;

  /* fetch admin group users */
  adGroupUsers: IAdGroupUser[];
  adGroupUsersLoading: boolean;
  setAdGroupUsers: () => void;

  /* fetch locations */
  locations: ILocation[];
  locationsLoading: boolean;
  setLocations: () => void;
}

export const useUserStore = create<IUserStore>((set) => ({
  /* fetch user */
  userLoading: false,
  setUser: () => {
    set({ userLoading: true });
    Agent.Graph.loadUser()
      .then((response) => {
        set({
          user: response,
          userLoading: false,
        });
      })
      .catch(() => set({ userLoading: false }));
  },

  /* fetch user photo */
  userPhotoLoading: false,
  setUserPhoto: () => {
    set({ userPhotoLoading: true });
    Agent.Graph.loadUserPhoto()
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(response);
        set({
          userPhoto: blobUrl,
          userPhotoLoading: false,
        });
      })
      .catch(() => set({ userPhotoLoading: false }));
  },

  /* fetch requester photo */
  requesterPhoto: '',
  requesterPhotoLoading: false,
  setRequesterPhoto: (email) => {
    set({ requesterPhotoLoading: true });
    Agent.Graph.loadRequesterPhoto(email)
      .then((response) => {
        const url = window.URL || window.webkitURL;
        const blobUrl = url.createObjectURL(response);
        set({
          requesterPhoto: blobUrl,
          requesterPhotoLoading: false,
        });
      })
      .catch(() => set({ requesterPhotoLoading: false, requesterPhoto: '' }));
  },

  /* fetch admin by email */
  adminUser: [],
  adminUserLoading: false,
  setAdminUser: (email) => {
    set({ adminUserLoading: true });
    Agent.User.loadAdminUser(email)
      .then((response) => {
        set({
          adminUser: response,
          adminUserLoading: false,
        });
      })
      .catch(() => set({ adminUserLoading: false }));
  },

  /* fetch office list */
  officeListLoading: false,
  setOfficeList: () => {
    set({ officeListLoading: true });
    Agent.User.loadOfficeList()
      .then((response) => {
        let updatedOfficeList: { [key: string]: string[] } = {};

        response.offices
          .filter((office) => office !== 'Remote')
          .forEach((office) => {
            let city, state;
            if (office === 'Washington DC') {
              city = 'Washington';
              state = 'DC';
            } else {
              [city, state] = office.split(', ');
            }
            updatedOfficeList[state]
              ? updatedOfficeList[state].push(city)
              : (updatedOfficeList[state] = [city]);
          });
        updatedOfficeList = Object.keys(updatedOfficeList)
          .sort()
          .reduce((temp_obj: { [key: string]: string[] }, key) => {
            temp_obj[key] = updatedOfficeList[key];
            return temp_obj;
          }, {});

        set({
          officeList: updatedOfficeList,
          officeListLoading: false,
        });
      })
      .catch(() => set({ officeListLoading: false }));
  },

  /* fetch all employees  */
  employeeList: [],
  employeeListLoading: false,
  setEmployeeList: () => {
    set({ employeeListLoading: true });
    Agent.User.employees()
      .then((response) => {
        set({
          employeeList: response,
          employeeListLoading: false,
        });
      })
      .catch(() => set({ employeeListLoading: false }));
  },

  /* fetch all discipline */
  disciplineList: [],
  disciplineListLoading: false,
  setDisciplineList: () => {
    set({ disciplineListLoading: true });
    Agent.User.loadDisciplineList()
      .then((response) => {
        set({
          disciplineList: response,
          disciplineListLoading: false,
        });
      })
      .catch(() => set({ disciplineListLoading: false }));
  },

  /* fetch admin group users */
  adGroupUsers: [],
  adGroupUsersLoading: false,
  setAdGroupUsers: () => {
    set({ adGroupUsersLoading: true });
    Agent.User.loadAdGroupUsers()
      .then((response) => {
        set({
          adGroupUsers: response,
          adGroupUsersLoading: false,
        });
      })
      .catch(() => set({ adGroupUsersLoading: false }));
  },

  /* fetch locations */
  locations: [],
  locationsLoading: false,
  setLocations: () => {
    set({ locationsLoading: true });
    Agent.User.loadLocations()
      .then((response) => {
        set({
          locations: response,
          locationsLoading: false,
        });
      })
      .catch(() => set({ locationsLoading: false }));
  },
}));
