import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { ThemeProvider } from '@mui/material/styles';
import { msalConfig } from 'app/azure/authConfig';
import App from 'app/layout/App';
import 'app/layout/styles.css';
import theme from 'app/materialUI/theme';
import React from 'react';
// import ReactDOM from 'react-dom';
import { Container, createRoot } from 'react-dom/client';
import reportWebVitals from './reportWebVitals';

export const msalInstance = new PublicClientApplication(msalConfig);

const root = createRoot(document.getElementById('root') as Container);
root.render(
  <MsalProvider instance={msalInstance}>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
