import { Box, Button } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid';
import React from 'react';

interface CustomToolbarProps {
  icon: JSX.Element;
  onClick: (...args: any[]) => void;
  label: string;
}

const CustomToolbar = ({ icon, onClick, label }: CustomToolbarProps) => {
  return (
    <GridToolbarContainer sx={{ justifyContent: 'space-between' }}>
      <Box>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <GridToolbarDensitySelector />
        <GridToolbarExport />
      </Box>
      <Button
        size="small"
        startIcon={icon}
        onClick={onClick}
        variant="contained"
        sx={{ m: 1 }}
      >
        {label}
      </Button>
    </GridToolbarContainer>
  );
};

export default CustomToolbar;
