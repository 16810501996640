import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {
  Badge,
  Box,
  Button,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { no_image_png } from 'app/common/options/noImage';
import { customizedItemImage } from 'app/common/options/ppeOptions';
import { firstImageSrc } from 'app/common/util/util';
import { useCartStore } from 'app/stores/cartStore';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React from 'react';
import { Link } from 'react-router-dom';

const CartMenu = () => {
  /* State and functions from custom hooks */
  const { items, cartCategory, customizedItems } = useCartStore();
  const totalItemsQuantity = items?.reduce((acc, item) => acc + item.qty, 0);
  const totalCustomizedItemsQuantity = customizedItems?.reduce(
    (acc, item) => acc + item.qty,
    0
  );
  const totalQuantity = totalItemsQuantity + totalCustomizedItemsQuantity;

  return (
    <PopupState variant="popover" popupId="cart-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* shopping cart button */}
          <IconButton size="large" color="primary" {...bindTrigger(popupState)}>
            <Badge badgeContent={totalQuantity} color="secondary">
              <ShoppingBagIcon />
            </Badge>
          </IconButton>
          {/* popup dropdown menu after click */}
          <Menu {...bindMenu(popupState)}>
            {items.length || customizedItems.length ? (
              <Box>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Product</TableCell>
                      <TableCell align="right"></TableCell>
                      <TableCell align="right">Size</TableCell>
                      <TableCell align="right">Color</TableCell>
                      {customizedItems.length > 0 && (
                        <TableCell align="right">Make</TableCell>
                      )}
                      {customizedItems.length > 0 && (
                        <TableCell align="right">Model</TableCell>
                      )}
                      <TableCell align="right">Quantity</TableCell>
                      {cartCategory?.isPriceable && (
                        <TableCell align="right">Amount</TableCell>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items.map((orderItem, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Link
                            to={`/inventory/${orderItem.item.categoryId}/${orderItem?.item?.id}`}
                            onClick={() => popupState.close()}
                          >
                            <Box
                              component="img"
                              src={firstImageSrc(orderItem.item.images)}
                              height={50}
                              width={50}
                              onError={(e) => {
                                e.currentTarget.src = no_image_png;
                              }}
                              alt={orderItem.item.name}
                              sx={{
                                cursor: 'pointer',
                                objectFit: 'contain',
                                '&:hover': {
                                  opacity: 0.8,
                                },
                              }}
                            />
                          </Link>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2">
                            {orderItem.item.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.item.size}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.item.color}
                          </Typography>
                        </TableCell>
                        {customizedItems.length > 0 && (
                          <TableCell align="right"></TableCell>
                        )}
                        {customizedItems.length > 0 && (
                          <TableCell align="right"></TableCell>
                        )}
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.qty}
                          </Typography>
                        </TableCell>
                        {cartCategory?.isPriceable && (
                          <TableCell align="right">
                            <Typography variant="subtitle2">
                              ${(orderItem.price * orderItem.qty).toFixed(2)}
                            </Typography>
                          </TableCell>
                        )}
                      </TableRow>
                    ))}
                  </TableBody>
                  <TableBody>
                    {customizedItems.map((orderItem, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          <Box
                            component="img"
                            src={firstImageSrc(orderItem.item.images)}
                            height={50}
                            width={50}
                            onError={(e) => {
                              e.currentTarget.src = customizedItemImage;
                            }}
                            alt={orderItem.item.name}
                            sx={{
                              cursor: 'pointer',
                              objectFit: 'contain',
                              '&:hover': {
                                opacity: 0.8,
                              },
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <Typography variant="subtitle2">
                            {orderItem.item.name}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.item.size}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.item.color}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.item.make}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.item.model}
                          </Typography>
                        </TableCell>
                        <TableCell align="right">
                          <Typography variant="subtitle2">
                            {orderItem.qty}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <Box>
                  <Divider />
                  <MenuItem
                    component={Link}
                    to="/cart"
                    onClick={popupState.close}
                  >
                    <Button variant="contained" sx={{ width: '100%' }}>
                      Cart
                    </Button>
                  </MenuItem>
                </Box>
              </Box>
            ) : (
              <MenuItem>Your cart is empty</MenuItem>
            )}
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default CartMenu;
