import { Box, FormControl, Grid, Stack } from '@mui/material';
import React, { ReactNode } from 'react';

interface FormFieldProps {
  label: ReactNode;
  control: ReactNode;
}

const FormField = ({ label, control }: FormFieldProps) => {
  return (
    <>
      <Grid item xs={12} xl={6}>
        <Box m={1}>{label}</Box>
      </Grid>
      <Grid item xs={12} xl={6}>
        <Stack m={1}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'left',
              alignItems: 'center',
            }}
          >
            <FormControl
              sx={{
                '& .MuiFormLabel-asterisk': {
                  color: 'warning.main',
                },
              }}
              fullWidth
              required
            >
              {control}
            </FormControl>
          </Box>
        </Stack>
      </Grid>
    </>
  );
};

export default FormField;
