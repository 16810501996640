import Agent from 'app/api/agent';
import { IItem } from 'app/models/item';
import { AxiosResponse } from 'axios';
import { create } from 'zustand';

interface IItemStore {
  /* fetch items in category */
  itemList: IItem[];
  itemListLoading: boolean;
  setItemList: (id: number) => void;

  /* fetch item by id */
  item?: IItem;
  itemLoading: boolean;
  setItem: (id: number) => void;

  /* fetch items in group */
  itemGroupList: IItem[];
  itemGroupTotalStock?: number;
  itemGroupListLoading: boolean;
  setItemGroupList: (id: number) => void;

  /* fetch all items */
  totalItemList: IItem[];
  totalItemListLoading: boolean;
  setTotalItemList: () => void;

  /* fetch items for search */
  itemSearchList: IItem[];
  itemSearchListLoading: boolean;
  setItemSearchList: () => void;

  /* fetch items for product table filter */
  itemListForFilter: IItem[];
  itemListForFilterLoading: boolean;
  setItemListForFilter: () => void;

  /* create a new item */
  submitItem: (payload: FormData) => Promise<void | AxiosResponse>;

  /* edit existing item */
  editItem: (id: number, payload: FormData) => Promise<void | AxiosResponse>;

  /* delete item */
  deleteItem: (id: number) => Promise<void | AxiosResponse>;

  /* reset item list */
  reSetItemList: (list: IItem[]) => void;

  /* reorder item list */
  reOrderItemList: (
    catId: number,
    items: IItem[]
  ) => Promise<void | AxiosResponse>;
}

export const useItemStore = create<IItemStore>((set) => ({
  /* fetch items in category */
  itemList: [],
  itemListLoading: false,
  setItemList: (id) => {
    set({ itemListLoading: true });
    Agent.Item.loadItemsInCategory(id)
      .then((response) => {
        set({
          itemList: response,
          itemListLoading: false,
        });
      })
      .catch(() => set({ itemListLoading: false }));
  },

  /* fetch item by id */
  itemLoading: false,
  setItem: (id) => {
    set({ itemLoading: true });
    Agent.Item.loadItem(id)
      .then((response) => {
        set({
          item: response,
          itemLoading: false,
        });
      })
      .catch(() => set({ itemLoading: false }));
  },

  /* fetch items in group */
  itemGroupList: [],
  itemGroupListLoading: false,
  setItemGroupList: (id) => {
    set({ itemGroupListLoading: true });
    Agent.Item.loadItemsInGroup(id)
      .then((response) => {
        const totalStock = response.reduce((acc, i) => acc + i.qty, 0);
        set({
          itemGroupList: response,
          itemGroupTotalStock: totalStock,
          itemGroupListLoading: false,
        });
      })
      .catch(() => set({ itemGroupListLoading: false }));
  },

  /* fetch all items */
  totalItemList: [],
  totalItemListLoading: false,
  setTotalItemList: () => {
    set({ totalItemListLoading: true });
    Agent.Item.loadAllItems()
      .then((response) => {
        set({
          totalItemList: response,
          totalItemListLoading: false,
        });
      })
      .catch(() => set({ totalItemListLoading: false }));
  },

  /* fetch items for product table filter */
  itemListForFilter: [],
  itemListForFilterLoading: false,
  setItemListForFilter: () => {
    set({ itemListForFilterLoading: true });
    Agent.Item.loadItemsForFilter()
      .then((response) => {
        set({
          itemListForFilter: response,
          itemListForFilterLoading: false,
        });
      })
      .catch(() => set({ itemListForFilterLoading: false }));
  },

  /* fetch items for search */
  itemSearchList: [],
  itemSearchListLoading: false,
  setItemSearchList: () => {
    set({ itemSearchListLoading: true });
    Agent.Item.loadItemsForSearch()
      .then((response) => {
        set({
          itemSearchList: response,
          itemSearchListLoading: false,
        });
      })
      .catch(() => set({ itemSearchListLoading: false }));
  },

  /* create new item */
  submitItem: async (payload) => {
    return Agent.Item.createItem(payload)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* edit existing item */
  editItem: async (id, payload) => {
    return Agent.Item.editItem(id, payload)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* delete item */
  deleteItem: async (id) => {
    return Agent.Item.deleteItem(id)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* reset item list */
  reSetItemList: (list) => {
    set({
      itemList: list,
    });
  },

  /* reorder item list */
  reOrderItemList: async (catId, items) => {
    return Agent.Item.editItemsOrder(catId, items)
      .then((response) => response)
      .catch((error) => console.log(error));
  },
}));
