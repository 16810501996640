import { PublicClientApplication } from '@azure/msal-browser';
import { User as IUser } from '@microsoft/microsoft-graph-types';
import { apiRequest, loginRequest, msalConfig } from 'app/azure/authConfig';
import { ICategory } from 'app/models/category';
import { ICedCoinItem, ICedCoinOrder } from 'app/models/cedCoin';

import { IItem } from 'app/models/item';
import {
  IOfficeList,
  IOrder,
  IOrderItemSum,
  IPayrollDeduction,
} from 'app/models/order';

import { IHomeDepartment, IPpeManager, IRequest } from 'app/models/request';
import {
  IAdGroupUser,
  IAdminUser,
  IEmployee,
  ILocation,
} from 'app/models/user';
import axios, { AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const graphAxios = axios.create({
  baseURL: 'https://graph.microsoft.com/v1.0',
});

const graphPhotoAxios = axios.create({
  baseURL: 'https://graph.microsoft.com/v1.0',
});

async function getAccessToken(scopes: string[]) {
  const instance = new PublicClientApplication(msalConfig);
  const accounts = instance.getAllAccounts();

  if (accounts.length > 0) {
    const request = {
      scopes: scopes,
      account: accounts[0],
    };
    const accessToken = await instance
      .acquireTokenSilent(request)
      .then((response: any) => {
        return response.accessToken;
      })
      .catch((error) => {
        console.log(error);
        return null;
      });

    return accessToken;
  }

  return null;
}

axios.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken(apiRequest.scopes);
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

graphAxios.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken(loginRequest.scopes);
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

graphPhotoAxios.interceptors.request.use(
  async (config) => {
    const token = await getAccessToken(loginRequest.scopes);
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
      config.responseType = 'blob';
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const interceptorsResponse = (error: any) => {
  if (error.message === 'Network Error' && !error.response) {
    console.error('Network error - make sure API is running!');
  }

  const { status, data, config } = error.response;

  if (status === 404 && config.responseType !== 'blob') {
    console.log(status + ':notfound');
  }

  if (
    status === 400 &&
    config.method === 'get' &&
    data.errors.hasOwnProperty('id')
  ) {
    console.log(status + ':notfound');
  }

  if (status === 500) {
    console.log(status + ':Server error - check the terminal for more info!');
  }

  throw error;
};

axios.interceptors.response.use(undefined, interceptorsResponse);
graphAxios.interceptors.response.use(undefined, interceptorsResponse);
graphPhotoAxios.interceptors.response.use(undefined, interceptorsResponse);

const responseBody = <T>(response: AxiosResponse<T>) => response.data;
const response = <T>(response: AxiosResponse<T>) => response;

const requests = {
  get: <T>(url: string) => axios.get<T>(url).then(responseBody),

  post: <T>(url: string, body: {}) => axios.post<T>(url, body).then(response),

  put: <T>(url: string, body: {}) => axios.put<T>(url, body).then(response),

  del: (url: string) => axios.delete(url).then(responseBody),
};

const graphRequests = {
  get: <T>(url: string) => graphAxios.get<T>(url).then(responseBody),
};

const graphPhotoRequests = {
  get: <T>(url: string) => graphPhotoAxios.get<T>(url).then(responseBody),
};

const Category = {
  getCategories: (): Promise<ICategory[]> =>
    requests.get('/inventory/getCategories'),
  getOneCategory: (id: number): Promise<ICategory> =>
    requests.get(`/inventory/getOneCategory?id=${id}`),
};

const Item = {
  loadItemsInCategory: (catId: number): Promise<IItem[]> =>
    requests.get(`/item/getItemsInCategory?catId=${catId}`),
  loadItemsInGroup: (id: number): Promise<IItem[]> =>
    requests.get(`/item/getItemsInGroup?id=${id}`),
  loadItem: (id: number): Promise<IItem> =>
    requests.get(`/item/getOne?id=${id}`),
  loadAllItems: (): Promise<IItem[]> => requests.get(`/item/getItems`),
  loadItemsForFilter: (): Promise<IItem[]> =>
    requests.get(`/item/getItemsForFilter`),
  loadItemsForSearch: (): Promise<IItem[]> =>
    requests.get(`/item/getItemsForSearch`),
  loadDiscontinued: (catId: number): Promise<IItem[]> =>
    requests.get(`/item/getDiscontinued?catId=${catId}`),
  createItem: (payload: FormData): Promise<AxiosResponse> =>
    requests.post(`/item`, payload),
  editItem: (id: number, item: FormData): Promise<AxiosResponse> =>
    requests.post(`/item/${id}/edit`, item),
  editItemsOrder: (catId: number, items: IItem[]): Promise<AxiosResponse> =>
    requests.post(`/item/${catId}/editItemsOrder`, items),
  deleteItem: (id: number): Promise<AxiosResponse> =>
    requests.put(`/item/${id}/delete`, {}),
};

const Order = {
  loadOrdersInCategory: (catId: number): Promise<IOrder[]> =>
    requests.get(`/order/getOrdersInCategory?catId=${catId}`),
  countOrderItem: (catId: number): Promise<IOrderItemSum[]> =>
    requests.get(`/order/countOrderItem?catId=${catId}`),
  loadOrder: (id: number): Promise<IOrder> =>
    requests.get(`/order/getOne?id=${id}`),
  loadAllOrders: (): Promise<IOrder[]> => requests.get(`/order/getOrders`),
  loadOrdersByEmail: (email: string): Promise<IOrder[]> =>
    requests.get(`/order/getOrdersByEmail?email=${email}`),
  loadMyOrder: (id: number, email: string, isAdmin: boolean): Promise<IOrder> =>
    requests.get(
      `/order/getMyOrder?id=${id}&email=${email}&isAdmin=${isAdmin}`
    ),
  createOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.post('/order/new', order),
  createPpeOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.post('/order/newPpe', order),
  createPickup: (order: IOrder): Promise<AxiosResponse> =>
    requests.post('/order/newPickup', order),
  modifyOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.put('/order/modify', order),
  receiveOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.put(`/order/receive`, order),
  shipOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.put('/order/ship', order),
  returnOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.put('/order/return', order),
  approveOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.put('/order/approve', order),
  rejectOrder: (order: IOrder): Promise<AxiosResponse> =>
    requests.put('/order/reject', order),
  loadPayrollDeductions: (): Promise<IPayrollDeduction[]> =>
    requests.get(`/order/getPayrollDeductions`),
};

const CedCoin = {
  createCedCoinOrder: (cedCoinOrder: ICedCoinOrder): Promise<AxiosResponse> =>
    requests.post(`/cedCoin/new`, cedCoinOrder),
  adjustCedCoin: (cedCoinOrder: ICedCoinOrder): Promise<AxiosResponse> =>
    requests.post(`/cedCoin/adjustCedCoin`, cedCoinOrder),
  loadAllCedCoins: (): Promise<ICedCoinItem[]> =>
    requests.get(`/cedCoin/getAllCedCoinItems`),
  loadCedCoins: (email: string): Promise<ICedCoinItem[]> =>
    requests.get(`/cedCoin/getCedCoinItemsByEmail?email=${email}`),
  deleteCedCoin: (email: string, deletedBy: string): Promise<AxiosResponse> =>
    requests.put(`/cedCoin/delete?email=${email}&deletedBy=${deletedBy}`, {}),
};

const User = {
  loadAdminUser: (email: string): Promise<IAdminUser[]> =>
    requests.get(`/inventory/user?email=${email}`),
  employees: (): Promise<IEmployee[]> =>
    requests.get('/empDirectory/employees'),
  loadOfficeList: (): Promise<IOfficeList> =>
    requests.get('/empdirectory/offices'),
  loadDisciplineList: (): Promise<string[]> =>
    requests.get('/empdirectory/disciplines'),
  loadAdGroupUsers: (): Promise<IAdGroupUser[]> =>
    requests.get('/empDirectory/adGroupUsers'),
  loadLocations: (): Promise<ILocation[]> =>
    requests.get('/empDirectory/locations'),
};

const Graph = {
  loadUser: (): Promise<IUser> => graphRequests.get('/me'),
  loadUserPhoto: (): Promise<Blob> =>
    graphPhotoRequests.get('/me/photo/$value'),
  loadRequesterPhoto: (email: string): Promise<Blob> =>
    graphPhotoRequests.get(`/users/${email}/photo/$value`),
};

const Request = {
  createRequest: (request: IRequest): Promise<AxiosResponse> =>
    requests.post('/request/new', request),
  loadManagers: (): Promise<IPpeManager[]> =>
    requests.get(`/request/getManagers`),
  loadRequests: (): Promise<IRequest[]> => requests.get(`/request/getRequests`),
  loadReviewedRequests: (): Promise<IRequest[]> =>
    requests.get(`/request/getReviewedRequests`),
  loadRequestById: (id: number): Promise<IRequest> =>
    requests.get(`/request/getOne?id=${id}`),
  handleRequest: (request: IRequest): Promise<AxiosResponse> =>
    requests.put('/request/handle', request),
  loadRequestsByEmail: (email: string): Promise<IRequest[]> =>
    requests.get(`/request/getRequestsByEmail?email=${email}`),
  loadHomeDepartments: (): Promise<IHomeDepartment[]> =>
    requests.get(`/request/getHomeDepartments`),
  shipRequestOrder: (request: IRequest): Promise<AxiosResponse> =>
    requests.put('/request/shipRequestOrder', request),
};

const Agent = {
  Category,
  Item,
  Order,
  User,
  Graph,
  CedCoin,
  Request,
};

export default Agent;
