import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import { Box, Button, Chip, Grid } from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import { useCartStore } from 'app/stores/cartStore';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutForm from './CheckoutForm';
import CheckoutOrder from './CheckoutOrder';

const Checkout = () => {
  /* State and functions from custom hooks */
  const { items, customizedItems } = useCartStore();
  const navigate = useNavigate();

  /* Render a message if the cart is empty */
  if (items.length === 0 && customizedItems.length === 0)
    return (
      <MessageContainer
        icon={<ProductionQuantityLimitsIcon />}
        message="Your cart is empty"
      />
    );

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<ShoppingBagIcon />} message="Checkout" />
      </Box>
      {/* back to cart button */}
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Chip label="Checkout" />
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            navigate('/cart');
          }}
        >
          Back to cart
        </Button>
      </Box>
      {/* Grid container for the checkout order summary and form */}
      <Grid container>
        {/* Grid item for the checkout order summary */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <CheckoutOrder />
        </Grid>
        {/* Grid item for the checkout form */}
        <Grid item xs={12} sm={12} md={12} lg={6} minHeight={0}>
          <CheckoutForm />
        </Grid>
      </Grid>
      <Box height={128}></Box>
    </Box>
  );
};

export default Checkout;
