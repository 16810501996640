import { Grid } from '@mui/material';
import React from 'react';
import AdminBoardMenu from './AdminBoardMenu';

interface AdminDashboardProps {
  table: JSX.Element;
}

const AdminDashboard = ({ table }: AdminDashboardProps) => {
  return (
    <Grid container>
      {/* Grid item for the admin menu */}
      <Grid item xs={12} lg={2}>
        <AdminBoardMenu />
      </Grid>
      {/* Grid item for the table */}
      <Grid item xs={12} lg={10}>
        {table}
      </Grid>
    </Grid>
  );
};

export default AdminDashboard;
