import ListAltIcon from '@mui/icons-material/ListAlt';
import { Box, Typography } from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const MarkReceived = () => {
  const { orderId }: any = useParams();

  return (
    <Box m={2}>
      <MessageContainer icon={<ListAltIcon />} message="Updates" />

      <Box m={2} fontSize={36}>
        Now you can go to the{' '}
        <Typography
          component={Link}
          to={`/my-orders/${orderId}`}
          fontSize={36}
          fontWeight={600}
        >
          order detail page
        </Typography>{' '}
        and mark receive my order.
      </Box>

      <Box height={128}></Box>
    </Box>
  );
};

export default MarkReceived;
