import { styled, useMediaQuery, useTheme } from '@mui/material';

export const stringAvatar = (name?: string) => {
  if (name) {
    return {
      children: name.includes(' ')
        ? `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
        : `${name[0]}`,
    };
  }
};

export const isImage = (fileName: string): boolean =>
  ['jpg', 'jpeg', 'png', 'gif'].includes(fileName.toLowerCase().split('.')[1]);

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const useSmallScreen = () => {
  const theme = useTheme();
  return useMediaQuery(theme.breakpoints.down('sm'));
};

export const useExtraSmallScreen = () => {
  return useMediaQuery('(max-width: 500px)');
};

export const firstImageSrc = (images?: any[]) => {
  return (
    process.env.REACT_APP_ATTACHMENTS_URL +
    '/' +
    images?.filter((i) => i !== '')[0]
  );
};
