import { useMsal } from '@azure/msal-react';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GradingIcon from '@mui/icons-material/Grading';
import HistoryIcon from '@mui/icons-material/History';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Logout from '@mui/icons-material/Logout';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  ListItemIcon,
  Menu,
  MenuItem,
  Skeleton,
  Typography,
} from '@mui/material';
import { stringAvatar } from 'app/common/util/util';
import { useCedCoinStore } from 'app/stores/cedCoinStore';
import { useUserStore } from 'app/stores/userStore';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const UserMenu = () => {
  /* State and functions from custom hooks */
  const {
    user,
    userPhoto,
    adminUser,
    userLoading,
    userPhotoLoading,
    adminUserLoading,
    setUser,
    setUserPhoto,
    setAdminUser,
    setLocations,
  } = useUserStore();
  const { instance, accounts } = useMsal();
  const { userCedCoinBalance, cedCoinItemList, setCedCoinItemList } =
    useCedCoinStore();

  /* Fetch user, userPhoto, adminUser, and cedCoin on component mount */
  useEffect(() => {
    setUser();
    setUserPhoto();
    setAdminUser(accounts[0]?.username);
    setCedCoinItemList(accounts[0]?.username);
    setLocations();
  }, [
    accounts,
    setUser,
    setUserPhoto,
    setAdminUser,
    setCedCoinItemList,
    setLocations,
  ]);

  return (
    <PopupState variant="popover" popupId="account-popup-menu">
      {(popupState) => (
        <React.Fragment>
          {/* account button */}
          <Button
            color="primary"
            {...bindTrigger(popupState)}
            endIcon={<KeyboardArrowDownIcon />}
          >
            {userPhotoLoading ? (
              <Skeleton
                variant="circular"
                width={24}
                height={24}
                sx={{ mr: 1 }}
              />
            ) : (
              <Avatar
                sx={{
                  width: 24,
                  height: 24,
                  mr: 1,
                }}
                src={userPhoto}
                {...stringAvatar(user?.displayName!)}
              />
            )}

            <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
              {userLoading ? (
                <Skeleton variant="text" sx={{ fontSize: 10 }} />
              ) : (
                <Typography sx={{ fontSize: 10 }}>
                  {user && user.displayName}
                </Typography>
              )}
              {adminUserLoading ? (
                <Skeleton variant="text" sx={{ fontSize: 10 }} />
              ) : (
                <Button
                  component="span"
                  variant="outlined"
                  sx={{
                    borderRadius: 8,
                    p: 0,
                  }}
                >
                  <Typography fontSize={10}>
                    {adminUser.length ? 'ADMIN' : 'User'}
                  </Typography>
                </Button>
              )}
            </Box>
            {cedCoinItemList.length > 0 && (
              <Chip
                sx={{ m: { xs: 0, sm: 2 }, width: { xs: 100, sm: 120 } }}
                icon={<MonetizationOnIcon color="secondary" />}
                label={userCedCoinBalance.toFixed(2)}
              />
            )}
          </Button>
          {/* popup dropdown menu after click */}
          <Menu {...bindMenu(popupState)}>
            {!!adminUser.length && (
              <MenuItem onClick={popupState.close} component={Link} to="admin">
                <ListItemIcon>
                  <AdminPanelSettingsIcon fontSize="small" />
                </ListItemIcon>
                Admin
              </MenuItem>
            )}
            <MenuItem
              component={Link}
              to="my-orders"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <HistoryIcon fontSize="small" />
              </ListItemIcon>
              My Orders
            </MenuItem>
            <MenuItem
              component={Link}
              to="my-ppeRequests"
              onClick={popupState.close}
            >
              <ListItemIcon>
                <GradingIcon fontSize="small" />
              </ListItemIcon>
              My PPE Requests
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() =>
                instance.logoutRedirect({
                  postLogoutRedirectUri: '/',
                })
              }
            >
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
        </React.Fragment>
      )}
    </PopupState>
  );
};

export default UserMenu;
