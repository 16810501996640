import { AppBar, Box, Toolbar } from '@mui/material';
import Logo from 'app/common/modals/Logo';
import React from 'react';
import CartMenu from './CartMenu';
import DrawerMenu from './DrawerMenu';
import NavMenu from './NavMenu';
import SearchMenu from './SearchMenu';
import UserMenu from './UserMenu';

const NavBar = () => {
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="static">
        <Toolbar
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
            height: 100,
          }}
        >
          {/* Left section of the toolbar */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DrawerMenu />
            <Logo />
            <NavMenu />
          </Box>
          {/* Right section of the toolbar */}
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <SearchMenu />
            <CartMenu />
            <UserMenu />
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
};

export default NavBar;
