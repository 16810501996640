import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { ICategory } from 'app/models/category';
import { useUserStore } from 'app/stores/userStore';
import React from 'react';

interface CategorySelectionProps {
  value: number;
  onChange: (...args: any[]) => void;
  options?: ICategory[];
}

const CategorySelection = ({
  value,
  onChange,
  options,
}: CategorySelectionProps) => {
  const { adminUser } = useUserStore();

  return (
    <FormControl>
      <FormLabel id="demo-controlled-radio-buttons-group">Category</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={value}
        onChange={onChange}
      >
        {options
          ?.filter((c) => adminUser.some((u) => u.categoryId === c.id))
          .map((c) => (
            <FormControlLabel
              key={c.id}
              value={c.id}
              control={<Radio />}
              label={c.name}
            />
          ))}
      </RadioGroup>
    </FormControl>
  );
};

export default CategorySelection;
