import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Button, MenuItem, Typography } from '@mui/material';
import Loading from 'app/common/modals/Loading';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useUserStore } from 'app/stores/userStore';
import PopupState, { bindHover, bindMenu } from 'material-ui-popup-state';
import HoverMenu from 'material-ui-popup-state/HoverMenu';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const NavMenu = () => {
  /* State and functions from custom hooks */
  const { categoryList, categoryListLoading, setCategoryList } =
    useCategoryStore();
  const { adminUser, locations, user } = useUserStore();

  const stationaryPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 1)
  );

  const marketingStandardStockPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 2)
  );

  /* Fetch category on component mount */
  useEffect(() => {
    setCategoryList();
  }, [setCategoryList]);

  return (
    <Box sx={{ display: { xs: 'none', lg: 'block' }, ml: 4 }}>
      {/* PopupState as a menu wrapper from MUI */}
      <PopupState variant="popover" popupId="category-popup-menu">
        {(popupState) => (
          <React.Fragment>
            {/* products button */}
            <Button sx={{ color: '#000', m: 1 }} {...bindHover(popupState)}>
              <Typography variant="body2">Products</Typography>
              <KeyboardArrowDownIcon />
            </Button>
            {/* popup dropdown menu after click */}
            <HoverMenu
              {...bindMenu(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              {categoryListLoading && <Loading />}
              {categoryList &&
                categoryList
                  .filter(
                    (c) =>
                      (c.id !== 2 &&
                        c.id !== 1 &&
                        (!c.adminOnly || adminUser.length > 0)) ||
                      (c.id === 2 && marketingStandardStockPermission) ||
                      (c.id === 1 && stationaryPermission)
                  )
                  .map((cat, i) => (
                    <MenuItem
                      component={Link}
                      to={'/inventory/' + cat.id}
                      key={i}
                      onClick={popupState.close}
                    >
                      {cat.name}
                    </MenuItem>
                  ))}
            </HoverMenu>
          </React.Fragment>
        )}
      </PopupState>
      {/* PopupState as a menu wrapper from MUI */}
      <PopupState variant="popover" popupId="request-popup-menu">
        {(popupState) => (
          <React.Fragment>
            {/* request button */}
            <Button sx={{ color: '#000', m: 1 }} {...bindHover(popupState)}>
              <Typography variant="body2">Request</Typography>
              <KeyboardArrowDownIcon />
            </Button>
            {/* popup dropdown menu after click */}
            <HoverMenu
              {...bindMenu(popupState)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <MenuItem
                component={Link}
                to={'/ppe-request'}
                onClick={popupState.close}
              >
                H & S PPE Request
              </MenuItem>
              <MenuItem
                component={Link}
                to={'/ppe-request/newhire'}
                onClick={popupState.close}
              >
                H & S PPE Request - NewHire
              </MenuItem>
            </HoverMenu>
          </React.Fragment>
        )}
      </PopupState>
      <Button component={Link} to="/faq" sx={{ color: '#000', m: 1 }}>
        <Typography variant="body2">FAQ</Typography>
      </Button>
      <Button component={Link} to="/contact" sx={{ color: '#000', m: 1 }}>
        <Typography variant="body2">Contact</Typography>
      </Button>
    </Box>
  );
};

export default NavMenu;
