import Agent from 'app/api/agent';
import { ICategory } from 'app/models/category';
import { create } from 'zustand';

interface ICategoryStore {
  /* category */
  category?: ICategory;
  categoryLoading: boolean;
  setCategory: (id: number) => void;

  /* category list */
  categoryList?: ICategory[];
  categoryListLoading: boolean;
  setCategoryList: () => void;
}

export const useCategoryStore = create<ICategoryStore>((set) => ({
  /* category */
  categoryLoading: false,
  setCategory: (id) => {
    set({ categoryLoading: true });
    Agent.Category.getOneCategory(id)
      .then((response) => {
        set({
          category: response,
          categoryLoading: false,
        });
      })
      .catch(() => set({ categoryLoading: false }));
  },

  /* category list */
  categoryListLoading: false,
  setCategoryList: () => {
    set({ categoryListLoading: true });
    Agent.Category.getCategories()
      .then((response) => {
        set({
          categoryList: response,
          categoryListLoading: false,
        });
      })
      .catch(() => set({ categoryListLoading: false }));
  },
}));
