import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import React from 'react';

interface QuestionAnswerProps {
  question: string;
  answer: JSX.Element;
}

const QuestionAnswer = ({ question, answer }: QuestionAnswerProps) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon color="primary" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography variant="subtitle2">{question}</Typography>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: 'secondary.dark' }}>
        {answer}
      </AccordionDetails>
    </Accordion>
  );
};

export default QuestionAnswer;
