import ListAltIcon from '@mui/icons-material/ListAlt';
import { Box } from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridToolbar } from '@mui/x-data-grid';
import MessageContainer from 'app/common/modals/MessageContainer';
import { useOrderStore } from 'app/stores/orderStore';
import React, { useEffect, useState } from 'react';

const PayrollDeductionTable = () => {
  /* State and functions from custom hooks */
  const {
    payrollDeductionList,
    payrollDeductionListLoading,
    setPayrollDeductionList,
  } = useOrderStore();

  /* Define component state variables */
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    {
      field: 'coCode',
      headerName: 'Co Code',
      width: 180,
    },
    { field: 'batchId', headerName: 'Batch ID', width: 180 },
    { field: 'fileNumber', headerName: 'File #', width: 180 },
    {
      field: 'employeeName',
      headerName: 'Employee Name',
      width: 250,
    },
    {
      field: 'adjustDedCode',
      headerName: 'Adjust Ded Code',
      width: 200,
    },
    {
      field: 'adjustDedAmount',
      headerName: 'Adjust Ded Amount',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  /* Fetching order list and item list for filter on component mount */
  useEffect(() => {
    setPayrollDeductionList();
  }, [setPayrollDeductionList]);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<ListAltIcon />} message="Payroll Deduction" />
      </Box>
      {/* Order Table */}
      <Box m={2}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            getRowId={(row) => row.employeeName}
            rows={payrollDeductionList}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
              setSelectionModel([...newSelection])
            }
            rowSelectionModel={selectionModel}
            autoHeight
            loading={payrollDeductionListLoading}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </Box>
      </Box>
      {/* footer gap filler */}
      <Box height={128}></Box>
    </Box>
  );
};

export default PayrollDeductionTable;
