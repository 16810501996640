import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  NativeSelect,
} from '@mui/material';
import React from 'react';

interface RequestDropdownProps {
  label: string;
  onChange: (...args: any[]) => void;
  value?: string | number | boolean;
  options?: any[];
  required?: boolean;
}

const RequestDropdown = ({
  label,
  onChange,
  value,
  options = [],
  required,
}: RequestDropdownProps) => {
  return (
    <Grid item xs={12} lg>
      <Box m={1}>
        <FormControl
          sx={{ minWidth: 120, width: '100%' }}
          size="medium"
          required={required}
        >
          <InputLabel
            htmlFor="native-select"
            variant="standard"
            sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
          >
            {label}
          </InputLabel>
          <NativeSelect id="native-select" onChange={onChange} value={value}>
            <option aria-label="None" value="" />
            {options.map((o, index) => (
              <option value={o} key={index}>
                {o}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </Box>
    </Grid>
  );
};

export default RequestDropdown;
