import GradeIcon from '@mui/icons-material/Grade';
import {
  Box,
  Button,
  Chip,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import NotFound from 'app/common/modals/NotFound';
import { IRequest } from 'app/models/request';
import React from 'react';
import { useLocation } from 'react-router-dom';

const RequestConfirm = () => {
  /* State and functions from custom hooks */
  const location = useLocation();
  const request = location?.state?.request as IRequest;

  /* Render not found when request is empty */
  if (!request) return <NotFound />;

  return (
    <Box
      sx={{
        flexGrow: 1,
      }}
    >
      {/* Header */}
      <Box m={2}>
        <MessageContainer
          icon={false}
          message="Your request has been submitted."
        />
      </Box>
      <Chip label="Request summary:" sx={{ m: 2 }} />
      {request.newHire && (
        <Button variant="text" startIcon={<GradeIcon />} color="secondary">
          New Hire
        </Button>
      )}

      {/* Request summary */}
      <Box m={2}>
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow
                sx={{
                  '& th': {
                    backgroundColor: 'secondary.dark',
                  },
                }}
              >
                <TableCell>Request Date</TableCell>
                <TableCell>Requester</TableCell>
                <TableCell>Requester Email</TableCell>
                <TableCell>Discipline</TableCell>
                <TableCell>DSC</TableCell>
                <TableCell>Reviewer</TableCell>
                <TableCell>Reviewed Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  {new Date(request.createdTime as Date).toLocaleDateString()}
                </TableCell>
                <TableCell>{request.requester}</TableCell>
                <TableCell>{request.requesterEmail}</TableCell>
                <TableCell>{request.discipline}</TableCell>
                <TableCell>{request.dsc}</TableCell>
                <TableCell>{request.reviewer}</TableCell>
                {request.reviewed && (
                  <TableCell>
                    {new Date(request.reviewed as Date).toLocaleDateString()}
                  </TableCell>
                )}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Box m={2}>
        {request.requestEmployees.map((e, employeeIndex) => (
          <Box key={e.id}>
            <Chip label={employeeIndex + 1} sx={{ m: 1 }} />
            <TableContainer component={Paper} elevation={2}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow
                    sx={{
                      '& th': {
                        color: 'white',
                        backgroundColor: 'primary.main',
                      },
                    }}
                  >
                    <TableCell>Employee Number</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Co Brand</TableCell>
                    <TableCell>Office</TableCell>
                    <TableCell>Ship To</TableCell>
                    <TableCell>Training Completed</TableCell>
                    {request.newHire && <TableCell>WorkFit</TableCell>}

                    <TableCell>Project No</TableCell>
                    <TableCell>Task</TableCell>
                    <TableCell>Org</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>{e.employeeNumber}</TableCell>
                    <TableCell>{e.name}</TableCell>
                    <TableCell>{e.coBrand}</TableCell>
                    <TableCell>{e.office}</TableCell>
                    <TableCell>{e.shipTo}</TableCell>
                    <TableCell>{e.trainingCompleted ? 'Yes' : 'No'}</TableCell>
                    {request.newHire && (
                      <TableCell>{e.workFit ? 'Yes' : 'No'}</TableCell>
                    )}
                    <TableCell>{e.projectNo}</TableCell>
                    <TableCell>{e.task}</TableCell>
                    <TableCell>{e.org}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <Box m={2}>
              <TableContainer component={Paper} elevation={1} key={e.id}>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow
                      sx={{
                        '& th': {
                          backgroundColor: 'secondary.dark',
                        },
                      }}
                    >
                      <TableCell>Category</TableCell>
                      <TableCell>Item</TableCell>
                      <TableCell>Type</TableCell>
                      <TableCell>Size</TableCell>
                      <TableCell>Color</TableCell>
                      <TableCell>Make</TableCell>
                      <TableCell>Model</TableCell>
                      <TableCell>Quantity</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {e.requestItems.map((i, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {i.isStandard ? 'Standard' : 'Non-Standard'}
                        </TableCell>
                        <TableCell>{i.item}</TableCell>
                        <TableCell>{i.type}</TableCell>
                        <TableCell>{i.size}</TableCell>
                        <TableCell>{i.color}</TableCell>
                        <TableCell>{i.make}</TableCell>
                        <TableCell>{i.model}</TableCell>
                        <TableCell>{i.quantity}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {request.status === 'Reviewed' && (
                <Button
                  variant="contained"
                  size="small"
                  style={{ marginLeft: 16 }}
                  sx={{
                    width: 100,
                    bgcolor: `status${e.status.replaceAll(' ', '')}.light`,
                    color: `status${e.status.replaceAll(' ', '')}.main`,
                    borderColor: `status${e.status.replaceAll(' ', '')}.main`,
                    '&:hover': {
                      bgcolor: `status${e.status.replaceAll(' ', '')}.dark`,
                    },
                  }}
                >
                  {e.status}
                </Button>
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {/* Footer gap */}
      <Box height={128}></Box>
    </Box>
  );
};

export default RequestConfirm;
