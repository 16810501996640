import GradeIcon from '@mui/icons-material/Grade';
import GradingIcon from '@mui/icons-material/Grading';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  TextField,
  Typography,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridFilterInputSingleSelectProps,
  GridFilterItem,
  GridRenderCellParams,
  GridRowId,
  GridToolbar,
} from '@mui/x-data-grid';
import UseGetQuery from 'app/api/useGetQuery';
import MessageContainer from 'app/common/modals/MessageContainer';
import StatusIcon from 'app/common/modals/StatusIcon';
import { stringAvatar } from 'app/common/util/util';
import { IRequestEmployee, IRequestStatus } from 'app/models/request';
import { IEmployee } from 'app/models/user';
import { useRequestStore } from 'app/stores/requestStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const Request = () => {
  /* State and functions from custom hooks */
  const { requestList, requestListLoading, setRequestList } = useRequestStore();
  const { requestPage, requestPageSize } = useParams();
  const { employeeList, employeeListLoading, setEmployeeList } = useUserStore();

  /* Define component state variables */
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: Number(requestPageSize),
    page: Number(requestPage),
  });

  /* Custom Employee filter input */
  const ItemsInputValue = (props: GridFilterInputSingleSelectProps) => {
    const { item, applyValue } = props;

    return (
      <Autocomplete
        loading={employeeListLoading}
        autoHighlight
        value={item.value ?? null}
        onChange={(event, selectedEmployee: IEmployee | null) => {
          if (selectedEmployee) {
            applyValue({ ...item, value: selectedEmployee });
          }
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === 'clear') {
            applyValue({ ...item, value: null });
            return;
          }
        }}
        id="controllable-states-demo"
        options={employeeList}
        getOptionLabel={(option: IEmployee) =>
          option.displayName
            ? option.displayName
            : `${option.firstName} ${option.lastName}`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            label="Employee"
            variant="standard"
            sx={{
              '& .MuiFormLabel-asterisk': {
                color: 'warning.main',
              },
            }}
          />
        )}
        renderOption={(props, option) => (
          <Box
            component="li"
            width="100%"
            sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
            {...props}
            key={option.email}
          >
            <Typography>
              {option.displayName
                ? option.displayName
                : `${option.firstName} ${option.lastName}`}
            </Typography>
          </Box>
        )}
      />
    );
  };

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 70 },
    {
      field: 'requester',
      headerName: 'Requester',
      width: 180,
    },
    { field: 'discipline', headerName: 'Discipline', width: 220 },
    { field: 'dsc', headerName: 'DSC', width: 180 },
    {
      field: 'employees',
      headerName: 'Request Employees',
      width: 300,
      type: 'singleSelect',
      filterOperators: [
        {
          value: 'contains',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value === '') {
              return null;
            }

            return (value, row, column, apiRef) => {
              return row.requestEmployees.some(
                (e: IRequestEmployee) =>
                  e.employeeNumber === filterItem.value.employeeNumber
              );
            };
          },

          InputComponent: ItemsInputValue,
        },
      ],

      valueGetter: (value, row) =>
        row.requestEmployees.map((e: IRequestEmployee) => {
          if (e) return e;
        }),

      renderCell: (params: GridRenderCellParams<any, string>) => {
        let updatedEmployees = params.row.requestEmployees.map(
          (employee: IRequestEmployee) => {
            if (!employee.email) {
              employee.email = employeeList.find(
                (e) => e.employeeNumber === employee.employeeNumber
              )?.email;
            }
            let response = UseGetQuery(employee.email);
            return {
              ...employee,
              src: response.data,
            };
          }
        );

        return (
          <AvatarGroup max={4} sx={{ flexDirection: 'row' }}>
            {updatedEmployees.map((e: any, index: number) => {
              return (
                <Avatar
                  key={index}
                  alt={e.name}
                  src={e.src}
                  sx={{ ml: '-8px !important' }}
                  {...stringAvatar(e.name)}
                />
              );
            })}
          </AvatarGroup>
        );
      },
    },
    {
      field: 'createdTime',
      headerName: 'Requested Date',
      width: 120,
      valueFormatter: (value) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'reviewed status',
      headerName: 'Reviewed Count / Total Count',
      headerAlign: 'center',
      width: 300,
      renderCell: (params: GridRenderCellParams<any, IRequestStatus>) => {
        const reviewedCount = params.row.requestEmployees.filter(
          (e: IRequestEmployee) =>
            e.status === 'Approved' || e.status === 'Rejected'
        ).length;
        const totalCount = params.row.requestEmployees.length;

        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
            }}
          >
            <Button
              sx={{
                bgcolor: `statusShipped.light`,
                color: `statusShipped.main`,
                borderColor: `statusShipped.main`,
                '&:hover': {
                  bgcolor: `statusShipped.dark`,
                },
              }}
              size="small"
              startIcon={<Box>{reviewedCount}</Box>}
              endIcon={<Box>{totalCount}</Box>}
            >
              /
            </Button>
          </Box>
        );
      },
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      width: 300,
      renderCell: (params: GridRenderCellParams<any, IRequestStatus>) => (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}
        >
          <Button
            variant="contained"
            size="small"
            tabIndex={params.hasFocus ? 0 : -1}
            sx={{
              ...(params.value === 'Awaiting Additional Review'
                ? { width: 220 }
                : { width: 120 }),
              bgcolor: `status${params?.value?.replaceAll(' ', '')}.light`,
              color: `status${params?.value?.replaceAll(' ', '')}.main`,
              borderColor: `status${params?.value?.replaceAll(' ', '')}.main`,
              '&:hover': {
                bgcolor: `status${params?.value?.replaceAll(' ', '')}.dark`,
              },
              justifyContent: 'left',
            }}
            startIcon={<StatusIcon status={params.value} />}
          >
            {params.value}
          </Button>
        </Box>
      ),
    },
    {
      field: 'newHire',
      headerName: 'New Hire',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params: GridRenderCellParams<any, IRequestStatus>) => {
        if (params.row.newHire) {
          return (
            <Button
              variant="text"
              size="small"
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              color="secondary"
              startIcon={<GradeIcon />}
            ></Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button
          component={Link}
          to={`/admin/ppeRequest/${paginationModel.pageSize}/${paginationModel.page}/${params.row.id}`}
        >
          View Details
        </Button>
      ),
    },
  ];

  /* Fetch request list on component mount */
  useEffect(() => {
    setRequestList();
    setEmployeeList();
  }, [setRequestList, setEmployeeList]);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<GradingIcon />} message="PPE Request" />
      </Box>
      {/* request list table */}
      <Box m={2}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={requestList}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
              setSelectionModel([...newSelection])
            }
            rowSelectionModel={selectionModel}
            autoHeight
            loading={requestListLoading}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: Number(requestPageSize),
                  page: Number(requestPage),
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
          />
        </Box>
      </Box>
      {/* Footer spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default Request;
