import AddIcon from '@mui/icons-material/Add';
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits';
import RemoveIcon from '@mui/icons-material/Remove';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import { no_image_png } from 'app/common/options/noImage';
import { customizedItemImage } from 'app/common/options/ppeOptions';
import { firstImageSrc, useExtraSmallScreen } from 'app/common/util/util';
import { useCartStore } from 'app/stores/cartStore';
import React from 'react';
import { Link } from 'react-router-dom';

const Cart = () => {
  /* State and functions from custom hooks */
  const {
    items,
    totalAmount,
    cartCategory,
    addItemsToCart,
    removeItemsFromCart,
    customizedItems,
    addCustomizedItemsToCart,
    removeCustomizedItemsFromCart,
  } = useCartStore();
  const extraSmallScreen = useExtraSmallScreen();

  /* Render empty cart message if no items in the cart */
  if (items.length === 0 && customizedItems.length === 0)
    return (
      <MessageContainer
        icon={<ProductionQuantityLimitsIcon />}
        message="Your cart is empty"
      />
    );

  return (
    <Box>
      {/* Cart heading */}
      <Box m={2}>
        <MessageContainer icon={<ShoppingBagIcon />} message="Cart" />
      </Box>
      {/* Cart content */}
      <Paper
        sx={{
          m: 2,
          bgcolor: 'secondary.dark',
          minHeight: 400,
        }}
      >
        {/* Render each item in the cart */}
        {(items.length || customizedItems.length) && (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                {!extraSmallScreen && <TableCell align="right"></TableCell>}
                <TableCell align="right">Size</TableCell>
                <TableCell align="right">Color</TableCell>
                {customizedItems.length > 0 && (
                  <TableCell align="right">Make</TableCell>
                )}
                {customizedItems.length > 0 && (
                  <TableCell align="right">Model</TableCell>
                )}
                <TableCell align="right">Quantity</TableCell>
                {cartCategory?.isPriceable && (
                  <TableCell align="right">Amount</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {items.map((orderItem, index) => (
                <TableRow
                  key={index}
                  sx={{
                    width: '100%',
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Link
                      to={`/inventory/${orderItem.item.categoryId}/${orderItem.item.id}`}
                    >
                      <Box
                        component="img"
                        src={firstImageSrc(orderItem.item.images)}
                        height={50}
                        width={50}
                        onError={(e) => {
                          e.currentTarget.src = no_image_png;
                        }}
                        alt={orderItem.item.name}
                        sx={{
                          cursor: 'pointer',
                          objectFit: 'contain',
                          '&:hover': {
                            opacity: 0.8,
                          },
                        }}
                      />
                    </Link>
                  </TableCell>
                  {!extraSmallScreen && (
                    <TableCell component="th" scope="row">
                      <Typography variant="subtitle2">
                        {orderItem.item.name}
                      </Typography>
                    </TableCell>
                  )}

                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      {orderItem.item.size}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      {orderItem.item.color}
                    </Typography>
                  </TableCell>
                  {customizedItems.length > 0 && (
                    <TableCell align="right"></TableCell>
                  )}
                  {customizedItems.length > 0 && (
                    <TableCell align="right"></TableCell>
                  )}
                  <TableCell align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          variant="outlined"
                          disableRipple
                          size="small"
                          startIcon={
                            <RemoveIcon
                              sx={{
                                m: { xs: 0, sm: 1 },
                                '&:hover': { color: 'secondary.main' },
                              }}
                              onClick={() =>
                                removeItemsFromCart(orderItem.item, 1)
                              }
                            />
                          }
                          endIcon={
                            <AddIcon
                              sx={{
                                m: { xs: 0, sm: 1 },
                                '&:hover': { color: 'secondary.main' },
                              }}
                              onClick={() => addItemsToCart(orderItem.item, 1)}
                            />
                          }
                          sx={{
                            color: 'black',
                            borderColor: 'black',
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: 36,
                            mt: 1,
                          }}
                        >
                          {orderItem.qty}
                        </Button>
                        <Button
                          variant="text"
                          size="small"
                          sx={{
                            color: 'black',
                            textDecoration: 'underline',
                            fontSize: 10,
                          }}
                          onClick={() =>
                            removeItemsFromCart(orderItem.item, orderItem.qty)
                          }
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                  {cartCategory?.isPriceable && (
                    <TableCell align="right">
                      <Typography variant="subtitle2">
                        ${(orderItem.price * orderItem.qty).toFixed(2)}
                      </Typography>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
            <TableBody>
              {customizedItems.map((orderItem, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:last-child td, &:last-child th': {
                      border: 0,
                    },
                  }}
                >
                  <TableCell component="th" scope="row">
                    <Box
                      component="img"
                      src={firstImageSrc(orderItem.item.images)}
                      height={50}
                      width={50}
                      onError={(e) => {
                        e.currentTarget.src = customizedItemImage;
                      }}
                      alt={orderItem.item.name}
                      sx={{
                        cursor: 'pointer',
                        objectFit: 'contain',
                        '&:hover': {
                          opacity: 0.8,
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">
                      {orderItem.item.name}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      {orderItem.item.size}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      {orderItem.item.color}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      {orderItem.item.make}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      {orderItem.item.model}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Box display="flex" justifyContent="flex-end">
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                      >
                        <Button
                          variant="outlined"
                          disableRipple
                          size="small"
                          startIcon={
                            <RemoveIcon
                              sx={{
                                m: { xs: 0, sm: 1 },
                                '&:hover': { color: 'secondary.main' },
                              }}
                              onClick={() =>
                                removeCustomizedItemsFromCart(orderItem.item, 1)
                              }
                            />
                          }
                          endIcon={
                            <AddIcon
                              sx={{
                                m: { xs: 0, sm: 1 },
                                '&:hover': { color: 'secondary.main' },
                              }}
                              onClick={() =>
                                addCustomizedItemsToCart(orderItem.item, 1)
                              }
                            />
                          }
                          sx={{
                            color: 'black',
                            borderColor: 'black',
                            display: 'flex',
                            justifyContent: 'space-around',
                            height: 36,
                            mt: 1,
                          }}
                        >
                          {orderItem.qty}
                        </Button>
                        <Button
                          variant="text"
                          size="small"
                          sx={{
                            color: 'black',
                            textDecoration: 'underline',
                            fontSize: 10,
                          }}
                          onClick={() =>
                            removeCustomizedItemsFromCart(
                              orderItem.item,
                              orderItem.qty
                            )
                          }
                        >
                          Remove
                        </Button>
                      </Box>
                    </Box>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              {cartCategory?.isPriceable && (
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {!extraSmallScreen && <TableCell></TableCell>}
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    Subtotal:
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    ${totalAmount.toFixed(2)}
                  </TableCell>
                </TableRow>
              )}
            </TableFooter>
          </Table>
        )}
      </Paper>
      {/* submit button */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        mt={10}
        ml={2}
        mr={2}
      >
        <Button variant="contained" component={Link} to="/checkout">
          Checkout
        </Button>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default Cart;
