import { Grid, ListItem, ListItemText } from '@mui/material';
import React from 'react';

interface ProductListItemProps {
  listItemLabel: string;
  listItemLabelHelpText?: string;
  listItemContent: JSX.Element;
}

const ProductListItem = ({
  listItemLabel,
  listItemContent,
  listItemLabelHelpText,
}: ProductListItemProps) => {
  return (
    <ListItem alignItems="flex-start">
      <Grid container>
        <Grid item xs={12} sm={4}>
          <ListItemText
            primary={listItemLabel}
            primaryTypographyProps={{
              fontWeight: 500,
              fontSize: 15,
            }}
            secondary={listItemLabelHelpText}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          {listItemContent}
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default ProductListItem;
