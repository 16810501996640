import { Alert, Snackbar } from '@mui/material';
import { useModalStore } from 'app/stores/modalStore';
import React from 'react';

const SnackBarInfo = () => {
  const { snackBar, setSnackBar } = useModalStore();

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === 'clickaway') return;
    setSnackBar({ ...snackBar, open: false });
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={snackBar.open}
      onClose={handleClose}
      autoHideDuration={6000}
    >
      <Alert
        onClose={handleClose}
        severity={snackBar.type}
        sx={{ width: '100%' }}
      >
        {snackBar.content}
      </Alert>
    </Snackbar>
  );
};

export default SnackBarInfo;
