import { Box, Typography } from '@mui/material';
import React from 'react';

const Banner = () => {
  return (
    <Box
      sx={{
        background: "url('/assets/banner_bg.jpg')",
        zIndex: 999,
        height: '350px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        color: 'white',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box>
          <Typography
            sx={{
              fontWeight: '300',
              fontSize: '47px',
              m: 2,
            }}
          >
            CED Branded Merchandise
          </Typography>
          <Typography
            sx={{
              fontWeight: '400',
              fontSize: '22px',
              m: 2,
            }}
          >
            A unique selection of CED-branded merchandise
          </Typography>
        </Box>
      </Box>
      <Box
        component="img"
        alt="CED Logo"
        src="/assets/spot-items.png"
        width={300}
        height={300}
        display={{ xs: 'none', sm: 'none', md: 'block' }}
      />
    </Box>
  );
};

export default Banner;
