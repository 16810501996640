import { useMsal } from '@azure/msal-react';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { useModalStore } from 'app/stores/modalStore';
import { useOrderStore } from 'app/stores/orderStore';
import { useUserStore } from 'app/stores/userStore';
import OrderContent from 'features/admin/order/OrderContent';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

const MyOrderDetails = () => {
  /* State and functions from custom hooks */
  const { orderId }: any = useParams();
  const { myOrder, myOrderLoading, setMyOrder, receiveOrder } = useOrderStore();
  const { setSnackBar } = useModalStore();
  const { adminUser } = useUserStore();
  const navigate = useNavigate();
  const { accounts } = useMsal();

  /* Define component state variables */
  const componentRef = useRef(null);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const isAdmin = adminUser.length > 0;

  /* Function to handle receiving the order */
  const handleReceiveOrder = () => {
    setSubmitLoading(true);
    if (myOrder) {
      receiveOrder(myOrder)
        .then((response) => {
          if (response?.status === 200) {
            setMyOrder(orderId, accounts[0]?.username, isAdmin);
            setSnackBar({
              open: true,
              type: 'success',
              content: `Order has been marked received.`,
            });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Order making received is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetch order details on component mount */
  useEffect(() => {
    setMyOrder(orderId, accounts[0]?.username, isAdmin);
  }, [orderId, accounts, setMyOrder]);

  /* Render loading spinner if order is still loading */
  /* Render NotFound component if order is not found */
  if (myOrderLoading) {
    return <Loading />;
  } else if (!myOrder) {
    return <NotFound />;
  }

  return (
    <Box mx={2} mt={2}>
      {/* Back to table and print order buttons */}
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            navigate(`/my-orders`);
          }}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Order
            </Button>
          )}
        />
      </Box>
      <Box ref={componentRef}>
        <OrderContent order={myOrder} ref={componentRef} />
      </Box>
      {/* mark receive */}
      {myOrder?.status === 'Shipped' && (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={10}
          ml={2}
          mr={2}
        >
          <LoadingButton
            variant="contained"
            size="large"
            onClick={handleReceiveOrder}
            loading={submitLoading}
          >
            Mark Receive My Order
          </LoadingButton>
        </Box>
      )}

      {/* Footer spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default MyOrderDetails;
