import { Card, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import React, { ReactNode } from 'react';

interface FormSectionProps {
  sectionTitle: string;
  children: ReactNode;
  action?: ReactNode;
}

const FormSection = ({ sectionTitle, children, action }: FormSectionProps) => {
  return (
    <Grid item xs={12}>
      <Card>
        <CardHeader
          sx={{ height: 80, bgcolor: 'secondary.dark' }}
          title={sectionTitle}
          titleTypographyProps={{ variant: 'button' }}
          action={action}
        />
        <Divider />
        <CardContent
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            m: 1,
          }}
        >
          <Grid container spacing={2}>
            {children}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
};
export default FormSection;
