import {
  Alert,
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { statusToStepMap } from 'app/common/options/activeSteps';
import { no_image_png } from 'app/common/options/noImage';
import { steps } from 'app/common/options/orderSteps';
import { customizedItemImage } from 'app/common/options/ppeOptions';
import {
  firstImageSrc,
  stringAvatar,
  useSmallScreen,
} from 'app/common/util/util';
import { IOrder } from 'app/models/order';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OrderListItem from './OrderListItem';

export interface OrderContentProps {
  order?: IOrder;
}

const OrderContent = React.forwardRef<HTMLDivElement, OrderContentProps>(
  ({ order }, ref) => {
    /* State and functions from custom hooks */
    const { setOrderPreviewOpen } = useModalStore();
    const {
      requesterPhoto,
      requesterPhotoLoading,
      setRequesterPhoto,
      employeeList,
      employeeListLoading,
      setEmployeeList,
    } = useUserStore();
    const smallScreen = useSmallScreen();

    /* Define component state variables */
    const [activeStep, setActiveStep] = useState(1);
    const defaultStep = 1;

    /* Fetch requester photo and set active step based on order status on component mount */
    useEffect(() => {
      const stepNumber = order?.status
        ? statusToStepMap[order.status]
        : defaultStep;
      setActiveStep(stepNumber);
      if (order) setRequesterPhoto(order.requesterEmail);
      setEmployeeList();
    }, [setRequesterPhoto, order, setEmployeeList]);

    return (
      <Box ref={ref}>
        {/* Toolbar containing order ID and status */}
        <Toolbar
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            backgroundColor: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
              Order ID # : {order?.id}
            </Typography>
          </Box>
          {order?.isMarketingStarterPack && (
            <Chip label="Marketing Stater Pack" color="secondary" />
          )}

          <Button
            variant="contained"
            size="small"
            style={{ marginLeft: 16 }}
            sx={{
              width: 100,
              bgcolor: `status${order?.status?.replaceAll(' ', '')}.light`,
              color: `status${order?.status?.replaceAll(' ', '')}.main`,
              borderColor: `status${order?.status?.replaceAll(' ', '')}.main`,
              '&:hover': {
                bgcolor: `status${order?.status?.replaceAll(' ', '')}.dark`,
              },
            }}
          >
            {order?.status}
          </Button>
        </Toolbar>
        {/* TableContainer containing order details */}
        <TableContainer component={Paper} elevation={3} sx={{ mb: 2, mt: 2 }}>
          {/* Display requester's information */}
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <IconButton sx={{ p: 2 }}>
              {requesterPhotoLoading ? (
                <Skeleton
                  variant="circular"
                  width={40}
                  height={40}
                  sx={{ mr: 1 }}
                />
              ) : (
                <Avatar
                  sx={{
                    color: 'white',
                    width: 40,
                    height: 40,
                    mr: 1,
                  }}
                  src={requesterPhoto}
                  {...stringAvatar(order?.requester!)}
                />
              )}
            </IconButton>
            <Box>
              <Typography sx={{ fontSize: 15, fontWeight: 500 }}>
                {order?.requester}
              </Typography>
              <Button
                variant="outlined"
                sx={{
                  borderRadius: 8,
                  p: 0,
                }}
              >
                <Typography fontSize={10}>REQUESTER</Typography>
              </Button>
            </Box>
            {!employeeListLoading &&
              !employeeList.some(
                (e) =>
                  e.email.toLowerCase() === order?.requesterEmail.toLowerCase()
              ) && (
                <Box m={2}>
                  <Alert severity="warning">
                    This employee cannot be found currently. Please verify the
                    employee's status.
                  </Alert>
                </Box>
              )}
          </Box>
          <Divider />
          {/* Display order details */}
          <Box>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              <ListItem>
                <ListItemText
                  primary="Email:"
                  secondary={order?.requesterEmail}
                  primaryTypographyProps={{ fontWeight: 500, fontSize: 15 }}
                />
              </ListItem>
              {order?.employeeNumber && (
                <ListItem>
                  <ListItemText
                    primary="Employee Number"
                    secondary={order?.employeeNumber}
                    primaryTypographyProps={{ fontWeight: 500, fontSize: 15 }}
                  />
                </ListItem>
              )}
            </List>
          </Box>
        </TableContainer>
        {/* TableContainer containing order status stepper */}
        <TableContainer component={Paper} elevation={3} sx={{ mb: 2, mt: 2 }}>
          <Stepper
            activeStep={activeStep}
            alternativeLabel={!smallScreen}
            orientation={smallScreen ? 'vertical' : 'horizontal'}
            sx={{ p: 2 }}
          >
            {steps.map((label, index) => {
              return (
                <Step key={label.name} active={index < activeStep}>
                  <StepLabel>
                    <Typography variant="subtitle2">
                      {label.description}
                    </Typography>
                  </StepLabel>
                  <Box
                    component={smallScreen ? StepContent : Box}
                    sx={
                      !smallScreen
                        ? {
                            border: 0,
                            m: 0,
                            p: 0,
                            justifyContent: 'center',
                            display: 'flex',
                          }
                        : null
                    }
                  >
                    <Typography variant="caption">
                      {index === 0 &&
                        index < activeStep &&
                        order?.requested &&
                        new Date(order?.requested as Date).toLocaleDateString()}
                      {index === 1 &&
                        index < activeStep &&
                        (order?.status === 'Approved' ||
                          order?.status === 'Shipped' ||
                          order?.status === 'Picked up' ||
                          order?.status === 'Received') &&
                        order?.approved &&
                        new Date(order?.approved as Date).toLocaleDateString()}
                      {index === 1 &&
                        index < activeStep &&
                        order?.status === 'Rejected' &&
                        order?.rejected &&
                        new Date(order?.rejected as Date).toLocaleDateString()}
                      {index === 2 &&
                        index < activeStep &&
                        (order?.status === 'Shipped' ||
                          order?.status === 'Picked up') &&
                        order?.shipped &&
                        new Date(order?.shipped as Date).toLocaleDateString()}
                    </Typography>
                  </Box>
                </Step>
              );
            })}
          </Stepper>
        </TableContainer>
        {/* TableContainer containing order details */}
        <TableContainer component={Paper} elevation={3} sx={{ mb: 2 }}>
          <Box>
            <List
              sx={{
                width: '100%',
                bgcolor: 'background.paper',
              }}
            >
              <OrderListItem
                leftLabel="Date Requested:"
                rightLabel={new Date(
                  order?.requested as Date
                ).toLocaleDateString()}
              />
              <OrderListItem
                leftLabel={
                  order?.isMarketingStarterPack ? 'Office Contact' : 'For Whom:'
                }
                rightLabel={order?.forWhom}
              />
              {!order?.isMarketingStarterPack && (
                <OrderListItem leftLabel="Reason:" rightLabel={order?.reason} />
              )}
              {order?.isMarketingStarterPack && (
                <OrderListItem
                  leftLabel="Office Employee Count:"
                  rightLabel={String(order?.officeEmployeeCount)}
                />
              )}

              {order?.reasonDesc && (
                <OrderListItem
                  leftLabel="Other Reason Description:"
                  rightLabel={order?.reasonDesc}
                />
              )}
              {order?.redeemCode && (
                <OrderListItem
                  leftLabel="Redeem Code:"
                  rightLabel={order?.redeemCode}
                />
              )}
              {order?.redeemCode && (
                <OrderListItem
                  leftLabel="Redeem Amount:"
                  rightLabel={`$${order.redeemAmount}`}
                />
              )}
              {(order?.status === 'Approved' || order?.status === 'Shipped') &&
                order?.approver && (
                  <OrderListItem
                    leftLabel="Approver:"
                    rightLabel={order?.approver.name}
                  />
                )}
              {order?.status === 'Rejected' && order?.rejecter && (
                <OrderListItem
                  leftLabel="Rejecter:"
                  rightLabel={order?.rejecter.name}
                />
              )}
              {order?.status === 'Shipped' && order?.shipper && (
                <OrderListItem
                  leftLabel="Shipper:"
                  rightLabel={order?.shipper.name}
                />
              )}
              {order?.shippingMethod && (
                <OrderListItem
                  leftLabel="Shipping Method:"
                  rightLabel={order?.shippingMethod}
                />
              )}
              {order?.trackingInfo && (
                <OrderListItem
                  leftLabel="Tracking Info:"
                  rightLabel={order?.trackingInfo}
                />
              )}
              {order?.eta && (
                <OrderListItem
                  leftLabel="Shipping ETA:"
                  rightLabel={new Date(order?.eta as Date).toLocaleDateString()}
                />
              )}
              {order?.adminComments && (
                <OrderListItem
                  leftLabel="Admin Comments:"
                  rightLabel={order?.adminComments}
                />
              )}
              {order?.comments && (
                <OrderListItem
                  leftLabel="Comments:"
                  rightLabel={order?.comments}
                />
              )}
              {order?.office && (
                <OrderListItem leftLabel="Office:" rightLabel={order?.office} />
              )}
              {order?.shipName && (
                <OrderListItem
                  leftLabel="Shipping Receiver:"
                  rightLabel={order?.shipName}
                />
              )}
              {order?.addrLn1 && (
                <OrderListItem
                  leftLabel="Shipping Address Line 1:"
                  rightLabel={order?.addrLn1}
                />
              )}
              {order?.addrLn2 && (
                <OrderListItem
                  leftLabel="Shipping Address Line 2:"
                  rightLabel={order?.addrLn1}
                />
              )}

              {order?.city && (
                <OrderListItem
                  leftLabel="Shipping City:"
                  rightLabel={order?.city}
                />
              )}
              {order?.state && (
                <OrderListItem
                  leftLabel="Shipping State:"
                  rightLabel={order?.state}
                />
              )}
              {order?.zip && (
                <OrderListItem
                  leftLabel="Shipping ZipCode:"
                  rightLabel={order?.zip}
                />
              )}
              {order?.priority && (
                <OrderListItem
                  leftLabel="Priority Order:"
                  rightLabel={order?.priority ? 'Yes' : 'No'}
                />
              )}
              {order?.priorityDate && (
                <OrderListItem
                  leftLabel="Priority Date:"
                  rightLabel={new Date(
                    order?.priorityDate as Date
                  ).toLocaleDateString()}
                />
              )}
            </List>
          </Box>
        </TableContainer>
        {/* TableContainer containing order items details */}
        <TableContainer component={Paper} elevation={3}>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Product</TableCell>
                <TableCell></TableCell>
                {!order?.isMarketingStarterPack && (
                  <>
                    <TableCell align="right">Size</TableCell>
                    <TableCell align="right">Color</TableCell>
                    <TableCell align="right">Make</TableCell>
                    <TableCell align="right">Model</TableCell>
                  </>
                )}
                <TableCell align="right">Available Quantity</TableCell>
                <TableCell align="right">Quantity</TableCell>
                {order?.isPriceable && (
                  <TableCell align="right">Amount</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {order?.items
                ?.filter((i) => i.item !== null)
                .map((orderItem) => (
                  <TableRow key={orderItem.id}>
                    <TableCell component="th" scope="row">
                      <Link
                        to={`/inventory/${orderItem.item.categoryId}/${orderItem.item.id}`}
                        onClick={() => setOrderPreviewOpen(false)}
                      >
                        <Box
                          component="img"
                          src={firstImageSrc(orderItem.item.images)}
                          height={50}
                          width={50}
                          onError={(e) => {
                            e.currentTarget.src = no_image_png;
                          }}
                          alt={orderItem.item.name}
                          sx={{
                            cursor: 'pointer',
                            objectFit: 'contain',
                            '&:hover': {
                              opacity: 0.8,
                            },
                          }}
                        />
                      </Link>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderItem.item.name}
                    </TableCell>
                    {!order?.isMarketingStarterPack && (
                      <>
                        <TableCell align="right">
                          {orderItem.item.size}
                        </TableCell>
                        <TableCell align="right">
                          {orderItem.item.color}
                        </TableCell>
                        <TableCell align="right">
                          {orderItem.item.make}
                        </TableCell>
                        <TableCell align="right">
                          {orderItem.item.model}
                        </TableCell>
                      </>
                    )}
                    <TableCell align="right">{orderItem.item.qty}</TableCell>
                    <TableCell align="right">{orderItem.qty}</TableCell>
                    {order.isPriceable && (
                      <TableCell align="right">
                        {orderItem?.amount?.toFixed(2)}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
            <TableBody>
              {order?.items
                ?.filter((i) => i.customizedItem !== null)
                .map((orderItem, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <Box
                        component="img"
                        src={firstImageSrc(orderItem.customizedItem?.images)}
                        height={50}
                        width={50}
                        onError={(e) => {
                          e.currentTarget.src = customizedItemImage;
                        }}
                        alt={orderItem.item?.name}
                        sx={{
                          cursor: 'pointer',
                          objectFit: 'contain',
                          '&:hover': {
                            opacity: 0.8,
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell component="th" scope="row">
                      {orderItem.customizedItem?.name}
                    </TableCell>
                    <TableCell align="right">
                      {orderItem.customizedItem?.size}
                    </TableCell>
                    <TableCell align="right">
                      {orderItem.customizedItem?.color}
                    </TableCell>
                    <TableCell align="right">
                      {orderItem.customizedItem?.make}
                    </TableCell>
                    <TableCell align="right">
                      {orderItem.customizedItem?.model}
                    </TableCell>
                    <TableCell align="right">{orderItem.qty}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
            <TableFooter>
              {order?.isPriceable && (
                <>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 'bold', fontSize: 14 }}
                    >
                      Subtotal:
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 'bold', fontSize: 14 }}
                    >
                      ${order.subTotal?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 'bold', fontSize: 14 }}
                    >
                      Credits:
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        fontSize: 14,
                      }}
                    >
                      -$
                      {order.redeemAmount ? order.redeemAmount.toFixed(2) : 0}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 'bold', fontSize: 14 }}
                    >
                      Total:
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{ fontWeight: 'bold', fontSize: 14 }}
                    >
                      $
                      {order.subTotal && order.redeemAmount
                        ? (order.subTotal - order.redeemAmount).toFixed(2)
                        : order.subTotal?.toFixed(2)}
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableFooter>
          </Table>
        </TableContainer>
        {/* Space filler for footer top */}
        <Box height={64}></Box>
      </Box>
    );
  }
);

export default OrderContent;
