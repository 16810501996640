import SmartToyIcon from '@mui/icons-material/SmartToy';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface NotFoundProps {
  error?: string;
}

const NotFound = ({ error }: NotFoundProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      bgcolor="primary.main"
      height="100vh"
    >
      <Card sx={{ width: 345, bgcolor: 'primary.dark' }}>
        <CardMedia
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          children={
            <SmartToyIcon sx={{ height: 300, width: 150, color: 'white' }} />
          }
        />
        <CardContent
          sx={{
            color: 'white',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography gutterBottom variant="h5" component="div">
              Whoops!
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              m: 2,
            }}
          >
            <Typography variant="body2" color="gray">
              {error ??
                'It seems like we could not find the page you were looking for '}
            </Typography>
          </Box>
        </CardContent>
        <CardActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            m: 2,
          }}
        >
          <Button size="small" variant="contained" component={Link} to="/">
            Home Page
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

export default NotFound;
