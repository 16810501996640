import { Box, Typography } from '@mui/material';
import React from 'react';

interface FormHeaderProps {
  text: string;
}

const FormHeader = ({ text }: FormHeaderProps) => {
  return (
    <Box
      sx={{
        background: "url('/assets/banner_bg.jpg')",
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        flex: 1,
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '200px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: { xs: 'left', sm: 'center' },
            justifyContent: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Typography
            color={'white'}
            sx={{
              fontWeight: 'bold',
              fontSize: { xs: 24, sm: 36, lg: 56 },
              p: 1,
              backgroundColor: 'primary.main',
            }}
          >
            {text}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FormHeader;
