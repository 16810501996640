import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from '@mui/x-data-grid';

import { useMsal } from '@azure/msal-react';
import HistoryIcon from '@mui/icons-material/History';
import MessageContainer from 'app/common/modals/MessageContainer';
import { useOrderStore } from 'app/stores/orderStore';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const MyOrders = () => {
  /* State and functions from custom hooks */
  const { ordersByEmail, ordersByEmailLoading, setOrdersByEmail } =
    useOrderStore();
  const { accounts } = useMsal();

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Order#', width: 70 },
    { field: 'office', headerName: 'Office', width: 180 },
    { field: 'forWhom', headerName: 'For Whom', width: 200 },
    {
      field: 'requested',
      headerName: 'Requested Date',
      width: 150,
      valueFormatter: (value) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 400,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <Button
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          sx={{
            width: 100,
            bgcolor: `status${params?.value?.replaceAll(' ', '')}.light`,
            color: `status${params?.value?.replaceAll(' ', '')}.main`,
            borderColor: `status${params?.value?.replaceAll(' ', '')}.main`,
            '&:hover': {
              bgcolor: `status${params?.value?.replaceAll(' ', '')}.dark`,
            },
          }}
        >
          {params?.value}
        </Button>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button component={Link} to={`/my-orders/${params.row.id}`}>
          View Details
        </Button>
      ),
    },
  ];

  /* Fetch orders on component mount */
  useEffect(() => {
    setOrdersByEmail(accounts[0]?.username);
  }, [setOrdersByEmail, accounts]);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<HistoryIcon />} message="My Orders" />
      </Box>
      {/*  My orders table */}
      <Box m={2}>
        <Box sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={ordersByEmail}
            columns={columns}
            checkboxSelection
            loading={ordersByEmailLoading}
            disableRowSelectionOnClick={true}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </Box>
      </Box>

      {/* footer spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default MyOrders;
