import { useMsal } from '@azure/msal-react';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Paper,
  Select,
  SelectChangeEvent,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Loading from 'app/common/modals/Loading';
import { reasons } from 'app/common/options/reasonOptions';
import { IOrder, IOrderStatus } from 'app/models/order';
import { IEmployee } from 'app/models/user';
import { useCartStore } from 'app/stores/cartStore';
import { useCedCoinStore } from 'app/stores/cedCoinStore';
import { useModalStore } from 'app/stores/modalStore';
import { useOrderStore } from 'app/stores/orderStore';
import { useUserStore } from 'app/stores/userStore';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CheckoutTextField from './CheckoutTextField';

const CheckoutForm = () => {
  /* State and functions from custom hooks */
  const {
    items,
    totalAmount,
    cartCategory,
    emptyCart,
    customizedItems,
    cartRequestEmployee,
  } = useCartStore();
  const { submitOrder, submitPpeOrder } = useOrderStore();
  const { setSnackBar } = useModalStore();
  const { userCedCoinBalance, setCedCoinItemList } = useCedCoinStore();
  const { accounts } = useMsal();
  const {
    user,
    adminUser,
    officeList,
    officeListLoading,
    setOfficeList,
    employeeList,
    employeeListLoading,
    setEmployeeList,
  } = useUserStore();
  const navigate = useNavigate();

  /* Define component state variables */
  const [location, setLocation] = useState<string>('office');
  const [disclaimerChecked, setDisclaimerChecked] = useState<boolean>(false);
  const [requestedEmployee, setRequestedEmployee] = useState<IEmployee | null>(
    null
  );
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const initialOrderState = {
    requester: '',
    requesterEmail: '',
    status: 'Requested' as IOrderStatus,
    items: [],
    office: '',
    shipName: '',
    addrLn1: '',
    addrLn2: '',
    city: '',
    state: '',
    zip: '',
    comments: '',
    reason: '',
    reasonDesc: '',
    forWhom: '',
    priority: false,
    priorityDate: undefined,
    isPriceable: false,
    employeeNumber: '',
  };

  const [order, setOrder] = useState<IOrder>(initialOrderState);

  /* handle  submission */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setSubmitLoading(true);

    if (order.categoryId === 6) {
      const admin = adminUser.find((a) => a.categoryId === 6);
      const ppeOrder = { ...order, approver: admin, approverId: admin?.id };

      submitPpeOrder(ppeOrder)
        .then((response) => {
          if (response?.status === 200) {
            emptyCart();
            setCedCoinItemList(accounts[0]?.username);
            navigate('/confirmation', { state: { order: response?.data } });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Order submitting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    } else {
      submitOrder(order)
        .then((response) => {
          if (response?.status === 200) {
            emptyCart();
            setCedCoinItemList(accounts[0]?.username);
            navigate('/confirmation', { state: { order: response?.data } });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Order submitting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetch officeList, employeeList on component mount */
  useEffect(() => {
    setOfficeList();
    setEmployeeList();
  }, [setOfficeList, setEmployeeList]);

  /* Update order details when fetched */
  useEffect(() => {
    if (
      (items.length || customizedItems.length) &&
      cartCategory &&
      user &&
      employeeList.length
    ) {
      const updatedOrder: IOrder = {
        status: 'Requested' as IOrderStatus,
        office: '',
        shipName: '',
        addrLn1: '',
        addrLn2: '',
        city: '',
        state: '',
        zip: '',
        comments: '',
        reason: '',
        reasonDesc: '',
        forWhom: '',
        priority: false,
        priorityDate: undefined,
        items: items,
        customizedItems: customizedItems,
        categoryId: cartCategory.id,
        isPriceable: cartCategory.isPriceable,
        requester: user.displayName!,
        requesterEmail: user.userPrincipalName!,
        employeeNumber: employeeList.find(
          (e) => e.email.toLowerCase() === user.userPrincipalName?.toLowerCase()
        )?.employeeNumber,
        redeemAmount:
          cartCategory?.isPriceable && userCedCoinBalance > 0
            ? userCedCoinBalance > totalAmount
              ? totalAmount
              : userCedCoinBalance
            : undefined,
        subTotal: totalAmount,
        payrollDeduction: cartCategory?.isPriceable
          ? userCedCoinBalance >= totalAmount
            ? undefined
            : totalAmount - userCedCoinBalance
          : undefined,
      };

      setOrder(updatedOrder);
    }
  }, [
    items,
    customizedItems,
    cartCategory,
    user,
    employeeList,
    userCedCoinBalance,
    totalAmount,
  ]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* order type */}
      {adminUser.length > 0 && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            m={2}
          >
            <ListAltIcon />
            <Typography
              m={2}
              variant="subtitle2"
              flexGrow={1}
              sx={{ fontSize: { xs: 10, sm: 14 } }}
            >
              Order Type
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={order.status}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                orderType: IOrderStatus
              ) =>
                setOrder((prevOrder) => ({
                  ...prevOrder,
                  status: orderType,
                }))
              }
              aria-label="Platform"
              size="small"
            >
              <ToggleButton value="Requested">Standard Order</ToggleButton>
              <ToggleButton value="Picked up">Pickup</ToggleButton>
              <ToggleButton value="Restock">Restock</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Paper>
      )}
      {/* delivery method */}
      {order.status === 'Requested' && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-start"
            m={2}
          >
            <LocalShippingIcon />
            <Typography
              m={2}
              variant="subtitle2"
              flexGrow={1}
              sx={{ fontSize: { xs: 10, sm: 14 } }}
            >
              Delivered to:
            </Typography>
            <ToggleButtonGroup
              color="primary"
              value={location}
              exclusive
              onChange={(
                event: React.MouseEvent<HTMLElement>,
                location: string
              ) => setLocation(location)}
              aria-label="Platform"
              size="small"
            >
              <ToggleButton value="office">CED Office</ToggleButton>
              <ToggleButton value="other">Other Address</ToggleButton>
            </ToggleButtonGroup>
          </Box>
        </Paper>
      )}

      {/* office options */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        {order.status === 'Requested' && location === 'office' && (
          <FormControl
            sx={{ minWidth: 120, width: '100%' }}
            size="small"
            required
          >
            <InputLabel
              htmlFor="office-grouped-native-select"
              sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
            >
              Office
            </InputLabel>
            {officeListLoading ? (
              <Select id="office-grouped-native-select" label="Office">
                <Loading />
              </Select>
            ) : (
              <Select
                id="office-grouped-native-select"
                label="Office"
                native
                onChange={(event: SelectChangeEvent) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    office: event.target.value,
                  }))
                }
                value={order.office}
              >
                <option aria-label="None" value="" />
                {officeList &&
                  Object.keys(officeList).map((state) => (
                    <optgroup label={state} key={state}>
                      {officeList[state].map((city, index) => (
                        <option value={`${city}, ${state}`} key={index}>
                          {city}, {state}
                        </option>
                      ))}
                    </optgroup>
                  ))}
              </Select>
            )}
          </FormControl>
        )}
        {/* address form */}
        {order.status === 'Requested' && location === 'other' && (
          <Box m={2}>
            <Grid container spacing={3} mt={2}>
              <Grid item xs={12}>
                <CheckoutTextField
                  label="Shipping Receiver"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      shipName: event.target.value,
                    }))
                  }
                  m={0}
                  value={order.shipName}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CheckoutTextField
                  label="Address Line 1"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      addrLn1: event.target.value,
                    }))
                  }
                  value={order.addrLn1}
                  m={0}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CheckoutTextField
                  label="Address Line 2"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      addrLn2: event.target.value,
                    }))
                  }
                  value={order.addrLn2}
                  m={0}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CheckoutTextField
                  label="City"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      city: event.target.value,
                    }))
                  }
                  value={order.city}
                  m={0}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CheckoutTextField
                  label="State"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      state: event.target.value,
                    }))
                  }
                  value={order.state}
                  m={0}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CheckoutTextField
                  label="Zip Code"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setOrder((prevOrder) => ({
                      ...prevOrder,
                      zip: event.target.value,
                    }))
                  }
                  value={order.zip}
                  m={0}
                  required
                />
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Box>
        )}
      </Paper>
      {/* reason options */}
      {order.status !== 'Restock' &&
        order.categoryId &&
        order.categoryId !== 6 && (
          <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
            <FormControl
              sx={{ minWidth: 120, width: '100%' }}
              size="small"
              required
            >
              <InputLabel
                htmlFor="reason-grouped-native-select"
                sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
              >
                Reason
              </InputLabel>
              <Select
                id="reason-grouped-native-select"
                label="Reason"
                native
                onChange={(event: SelectChangeEvent) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    reason: event.target.value,
                  }))
                }
                value={order.reason}
              >
                <option aria-label="None" value="" />
                {reasons?.map((reason) => (
                  <option value={reason.value} key={reason.key}>
                    {reason.value}
                  </option>
                ))}
              </Select>
            </FormControl>
          </Paper>
        )}
      {order.status !== 'Restock' &&
        order.categoryId !== 6 &&
        order.reason === 'Other' && (
          <CheckoutTextField
            label="Other Reason Description"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setOrder((prevOrder) => ({
                ...prevOrder,
                reasonDesc: event.target.value,
              }))
            }
            value={order.reasonDesc}
            m={2}
          />
        )}
      {order.status !== 'Restock' &&
        (order.categoryId === 6 ? (
          <Paper sx={{ m: 2 }}>
            <Autocomplete
              loading={employeeListLoading}
              autoHighlight
              value={requestedEmployee}
              onChange={(event, selectedEmployee: IEmployee | null) => {
                setOrder((prevOrder) => {
                  if (selectedEmployee) {
                    return {
                      ...prevOrder,
                      requester: selectedEmployee.displayName
                        ? selectedEmployee.displayName
                        : `${selectedEmployee.firstName} ${selectedEmployee.lastName}`,
                      requesterEmail: selectedEmployee.email,
                    };
                  }
                  return prevOrder;
                });
                setRequestedEmployee(selectedEmployee);
              }}
              id="controllable-states-demo"
              options={employeeList}
              getOptionLabel={(option: IEmployee) =>
                option.displayName
                  ? option.displayName
                  : `${option.firstName} ${option.lastName}`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Employee"
                  sx={{
                    '& .MuiFormLabel-asterisk': {
                      color: 'warning.main',
                    },
                    bgcolor: 'secondary.dark',
                  }}
                  size="small"
                  required
                />
              )}
              renderOption={(props, option) => (
                <Box
                  component="li"
                  width="100%"
                  sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                  {...props}
                  key={option.aiod}
                >
                  <Tooltip title={option.email} placement="bottom">
                    <Typography>
                      {option.displayName
                        ? option.displayName
                        : `${option.firstName} ${option.lastName}`}
                    </Typography>
                  </Tooltip>
                </Box>
              )}
            />
          </Paper>
        ) : (
          <CheckoutTextField
            label="For Whom"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setOrder((prevOrder) => ({
                ...prevOrder,
                forWhom: event.target.value,
              }))
            }
            value={order.forWhom}
            m={2}
            required
          />
        ))}
      <CheckoutTextField
        label="Comments"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          setOrder((prevOrder) => ({
            ...prevOrder,
            comments: event.target.value,
          }))
        }
        value={order.comments}
        m={2}
        required
      />
      {/* priority order */}
      {order.status === 'Requested' && (
        <Paper
          sx={{
            m: 2,
            bgcolor: 'secondary.dark',
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                name="priorityOrder"
                checked={order.priority}
                size="small"
                sx={{ color: 'grey' }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setOrder((prevOrder) => ({
                    ...prevOrder,
                    priority: event.target.checked,
                  }))
                }
              />
            }
            label="Priority Order"
            sx={{ ml: 1, color: 'grey' }}
          />
        </Paper>
      )}
      {order.status === 'Requested' && order.priority && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="Priority Date"
              onChange={(newValue: Dayjs | null) =>
                setOrder((prevOrder) => ({
                  ...prevOrder,
                  priorityDate: newValue ? newValue.toDate() : undefined,
                }))
              }
              value={order.priorityDate ? dayjs(order.priorityDate) : null}
              disablePast
              sx={{ width: '100%' }}
            />
          </LocalizationProvider>
        </Paper>
      )}

      {/*paycheck disclaimer */}
      {order.status === 'Requested' &&
        cartCategory?.isRedeemable &&
        totalAmount > userCedCoinBalance && (
          <Paper
            sx={{
              m: 2,
              bgcolor: 'secondary.dark',
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="disclaimer"
                  checked={disclaimerChecked}
                  required
                  size="small"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setDisclaimerChecked(event.target.checked)
                  }
                />
              }
              label="Disclaimer"
              sx={{
                ml: 1,
                color: 'grey',
                '& .MuiFormControlLabel-asterisk': { color: 'warning.main' },
              }}
            />
            <FormHelperText
              sx={{
                ml: 3,
                paddingBottom: 1,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              I authorize CED to deduct the below amount from payroll:
            </FormHelperText>
            <FormHelperText
              sx={{
                ml: 3,
                paddingBottom: 1,
                fontSize: 16,
                fontWeight: 'bold',
              }}
            >
              $
              {userCedCoinBalance > 0
                ? userCedCoinBalance > totalAmount
                  ? 0
                  : (totalAmount - userCedCoinBalance).toFixed(2)
                : totalAmount.toFixed(2)}
            </FormHelperText>
          </Paper>
        )}

      {/* submit button */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        mt={10}
        ml={2}
        mr={2}
      >
        <LoadingButton
          variant="contained"
          type="submit"
          loading={submitLoading}
        >
          Submit Order
        </LoadingButton>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default CheckoutForm;
