import { Configuration } from '@azure/msal-browser';

const httpsroot = window.location.origin.replace(
  window.location.protocol,
  'https:'
);

export const msalConfig: Configuration = {
  auth: {
    authority: 'https://login.microsoftonline.com/common',
    clientId: '3dfae65b-8ca7-4468-9cd6-da415e27c1eb',
    postLogoutRedirectUri:
      process.env.NODE_ENV === 'development'
        ? window.location.origin
        : httpsroot,
    redirectUri:
      process.env.NODE_ENV === 'development'
        ? window.location.origin
        : httpsroot,
  },
  cache: {
    cacheLocation: 'sessionStorage',

    secureCookies: false,
  },
  system: {},
};

export const loginRequest = {
  scopes: ['user.read'],
};

export const graphConfig = {
  graphMePhotoEndpoint: 'https://graph.microsoft.com/v1.0/me/photo/$value',
  graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
  graphUsersPhotoEndpoint: 'https://graph.microsoft.com/v1.0',
};

export const apiRequest = {
  scopes: ['api://3dfae65b-8ca7-4468-9cd6-da415e27c1eb/Inventory.Read'],
};
