import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  Chip,
  Fab,
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { no_image_png } from 'app/common/options/noImage';
import { firstImageSrc } from 'app/common/util/util';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useItemStore } from 'app/stores/itemStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';

const ItemDashboard = () => {
  /* State and functions from custom hooks */
  const { catId }: any = useParams();
  const { category, categoryLoading, setCategory } = useCategoryStore();
  const { itemList, itemListLoading, setItemList } = useItemStore();
  const { adminUser, user, locations, adminUserLoading } = useUserStore();
  const stationaryPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 1)
  );

  const marketingStandardStockPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 2)
  );
  const navigate = useNavigate();

  /* Fetch category, itemlist on component mount */
  useEffect(() => {
    if (catId) {
      setCategory(catId);
      setItemList(catId);
    }
  }, [catId, setCategory, setItemList]);

  /* Render loading state while category or itemList are loading */
  /* Render not found state if category or itemList are not available, or only available for admin, or only available for ced admins*/
  if (catId === '1' && !stationaryPermission) {
    return (
      <NotFound error="You have not been granted permission for this page" />
    );
  }

  if (catId === '2' && !marketingStandardStockPermission) {
    return (
      <NotFound error="You have not been granted permission for this page" />
    );
  }

  if (categoryLoading || itemListLoading || adminUserLoading) {
    return <Loading />;
  } else if (
    !category ||
    itemList?.length === 0 ||
    (category.adminOnly && adminUser.length === 0)
  ) {
    return <NotFound />;
  }

  return (
    <Box>
      {/* toolbar */}
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          borderTop: '1px solid black',
          borderBottom: '1px solid black',
        }}
      >
        {/* category header */}
        <Typography>{category?.name}</Typography>
      </Toolbar>
      {/* new starter pack banner */}
      {catId === '2' && (
        <Box
          sx={{
            background: "url('/assets/banner_bg.jpg')",
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            flex: 1,
            padding: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '300px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: { xs: 'left', sm: 'center' },
                justifyContent: 'center',
                flexDirection: { xs: 'column', sm: 'row' },
              }}
            >
              <Typography
                color={'white'}
                sx={{
                  fontWeight: 'bold',
                  fontSize: { xs: 24, sm: 36, lg: 56 },
                  p: 1,
                  backgroundColor: 'primary.main',
                }}
              >
                MARKETING OFFICE STARTER PACK
              </Typography>
            </Box>

            <Box sx={{ mt: 2 }}>
              <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => navigate(`/marketing-starter-pack`)}
                sx={{ mr: 2 }}
              >
                Order Now
              </Button>
            </Box>
          </Box>
        </Box>
      )}
      {/* category list menu */}
      <Grid container spacing={2} p={2}>
        {/* item grid list */}
        <Grid item xs={12} sm={12} md={12}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 2, sm: 4, md: 6, lg: 8, xl: 10 }}
          >
            {itemList
              ?.filter((i) => category?.isPriceable === i.hasPrice)
              .map((item) => (
                <Grid item xs={2} key={item.id}>
                  <Card sx={{ maxWidth: 480 }}>
                    <CardHeader
                      title={
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                          }}
                        >
                          {item.name}
                        </Box>
                      }
                      sx={{ height: 40, bgcolor: 'secondary.dark' }}
                      titleTypographyProps={{
                        variant: 'caption',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        fontWeight: '500',
                      }}
                    />
                    {item.isOrderable ? (
                      <Link to={`/inventory/${item.categoryId}/${item.id}`}>
                        <CardMedia
                          component="img"
                          height="300"
                          loading="lazy"
                          src={firstImageSrc(item.images)}
                          onError={(e) => {
                            e.currentTarget.src = no_image_png;
                          }}
                          alt={item.name}
                          sx={{
                            cursor: 'pointer',
                            '&:hover': {
                              opacity: 0.8,
                            },
                            objectFit: 'contain',
                            objectPosition: 'center',
                            width: '100%',
                          }}
                        />
                      </Link>
                    ) : item.id === 2433 ? (
                      <Box sx={{ position: 'relative' }}>
                        <Link
                          target="_blank"
                          to={`https://collierseng.sharepoint.com/sites/HealthAndSafetyFiles/Shared%20Documents/Forms/AllItems.aspx?id=%2Fsites%2FHealthAndSafetyFiles%2FShared%20Documents%2FH%26S%20Equipment%2FShoe%20%26%20Boot%20Express%20Info%2Epdf&viewid=ec580123%2Db838%2D4ab7%2Db5c8%2D40d30a0d35ae&parent=%2Fsites%2FHealthAndSafetyFiles%2FShared%20Documents%2FH%26S%20Equipment&OR=Teams-HL&CT=1715962422910&clickparams=eyJBcHBOYW1lIjoiVGVhbXMtRGVza3RvcCIsIkFwcFZlcnNpb24iOiI0OS8yNDA0MTEyMjMxNSIsIkhhc0ZlZGVyYXRlZFVzZXIiOmZhbHNlfQ%3D%3D`}
                        >
                          <CardMedia
                            component="img"
                            height="194"
                            loading="lazy"
                            src={firstImageSrc(item.images)}
                            onError={(e) => {
                              e.currentTarget.src = no_image_png;
                            }}
                            alt={item.name}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                opacity: 0.8,
                              },
                              objectFit: 'contain',
                              objectPosition: 'center',
                              width: '100%',
                            }}
                          />
                        </Link>
                      </Box>
                    ) : (
                      <Box sx={{ position: 'relative' }}>
                        <Link to={`/inventory/${item.categoryId}/${item.id}`}>
                          <CardMedia
                            component="img"
                            height="300"
                            loading="lazy"
                            src={firstImageSrc(item.images)}
                            onError={(e) => {
                              e.currentTarget.src = no_image_png;
                            }}
                            alt={item.name}
                            sx={{
                              cursor: 'pointer',
                              '&:hover': {
                                opacity: 0.8,
                              },
                              objectFit: 'contain',
                              objectPosition: 'center',
                              width: '100%',
                            }}
                          />

                          <Box
                            sx={{
                              position: 'absolute',
                              bottom: '50%',
                              left: 0,
                              width: '100%',
                              bgcolor: 'rgba(0, 0, 0, 0.54)',
                              color: 'white',
                              padding: '10px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                            }}
                          >
                            <Typography sx={{ fontSize: { xs: 12, md: 24 } }}>
                              Coming Soon
                            </Typography>
                          </Box>
                        </Link>
                      </Box>
                    )}

                    {category?.isPriceable && item.price !== null && (
                      <CardContent>
                        <Box display="flex" justifyContent="space-between">
                          <Typography variant="caption" component="div">
                            Price:
                          </Typography>
                          <Typography variant="caption" component="div">
                            ${item.price.toFixed(2)}
                          </Typography>
                        </Box>
                      </CardContent>
                    )}

                    {/* {item.tags?.length && (
                      <CardActions sx={{ bgcolor: 'secondary.dark' }}>
                        {item.tags?.map((t, index) => (
                          <Chip
                            key={index}
                            label={t}
                            color="info"
                            variant="outlined"
                            size="small"
                            sx={{ m: 1 }}
                          />
                        ))}
                      </CardActions>
                    )} */}
                    <CardActions sx={{ bgcolor: 'secondary.dark', height: 40 }}>
                      {item.tags?.map((t, index) => (
                        <Chip
                          key={index}
                          label={t}
                          color="info"
                          variant="filled"
                          size="small"
                          sx={{ m: 1 }}
                        />
                      ))}
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            {category?.id === 6 && (
              <Grid item xs={2}>
                <Box
                  sx={{
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <Fab
                    variant="extended"
                    color="primary"
                    component={Link}
                    to={`/inventory/6/custom`}
                  >
                    <DashboardCustomizeIcon sx={{ mr: 1 }} />
                    Add Customized Items
                  </Fab>
                </Box>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      {/* Footer spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default ItemDashboard;
