import { useMsal } from '@azure/msal-react';
import AddIcon from '@mui/icons-material/Add';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import { LoadingButton } from '@mui/lab';
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Skeleton,
} from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import UseGetQuery from 'app/api/useGetQuery';
import CustomToolbar from 'app/common/modals/CustomToolbar';
import MessageContainer from 'app/common/modals/MessageContainer';
import { stringAvatar } from 'app/common/util/util';
import { useCedCoinStore } from 'app/stores/cedCoinStore';
import { useModalStore } from 'app/stores/modalStore';
import React, { useEffect, useState } from 'react';
import AddCedCoins from './AddCedCoins';

const CedCoin = () => {
  /* State and functions from custom hooks */
  const {
    addCedCoinOpen,
    setAddCedCoinOpen,
    deleteCedCoinOpen,
    setDeleteCedCoinOpen,
    deleteCedCoinSuccessOpen,
    setDeleteCedCoinSuccessOpen,
  } = useModalStore();
  const {
    totalCedCoinItemListLoading,
    accumulatedCoinItemList,
    setTotalCedCoinItemList,
    deleteCedCoin,
  } = useCedCoinStore();
  const { accounts } = useMsal();

  const [selectedEmployee, setSelectedEmployee] = useState('');
  const [selectedEmployeeEmail, setSelectedEmployeeEmail] = useState('');
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id#', width: 70 },
    {
      field: 'email',
      headerName: 'Avatar',
      width: 80,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let response = UseGetQuery(params?.value);
        if (response.loading)
          return <Skeleton variant="rectangular" width={60} height={10} />;
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Avatar
              sx={{
                bgcolor: 'white',
                color: 'primary.main',
                width: 30,
                height: 30,
                mr: 1,
              }}
              src={response.data as string}
              {...stringAvatar(params.row.email)}
            />
          </Box>
        );
      },
    },
    { field: 'name', headerName: 'Name', width: 150 },
    { field: 'office', headerName: 'Office', width: 120 },

    { field: 'discipline', headerName: 'Discipline', width: 150 },
    { field: 'employeeNumber', headerName: 'Employee#', width: 150 },
    {
      field: 'issuedTime',
      headerName: 'Last Issued Date',
      width: 160,
      valueFormatter: (value) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'expiredTime',
      headerName: 'Expired Date',
      width: 120,
      valueFormatter: (value) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'issuer',
      headerName: 'Last Issuer',
      width: 120,
    },
    {
      field: 'issuedAmount',
      headerName: 'Total Issued Amount',
      flex: 1,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>${params?.value}</>
      ),
    },
    {
      field: 'balance',
      headerName: 'Balance',
      flex: 1,
      width: 100,
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <>${params?.value}</>
      ),
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => {
            setDeleteCedCoinOpen(true);
            setSelectedEmployee(params.row.name);
            setSelectedEmployeeEmail(params.row.email);
          }}
        >
          Delete
        </Button>
      ),
    },
  ];

  const handleDelete = (event: React.FormEvent) => {
    event.preventDefault();
    setDeleteLoading(true);
    const deletedBy = accounts[0].username;

    if (selectedEmployee !== '' && selectedEmployeeEmail !== '' && deletedBy) {
      deleteCedCoin(selectedEmployeeEmail, deletedBy)
        .then((response) => {
          if (response?.status === 200) {
            setDeleteCedCoinSuccessOpen(true);
            setTotalCedCoinItemList();
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `CedCoin deletion is not completed.`,
            });
          }
          setDeleteLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetching total CedCoin items on component mount */
  useEffect(() => {
    setTotalCedCoinItemList();
  }, [setTotalCedCoinItemList]);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<CurrencyExchangeIcon />} message="CedCoin" />
      </Box>
      {/* CedCoin Table */}
      <Box m={2}>
        <Box sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={accumulatedCoinItemList}
            columns={columns}
            checkboxSelection
            loading={totalCedCoinItemListLoading}
            disableRowSelectionOnClick={true}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
            }}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  icon={<AddIcon />}
                  label="Add CedCoins"
                  onClick={() => setAddCedCoinOpen(true)}
                />
              ),
            }}
          />
        </Box>
      </Box>
      {/* Add cedCoin popup */}
      <Box>
        <Dialog
          open={addCedCoinOpen}
          onClose={() => setAddCedCoinOpen(false)}
          scroll="body"
          fullWidth
          maxWidth="lg"
        >
          <DialogContent>
            <AddCedCoins />
          </DialogContent>
        </Dialog>
      </Box>
      {/* Delete cedCoin popup */}
      <Box>
        <Dialog
          open={deleteCedCoinOpen}
          onClose={() => setDeleteCedCoinOpen(false)}
          scroll="body"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <DialogContentText>
              The credits for {selectedEmployee} will be deleted. Please verify
              this employee's orders first.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setDeleteCedCoinOpen(false)}>
              No
            </Button>
            <LoadingButton
              autoFocus
              loading={deleteLoading}
              onClick={(event) => {
                setDeleteCedCoinOpen(false);
                handleDelete(event);
              }}
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </Box>
      {/* Delete cedCoin successfully popup */}
      <Box>
        <Dialog
          open={deleteCedCoinSuccessOpen}
          onClose={() => setDeleteCedCoinSuccessOpen(false)}
          scroll="body"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <DialogContentText>The coins has been delete.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => setDeleteCedCoinSuccessOpen(false)}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>

      {/* Footer gap filler */}
      <Box height={128}></Box>
    </Box>
  );
};

export default CedCoin;
function setSnackBar(arg0: { open: boolean; type: string; content: string }) {
  throw new Error('Function not implemented.');
}
