import CategoryIcon from '@mui/icons-material/Category';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import GradingIcon from '@mui/icons-material/Grading';
import MenuIcon from '@mui/icons-material/Menu';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import { Box, Divider, IconButton, List, SwipeableDrawer } from '@mui/material';
import Loading from 'app/common/modals/Loading';
import Logo from 'app/common/modals/Logo';
import MenuListItem from 'app/common/modals/MenuListItem';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';

const DrawerMenu = () => {
  /* State and functions from custom hooks */
  const { categoryList, categoryListLoading, setCategoryList } =
    useCategoryStore();
  const { adminUser } = useUserStore();

  /* Define component state variables */
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  /* Function to toggle drawer */
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event &&
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  /* Fetch category on component mount */
  useEffect(() => {
    setCategoryList();
  }, [setCategoryList]);

  return (
    <Box>
      {/* drawer button */}
      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={toggleDrawer(true)}
        sx={{ mr: 1, display: { lg: 'none' } }}
        color="primary"
      >
        <MenuIcon />
      </IconButton>
      {/* drawer menu */}
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
      >
        <Box>
          {categoryListLoading ? (
            <Loading />
          ) : (
            <Box
              sx={{ width: 350 }}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                <MenuListItem icon={<Logo />} />
              </List>
              <Divider />
              <List>
                <MenuListItem icon={<CategoryIcon />} text="Products" />
                {categoryList
                  ?.filter((c) => !c.adminOnly || adminUser.length > 0)
                  .map((cat) => (
                    <MenuListItem
                      key={cat.id}
                      icon="-"
                      text={cat.name}
                      isSubItem
                      path={`/inventory/${cat.id}`}
                    />
                  ))}
                <MenuListItem icon={<GradingIcon />} text="Requests" />
                <MenuListItem
                  icon="-"
                  text="Health & Safety PPE Request Form"
                  isSubItem
                  path={`/ppe-request`}
                />
                <MenuListItem
                  icon={<QuestionAnswerIcon />}
                  text="FAQ"
                  path="/faq"
                />

                <MenuListItem
                  icon={<ContactMailIcon />}
                  text="Contact"
                  path="/contact"
                />
              </List>
            </Box>
          )}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
};

export default DrawerMenu;
