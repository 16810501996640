import Agent from 'app/api/agent';
import { IHomeDepartment, IPpeManager, IRequest } from 'app/models/request';
import { AxiosResponse } from 'axios';
import { create } from 'zustand';

interface IRequestStore {
  /* submit request list */
  submitRequest: (request: IRequest) => Promise<void | AxiosResponse>;

  /* load managers */
  ppeManagers: IPpeManager[];
  ppeManagersLoading: boolean;
  setPpeManagers: () => void;

  /* load requests */
  requestList: IRequest[];
  requestListLoading: boolean;
  setRequestList: () => void;

  /* load reviewed requests */
  reviewedRequestList: IRequest[];
  reviewedRequestListLoading: boolean;
  setReviewedRequestList: () => void;

  /* load one request by id*/
  requestForReview?: IRequest;
  requestForReviewLoading: boolean;
  setRequestForReview: (id: number) => void;

  /* handle request */
  handleRequest: (requst: IRequest) => Promise<void | AxiosResponse>;

  /* load requests by email */
  requestsByEmail: IRequest[];
  requestsByEmailLoading: boolean;
  setRequestsByEmail: (email: string) => void;

  /* load HomeDepartments */
  homeDepartmentList: IHomeDepartment[];
  homeDepartmentListLoading: boolean;
  setHomeDepartmentList: () => void;

  /* ship request order */
  shipRequestOrder: (requst: IRequest) => Promise<void | AxiosResponse>;
}

export const useRequestStore = create<IRequestStore>((set, get) => ({
  /* submit request list */
  submitRequest: async (request) => {
    return Agent.Request.createRequest(request)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* load managers */
  ppeManagers: [],
  ppeManagersLoading: false,
  setPpeManagers: () => {
    set({ ppeManagersLoading: true });
    Agent.Request.loadManagers()
      .then((response) => {
        set({
          ppeManagers: response,
          ppeManagersLoading: false,
        });
      })
      .catch(() => set({ ppeManagersLoading: false }));
  },

  /* load requests */
  requestList: [],
  requestListLoading: false,
  setRequestList: () => {
    set({ requestListLoading: true });
    Agent.Request.loadRequests()
      .then((response) => {
        set({
          requestList: response,
          requestListLoading: false,
        });
      })
      .catch(() => set({ requestListLoading: false }));
  },

  /* load reviewed requests */
  reviewedRequestList: [],
  reviewedRequestListLoading: false,
  setReviewedRequestList: () => {
    set({ reviewedRequestListLoading: true });
    Agent.Request.loadReviewedRequests()
      .then((response) => {
        set({
          reviewedRequestList: response,
          reviewedRequestListLoading: false,
        });
      })
      .catch(() => set({ reviewedRequestListLoading: false }));
  },

  /* load one request by id*/
  requestForReviewLoading: false,
  setRequestForReview: (id) => {
    set({ requestForReviewLoading: true });
    Agent.Request.loadRequestById(id)
      .then((response) => {
        set({
          requestForReview: response,
          requestForReviewLoading: false,
        });
      })
      .catch((error) => set({ requestForReviewLoading: false }));
  },

  /* handle request */
  handleRequest: async (request) => {
    return Agent.Request.handleRequest(request)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* ship request order*/
  shipRequestOrder: async (request) => {
    return Agent.Request.shipRequestOrder(request)
      .then((response) => response)
      .catch((error) => console.log(error));
  },

  /* load requests by email */
  requestsByEmail: [],
  requestsByEmailLoading: false,
  setRequestsByEmail: (email) => {
    set({ requestsByEmailLoading: true });
    Agent.Request.loadRequestsByEmail(email)
      .then((response) => {
        set({
          requestsByEmail: response,
          requestsByEmailLoading: false,
        });
      })
      .catch(() => set({ requestsByEmailLoading: false }));
  },

  /* load HomeDepartments */
  homeDepartmentList: [],
  homeDepartmentListLoading: false,
  setHomeDepartmentList: () => {
    set({ homeDepartmentListLoading: true });
    Agent.Request.loadHomeDepartments()
      .then((response) => {
        set({
          homeDepartmentList: response,
          homeDepartmentListLoading: false,
        });
      })
      .catch(() => set({ homeDepartmentListLoading: false }));
  },
}));
