import ReorderIcon from '@mui/icons-material/Reorder';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  Avatar,
  Box,
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
} from '@mui/material';
import CategorySelection from 'app/common/modals/CategorySelection';
import Loading from 'app/common/modals/Loading';
import MessageContainer from 'app/common/modals/MessageContainer';
import { IItem } from 'app/models/item';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Draggable,
  DropResult,
  Droppable,
} from 'react-beautiful-dnd';

const ProductReorder = () => {
  /* State and functions from custom hooks */
  const {
    itemList,
    itemListLoading,
    setItemList,
    reSetItemList,
    reOrderItemList,
  } = useItemStore();
  const { adminUser } = useUserStore();
  const { setSnackBar } = useModalStore();
  const { categoryList } = useCategoryStore();
  const [selectedCategory, setSelectedCategory] = useState(
    adminUser[0].categoryId
  );

  /* Function to reorder items */
  const reorder = (
    list: IItem[],
    startIndex: number,
    endIndex: number
  ): IItem[] => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  /* Handle drag end event */
  const onDragEnd = (result: DropResult): void => {
    if (!result.destination) return;

    const items: IItem[] = reorder(
      itemList,
      result.source.index,
      result.destination.index
    );

    reSetItemList(items);
  };

  /* Handle form submission */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    if (itemList) {
      reOrderItemList(selectedCategory, itemList)
        .then((response) => {
          if (response?.status === 200) {
            setSnackBar({
              open: true,
              type: 'success',
              content: `Product re-ordering is completed successfully.`,
            });
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Product re-ordering is not completed.`,
            });
          }
        })
        .catch((error) => console.log(error));
    }
  };

  /* Load category items on component mount */
  useEffect(() => {
    if (adminUser.length) setItemList(adminUser[0].categoryId);
  }, [setItemList, adminUser]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* Header */}
      <Box m={2}>
        <MessageContainer
          icon={<ViewListIcon />}
          message="Product List Reorder"
        />
      </Box>
      {/* Category selection */}
      <Box m={2}>
        <CategorySelection
          value={selectedCategory}
          options={categoryList}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setSelectedCategory(
              Number((event.target as HTMLInputElement).value)
            );
            setItemList(Number((event.target as HTMLInputElement).value));
          }}
        />
      </Box>
      {/* Item list with drag and drop */}
      <Box sx={{ m: 2 }}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided): JSX.Element => (
              <List
                {...provided.droppableProps}
                ref={provided.innerRef}
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                }}
              >
                {itemListLoading ? (
                  <Loading />
                ) : (
                  itemList.map((item, index) => (
                    <Draggable
                      key={item.id}
                      draggableId={String(item.id)}
                      index={index}
                    >
                      {(provided): JSX.Element => (
                        <ListItem
                          secondaryAction={<ReorderIcon />}
                          disablePadding
                          component={Paper}
                          elevation={3}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <ListItemButton>
                            <ListItemAvatar>
                              <Avatar
                                alt={item.name}
                                src={
                                  process.env.REACT_APP_ATTACHMENTS_URL +
                                  '/' +
                                  item?.images?.filter((i) => i !== '')[0]
                                }
                              />
                            </ListItemAvatar>
                            <ListItemText id={item.name} primary={item.name} />
                          </ListItemButton>
                        </ListItem>
                      )}
                    </Draggable>
                  ))
                )}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Box>
      {/* submit button */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        mt={10}
        ml={2}
        mr={2}
      >
        <Button variant="contained" type="submit">
          Submit
        </Button>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default ProductReorder;
