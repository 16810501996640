import CopyrightIcon from '@mui/icons-material/Copyright';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Toolbar,
  Typography,
} from '@mui/material';
import Logo from 'app/common/modals/Logo';
import { useModalStore } from 'app/stores/modalStore';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  const { aboutOpen, setAboutOpen } = useModalStore();
  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'primary.main',
        position: 'absolute',
        bottom: 0,
      }}
    >
      <Toolbar
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          color: 'white',
        }}
      >
        <Typography variant="subtitle2">CED</Typography>
        <Box justifyContent="space-between" display="flex" alignItems="center">
          <Button
            variant="text"
            sx={{ color: 'white' }}
            component={Link}
            to="/contact"
          >
            Contact
          </Button>
          <Button
            variant="text"
            sx={{ color: 'white' }}
            onClick={() => setAboutOpen(true)}
          >
            About
          </Button>
          {/* about popup */}
          <Dialog
            open={aboutOpen}
            onClose={() => setAboutOpen(false)}
            scroll="body"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle
              id="responsive-dialog-title"
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Logo />
              Version 1.83
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText>
                Updated on September 10, 2024
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                variant="contained"
                onClick={() => setAboutOpen(false)}
              >
                OK
              </Button>
            </DialogActions>
          </Dialog>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              color: 'white',
            }}
          >
            <CopyrightIcon sx={{ fontSize: '0.875rem' }} />
            <Typography variant="subtitle2">
              {new Date().getFullYear()}
            </Typography>
          </Box>
        </Box>
      </Toolbar>
    </Box>
  );
};

export default Footer;
