import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import DialpadIcon from '@mui/icons-material/Dialpad';
import { Box, Link, List, ListSubheader, Paper } from '@mui/material';
import MenuListItem from 'app/common/modals/MenuListItem';
import MessageContainer from 'app/common/modals/MessageContainer';
import React from 'react';

const Contact = () => {
  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer
          icon={<ConnectWithoutContactIcon />}
          message="Contact Us"
        />
      </Box>
      {/* Field Apparel section */}
      <List
        component={Paper}
        elevation={3}
        sx={{ m: 2 }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Field Apparel
          </ListSubheader>
        }
      >
        <MenuListItem
          text="Field Apparel"
          action={
            <Link
              href="mailto:fieldapparel@collierseng.com"
              sx={{ fontSize: { xs: 8, sm: 16 } }}
            >
              fieldapparel@collierseng.com
            </Link>
          }
          icon={<DialpadIcon />}
        />
      </List>
      {/* Client, Events & Office Promo Material section */}
      <List
        component={Paper}
        elevation={3}
        sx={{ m: 2 }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Client, Events & Office Promo Material
          </ListSubheader>
        }
      >
        <MenuListItem
          text="Carol Rech"
          action={
            <Link
              href="mailto:carol.rech@collierseng.com"
              sx={{ fontSize: { xs: 8, sm: 16 } }}
            >
              carol.rech@collierseng.com
            </Link>
          }
          icon={<DialpadIcon />}
        />
      </List>
      {/* TradeShow Supplies and Brochures section */}
      <List
        component={Paper}
        elevation={3}
        sx={{ m: 2 }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Tradeshow Supplies and Brochures
          </ListSubheader>
        }
      >
        <MenuListItem
          text="Carol Rech"
          action={
            <Link
              href="mailto:carol.rech@collierseng.com"
              sx={{ fontSize: { xs: 8, sm: 16 } }}
            >
              carol.rech@collierseng.com
            </Link>
          }
          icon={<DialpadIcon />}
        />
      </List>
      {/* Development section */}
      <List
        component={Paper}
        elevation={3}
        sx={{ m: 2 }}
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Development
          </ListSubheader>
        }
      >
        <MenuListItem
          text="Development"
          action={
            <Link
              href="mailto:developmentDept@collierseng.com"
              sx={{ fontSize: { xs: 8, sm: 16 } }}
            >
              developmentDept@collierseng.com
            </Link>
          }
          icon={<DialpadIcon />}
        />
      </List>
      {/* Footer spacer*/}
      <Box height={128}></Box>
    </Box>
  );
};

export default Contact;
