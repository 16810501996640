import {
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

interface MenuListItemProps {
  icon?: JSX.Element | string;
  text?: string;
  isSubItem?: boolean;
  path?: string;
  subText?: string;
  action?: JSX.Element | string;
}

const MenuListItem = ({
  icon,
  text,
  isSubItem,
  path,
  subText,
  action,
}: MenuListItemProps) => {
  return (
    <ListItem sx={{ pl: isSubItem ? 1 : 0 }} secondaryAction={action}>
      <ListItemButton component={path ? Link : 'div'} to={path}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText
          primary={
            <Typography variant={isSubItem ? 'body1' : 'subtitle2'}>
              {text}
            </Typography>
          }
        />

        <Typography>{subText}</Typography>
      </ListItemButton>
    </ListItem>
  );
};

export default MenuListItem;
