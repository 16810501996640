import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';
import InfoIcon from '@mui/icons-material/Info';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { IOrderStatus } from 'app/models/order';
import { IRequestStatus } from 'app/models/request';
import React from 'react';

interface StatusProps {
  status?: IOrderStatus | IRequestStatus;
}

const StatusIcon = ({ status }: StatusProps) => {
  if (status === 'Rejected') {
    return <ReportProblemIcon />;
  } else if (status === 'Approved' || status === 'Reviewed') {
    return <DoneIcon />;
  } else if (status === 'Requested') {
    return <AutorenewIcon />;
  } else {
    return <InfoIcon />;
  }
};

export default StatusIcon;
