import AddIcon from '@mui/icons-material/Add';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Paper,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import MessageContainer from 'app/common/modals/MessageContainer';
import NotFound from 'app/common/modals/NotFound';
import { no_image_png } from 'app/common/options/noImage';
import { IItem } from 'app/models/item';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import React, { useEffect, useState } from 'react';
import ImageUploading, { ImageListType } from 'react-images-uploading';
import { useNavigate, useParams } from 'react-router-dom';
import ProductListItem from './ProductListItem';

const ProductDetails = () => {
  const { productId, catId }: any = useParams();

  const {
    item,
    itemLoading,
    setItem,
    itemList,
    setItemList,
    totalItemList,
    setTotalItemList,
  } = useItemStore();

  const { editItem, deleteItem } = useItemStore();
  const { setSnackBar, editProductOpen, setEditProductOpen } = useModalStore();
  const navigate = useNavigate();

  const [updatedItem, setUpdatedItem] = useState<IItem>({} as IItem);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);

  const handleDelete = (event: React.FormEvent) => {
    event.preventDefault();
    setDeleteLoading(true);

    if (item) {
      deleteItem(item.id!)
        .then((response) => {
          if (response?.status === 200) {
            setEditProductOpen(true);
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Product deletion is not completed. Error: ${response?.status}`,
            });
          }
          setDeleteLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    if (updatedItem) {
      const payload = new FormData();
      payload.set('name', updatedItem.name);
      payload.set('desc', updatedItem.desc);
      payload.set('qty', String(updatedItem.qty));
      payload.set('hasSize', updatedItem.hasSize ? 'True' : 'False');
      payload.set('hasColor', updatedItem.hasColor ? 'True' : 'False');
      payload.set('hasPrice', updatedItem.hasPrice ? 'True' : 'False');
      payload.set('categoryId', String(updatedItem.categoryId));
      payload.set('id', String(updatedItem.id));
      payload.set(
        'isDiscontinued',
        updatedItem.isDiscontinued ? 'True' : 'False'
      );
      payload.set('isOrderable', updatedItem.isOrderable ? 'True' : 'False');
      payload.set('price', updatedItem.price ? String(updatedItem.price) : '');
      payload.set('size', updatedItem.size ?? '');
      payload.set('color', updatedItem.color ?? '');
      payload.set('groupId', String(updatedItem.groupId));
      payload.set('equipment', updatedItem.equipment ?? '');
      payload.set('type', updatedItem.type ?? '');

      updatedItem.tags?.forEach((t) => payload.append('tags', t));

      updatedItem.images?.forEach((i: any) => payload.append('images', i.file));

      updatedItem.imagesToRemove?.forEach((i) =>
        payload.append('imagesToRemove', i)
      );

      editItem(updatedItem.id!, payload)
        .then((response) => {
          if (response?.status === 200) {
            setEditProductOpen(true);
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Product changing is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* fetch item details, item list, and total item list on component mount */
  useEffect(() => {
    setItem(productId);
    setItemList(catId);
    setTotalItemList();
  }, [productId, catId, setItem, setItemList, setTotalItemList]);

  useEffect(() => {
    if (item) {
      setUpdatedItem(item);
    }
  }, [item]);

  if (itemLoading) {
    return <Loading />;
  } else if (!item) {
    return <NotFound />;
  }

  if (item.isDeleted)
    return (
      <Box>
        <Box m={2}>
          <MessageContainer
            icon={<DeleteForeverIcon />}
            message="This product has been deleted."
          />
        </Box>
        <Box
          m={2}
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <Button
            variant="contained"
            size="small"
            startIcon={<KeyboardReturnIcon />}
            onClick={() => navigate('/admin/product')}
          >
            Back to table
          </Button>
        </Box>
      </Box>
    );

  console.log(updatedItem);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Chip label="Edit Product" />
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => navigate('/admin/product')}
        >
          Back to table
        </Button>
      </Box>
      <Box component={Paper} elevation={3} sx={{ m: 2 }}>
        <List
          sx={{
            width: '100%',
            bgcolor: 'background.paper',
          }}
        >
          <ProductListItem
            listItemLabel="Image:"
            listItemContent={
              <ImageUploading
                multiple
                value={updatedItem.images?.filter((i) => i !== '') ?? []}
                onChange={(imageList: ImageListType) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    images: imageList,
                  }))
                }
              >
                {({ onImageUpload, onImageUpdate, onImageRemove }) => (
                  <Box display="flex">
                    {updatedItem.images
                      ?.filter((i) => i !== '')
                      .map((image, index) => (
                        <Badge
                          key={index}
                          badgeContent={
                            <Typography
                              onClick={() => {
                                onImageRemove(index);
                                setUpdatedItem((prevItem) => ({
                                  ...prevItem,
                                  imagesToRemove: [
                                    ...(prevItem.imagesToRemove || []),
                                    image,
                                  ],
                                }));
                              }}
                            >
                              X
                            </Typography>
                          }
                          color="primary"
                          sx={{ m: 1, cursor: 'pointer' }}
                        >
                          <Box
                            component="img"
                            src={
                              image.dataURL ??
                              process.env.REACT_APP_ATTACHMENTS_URL +
                                '/' +
                                image
                            }
                            height={100}
                            width={100}
                            onError={(e: any) => {
                              e.currentTarget.src = no_image_png;
                            }}
                            sx={{
                              cursor: 'pointer',
                              border: '1px solid gray',
                              objectFit: 'contain',
                              '&:hover': {
                                opacity: 0.8,
                              },
                            }}
                            onClick={() => {
                              onImageUpdate(index);
                              setUpdatedItem((prevItem) => ({
                                ...prevItem,
                                imagesToRemove: [
                                  ...(prevItem.imagesToRemove || []),
                                  image,
                                ],
                              }));
                            }}
                          />
                        </Badge>
                      ))}
                    <Box
                      onClick={onImageUpload}
                      sx={{
                        p: 2,
                        border: '1px dashed grey',
                        cursor: 'pointer',
                        width: 100,
                        height: 100,
                        m: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <AddIcon color="primary" />
                    </Box>
                  </Box>
                )}
              </ImageUploading>
            }
          />
          <ProductListItem
            listItemLabel="Name:"
            listItemContent={
              <TextField
                id="outlined-controlled-name"
                variant="standard"
                fullWidth
                size="small"
                required
                value={updatedItem.name}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    name: event.target.value,
                  }))
                }
              />
            }
          />
          <ProductListItem
            listItemLabel="Tags:"
            listItemContent={
              <Autocomplete
                size="small"
                multiple
                freeSolo
                value={updatedItem.tags ?? []}
                onChange={(event, newValue) => {
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    tags: newValue,
                  }));
                }}
                options={[]}
                renderInput={(params) => (
                  <TextField variant="standard" {...params} />
                )}
              />
            }
          />
          <ProductListItem
            listItemLabel="Description:"
            listItemContent={
              <TextField
                id="outlined-controlled-desc"
                variant="standard"
                fullWidth
                required
                multiline
                size="small"
                value={updatedItem.desc}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    desc: event.target.value,
                  }))
                }
              />
            }
          />
          <ProductListItem
            listItemLabel="Stock Quantity:"
            listItemContent={
              <TextField
                id="outlined-controlled-qty"
                variant="standard"
                fullWidth
                type="number"
                size="small"
                required
                value={updatedItem.qty}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    qty: Number(event.target.value),
                  }))
                }
              />
            }
          />
          <ProductListItem
            listItemLabel="Has Size:"
            listItemContent={
              <Switch
                checked={updatedItem.hasSize}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    hasSize: event.target.checked,
                    size: event.target.checked ? prevItem.size ?? '' : '',
                  }))
                }
              />
            }
          />
          {updatedItem.hasSize && (
            <ProductListItem
              listItemLabel="Size:"
              listItemContent={
                <TextField
                  id="outlined-controlled-size"
                  variant="standard"
                  fullWidth
                  size="small"
                  value={updatedItem.size}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUpdatedItem((prevItem) => ({
                      ...prevItem,
                      size: event.target.value,
                    }))
                  }
                />
              }
            />
          )}
          <ProductListItem
            listItemLabel="Has Color:"
            listItemContent={
              <Switch
                checked={updatedItem.hasColor}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    hasColor: event.target.checked,
                    color: event.target.checked ? prevItem.color ?? '' : '',
                  }))
                }
              />
            }
          />

          {updatedItem.hasColor && (
            <ProductListItem
              listItemLabel="Color:"
              listItemContent={
                <TextField
                  id="outlined-controlled-color"
                  variant="standard"
                  fullWidth
                  size="small"
                  value={updatedItem.color}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUpdatedItem((prevItem) => ({
                      ...prevItem,
                      color: event.target.value,
                    }))
                  }
                />
              }
            />
          )}

          <ProductListItem
            listItemLabel="Has Price:"
            listItemContent={
              <Switch
                checked={updatedItem.hasPrice}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    hasPrice: event.target.checked,
                    price: event.target.checked ? prevItem.price : null,
                  }))
                }
              />
            }
          />
          {updatedItem.hasPrice && (
            <ProductListItem
              listItemLabel="Price:"
              listItemContent={
                <TextField
                  id="outlined-controlled-price"
                  variant="standard"
                  fullWidth
                  type="number"
                  size="small"
                  value={updatedItem.price}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                    setUpdatedItem((prevItem) => ({
                      ...prevItem,
                      price: Number(event.target.value),
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    inputProps: { step: '0.01' },
                  }}
                />
              }
            />
          )}
          {/* {updatedItem.categoryId === 6 && (
            <ProductListItem
              listItemLabel="Equipment:"
              listItemContent={
                <FormControl size="small" variant="standard" fullWidth required>
                  <InputLabel
                    htmlFor="group-select"
                    sx={{
                      '& .MuiFormLabel-asterisk': { color: 'warning.main' },
                    }}
                  >
                    Equipment
                  </InputLabel>

                  <Select
                    id="group-select"
                    label="Equipment"
                    onChange={(event: SelectChangeEvent) =>
                      setUpdatedItem((prevItem) => ({
                        ...prevItem,
                        equipment: event.target.value,
                      }))
                    }
                    value={updatedItem.equipment ?? ''}
                  >
                    {equipmentList.map((e, index) => (
                      <MenuItem key={index} value={e}>
                        {e}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            />
          )}
          {updatedItem.categoryId === 6 && updatedItem.equipment && (
            <ProductListItem
              listItemLabel="Type:"
              listItemContent={
                <FormControl size="small" variant="standard" fullWidth required>
                  <InputLabel
                    htmlFor="group-select"
                    sx={{
                      '& .MuiFormLabel-asterisk': { color: 'warning.main' },
                    }}
                  >
                    Type
                  </InputLabel>
                  <Select
                    id="group-select"
                    label="Type"
                    onChange={(event: SelectChangeEvent) =>
                      setUpdatedItem((prevItem) => ({
                        ...prevItem,
                        type: event.target.value,
                      }))
                    }
                    value={updatedItem.type ?? ''}
                  >
                    {typeList?.map((t, index) => (
                      <MenuItem key={index} value={t}>
                        {t}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              }
            />
          )} */}

          <ProductListItem
            listItemLabel="Is Discontinued:"
            listItemContent={
              <Switch
                checked={updatedItem.isDiscontinued}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    isDiscontinued: event.target.checked,
                  }))
                }
              />
            }
          />
          <ProductListItem
            listItemLabel="Is Orderable:"
            listItemLabelHelpText="If it is set to unorderable, the product will be marked as coming soon "
            listItemContent={
              <Switch
                checked={updatedItem.isOrderable}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  setUpdatedItem((prevItem) => ({
                    ...prevItem,
                    isOrderable: event.target.checked,
                  }))
                }
              />
            }
          />
          <ProductListItem
            listItemLabel="Group:"
            listItemContent={
              <List>
                {itemList?.map((item) => (
                  <ListItem
                    key={item.id}
                    secondaryAction={
                      <Checkbox
                        edge="end"
                        checked={item.groupId === updatedItem.groupId}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setUpdatedItem((prevItem) => ({
                            ...prevItem,
                            groupId: item.groupId,
                          }))
                        }
                      />
                    }
                    disablePadding
                  >
                    <ListItemButton>
                      <AvatarGroup>
                        {item.hasColor ? (
                          totalItemList
                            ?.filter((i) => i.groupId === item.groupId)
                            .map((item) => (
                              <ListItemAvatar key={item.id}>
                                <Avatar
                                  alt={item.name}
                                  src={
                                    process.env.REACT_APP_ATTACHMENTS_URL +
                                    '/' +
                                    item?.images?.filter((i) => i !== '')[0]
                                  }
                                />
                              </ListItemAvatar>
                            ))
                        ) : (
                          <ListItemAvatar>
                            <Avatar
                              alt={item.name}
                              src={
                                process.env.REACT_APP_ATTACHMENTS_URL +
                                '/' +
                                item?.images?.filter((i) => i !== '')[0]
                              }
                            />
                          </ListItemAvatar>
                        )}
                      </AvatarGroup>

                      <ListItemText
                        id={item.name}
                        primary={item.name}
                        secondary={
                          item.hasSize
                            ? totalItemList
                                ?.filter((i) => i.groupId === item.groupId)
                                .map((i) => i.size)
                                .toString()
                            : 'One Size'
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            }
          />
        </List>
      </Box>
      <Box>
        <Dialog
          open={editProductOpen}
          onClose={() => setEditProductOpen(false)}
          scroll="body"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            <DialogContentText>Changes has been applied.</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={() => setEditProductOpen(false)}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mt={10}
        ml={2}
        mr={2}
      >
        <LoadingButton
          variant="outlined"
          color="warning"
          onClick={handleDelete}
          loading={deleteLoading}
        >
          Delete Product
        </LoadingButton>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={submitLoading}
        >
          Submit Changes
        </LoadingButton>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default ProductDetails;
