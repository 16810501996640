import { Avatar, Skeleton } from '@mui/material';
import { red } from '@mui/material/colors';
import UseGetQuery from 'app/api/useGetQuery';
import React from 'react';
import { stringAvatar } from '../util/util';

interface ProfilePhotoProps {
  displayName?: string;
  email?: string;
  size?: number;
}

const ProfilePhoto = ({ displayName, email, size = 40 }: ProfilePhotoProps) => {
  let response = UseGetQuery(email);
  if (response.loading)
    return <Skeleton variant="circular" width={size} height={size} />;
  return (
    <Avatar
      sx={{ bgcolor: red[500], width: size, height: size }}
      alt={displayName}
      src={response.data as string}
      {...stringAvatar(displayName)}
    />
  );
};

export default ProfilePhoto;
