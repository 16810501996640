import { InputAdornment, Paper, TextField } from '@mui/material';
import React from 'react';

interface CheckoutTextFieldProps {
  label: string;
  onChange: (...args: any[]) => void;
  m: number;
  required?: boolean;
  startAdornment?: JSX.Element | string;
  endAdornment?: JSX.Element | string;
  type?: string;
  multiline?: boolean;
  helperText?: JSX.Element | string;
  value?: any;
  step?: string;
  size?: 'small' | 'medium';
  bgcolor?: string;
  readOnly?: boolean;
}

const CheckoutTextField = ({
  label,
  onChange,
  m,
  required,
  startAdornment,
  endAdornment,
  type,
  multiline,
  helperText,
  value,
  step,
  size = 'small',
  bgcolor = 'secondary.dark',
  readOnly,
}: CheckoutTextFieldProps) => {
  return (
    <Paper sx={{ m: m, bgcolor: bgcolor }} elevation={0}>
      <TextField
        id={label}
        name={label}
        label={label}
        required={required}
        fullWidth
        multiline={multiline}
        type={type}
        helperText={helperText}
        size={size}
        value={value}
        hidden
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{startAdornment}</InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">{endAdornment}</InputAdornment>
          ),
          inputProps: { step: step },
          readOnly: readOnly,
        }}
        sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
        onChange={onChange}
      />
    </Paper>
  );
};

export default CheckoutTextField;
