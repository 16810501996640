import { useMsal } from '@azure/msal-react';
import GradeIcon from '@mui/icons-material/Grade';
import GradingIcon from '@mui/icons-material/Grading';
import { Box, Button } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridToolbar,
} from '@mui/x-data-grid';
import MessageContainer from 'app/common/modals/MessageContainer';
import StatusIcon from 'app/common/modals/StatusIcon';
import { IRequestStatus } from 'app/models/request';
import { useRequestStore } from 'app/stores/requestStore';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

const MyPpeRequests = () => {
  /* State and functions from custom hooks */
  const { requestsByEmail, requestsByEmailLoading, setRequestsByEmail } =
    useRequestStore();
  const { accounts } = useMsal();

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', width: 70 },
    {
      field: 'requester',
      headerName: 'Requester',
      width: 180,
    },
    { field: 'discipline', headerName: 'Discipline', width: 320 },
    { field: 'dsc', headerName: 'DSC', width: 180 },
    { field: 'safetyManager', headerName: 'Safety Manager', width: 180 },
    {
      field: 'createdTime',
      headerName: 'Requested Date',
      width: 180,
      valueFormatter: (value) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'status',
      headerName: 'Status',
      width: 200,
      renderCell: (params: GridRenderCellParams<any, IRequestStatus>) => (
        <Button
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          sx={{
            width: 120,
            bgcolor: `status${params?.value?.replaceAll(' ', '')}.light`,
            color: `status${params?.value?.replaceAll(' ', '')}.main`,
            borderColor: `status${params?.value?.replaceAll(' ', '')}.main`,
            '&:hover': {
              bgcolor: `status${params?.value?.replaceAll(' ', '')}.dark`,
            },
            justifyContent: 'left',
          }}
          startIcon={<StatusIcon status={params.value} />}
        >
          {params?.value}
        </Button>
      ),
    },
    {
      field: 'newHire',
      headerName: 'New Hire',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params: GridRenderCellParams<any, IRequestStatus>) => {
        if (params.row.newHire) {
          return (
            <Button
              variant="text"
              size="small"
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              color="secondary"
              startIcon={<GradeIcon />}
            ></Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <Button component={Link} to={`/my-ppeRequests/${params.row.id}`}>
          View Details
        </Button>
      ),
    },
  ];

  /* Fetch request list on component mount */
  useEffect(() => {
    setRequestsByEmail(accounts[0]?.username);
  }, [setRequestsByEmail, accounts]);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<GradingIcon />} message="My PPE Requests" />
      </Box>
      {/* request list table */}
      <Box m={2}>
        <Box sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={requestsByEmail}
            columns={columns}
            checkboxSelection
            loading={requestsByEmailLoading}
            disableRowSelectionOnClick={true}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </Box>
      </Box>
      {/* Footer spacer */}
      <Box height={128}></Box>
    </Box>
  );
};

export default MyPpeRequests;
