import { Box } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => {
  return (
    <Box
      component={Link}
      to="/"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        component="img"
        sx={{
          maxHeight: { xs: 50, sm: 100 },
          maxWidth: { xs: 100, sm: 200 },
        }}
        alt="CED Logo"
        src="/assets/CED_Logo.jpg"
      />
    </Box>
  );
};

export default Logo;
