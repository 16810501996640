import { Alert, Typography } from '@mui/material';
import React from 'react';

interface MessageContainerProps {
  icon: React.ReactNode;
  message: string;
}

const MessageContainer = ({ icon, message }: MessageContainerProps) => {
  return (
    <Alert severity="info" variant="outlined" icon={icon}>
      <Typography variant="subtitle2" sx={{ fontSize: { xs: 50 } }}>
        {message}
      </Typography>
    </Alert>
  );
};

export default MessageContainer;
