import { Dropzone, FileMosaic } from '@dropzone-ui/react';
import CategoryIcon from '@mui/icons-material/Category';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  FormControl,
  InputLabel,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import MessageContainer from 'app/common/modals/MessageContainer';
import { firstImageSrc } from 'app/common/util/util';
import { IItem } from 'app/models/item';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import CheckoutTextField from 'features/checkout/CheckoutTextField';
import React, { useState } from 'react';

const AddProduct = () => {
  /* State and functions from custom hooks */
  const { categoryList, categoryListLoading } = useCategoryStore();
  const { submitItem, setTotalItemList, itemList, totalItemList, setItemList } =
    useItemStore();
  const { setSnackBar, setAddProductOpen } = useModalStore();
  const { adminUser } = useUserStore();

  /* Define component state variables */
  const [isGroupExist, setIsGroupExist] = useState(false);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const initialItemState: IItem = {
    name: '',
    desc: '',
    qty: -1,
    price: null,
    size: '',
    color: '',
    categoryId: -1,
    groupId: -1,
    hasSize: false,
    hasColor: false,
    hasPrice: false,
    images: [],
    files: [],
    isDeleted: false,
    isDiscontinued: false,
    isOrderable: true,
    tags: [],
  };
  const [item, setItem] = useState<IItem>(initialItemState);

  /* Function to handle form submission */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    if (item.name && item.categoryId && item.qty && item.desc) {
      const payload = new FormData();
      payload.set('name', item.name);
      payload.set('desc', item.desc);
      payload.set('qty', String(item.qty));
      payload.set('hasSize', item.hasSize ? 'True' : 'False');
      payload.set('hasColor', item.hasColor ? 'True' : 'False');
      payload.set('hasPrice', item.hasPrice ? 'True' : 'False');
      payload.set('isDeleted', item.isDeleted ? 'True' : 'False');
      payload.set('isDiscontinued', item.isDiscontinued ? 'True' : 'False');
      payload.set('isOrderable', item.isOrderable ? 'True' : 'False');
      payload.set('price', item.price ? String(item.price) : '');
      payload.set('categoryId', String(item.categoryId));
      payload.set('size', item.size ?? '');
      payload.set('color', item.color ?? '');
      payload.set('groupId', String(item.groupId));

      item.images?.forEach((i) => payload.append('Images', i.file));
      item.tags?.forEach((t) => payload.append('tags', t));

      submitItem(payload)
        .then((response) => {
          if (response?.status === 200) {
            setTotalItemList();
            setSnackBar({
              open: true,
              type: 'success',
              content: `Product submitting is completed successfully.`,
            });
            setAddProductOpen(false);
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Product submitting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };
  console.log(item);
  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* Header */}
      <Box m={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
        <MessageContainer icon={<CategoryIcon />} message="New Product" />
      </Box>
      {/* Text field for entering product name */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <CheckoutTextField
          label="Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem((prevItem) => ({
              ...prevItem,
              name: event.target.value,
            }))
          }
          value={item.name}
          m={0}
          required
        />
      </Paper>
      {/* Text field for entering product tags */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <Autocomplete
          size="small"
          multiple
          freeSolo
          value={item.tags}
          onChange={(event, newValue) => {
            setItem((prevItem) => ({
              ...prevItem,
              tags: newValue,
            }));
          }}
          options={[]}
          renderInput={(params) => <TextField {...params} label="tags" />}
        />
      </Paper>
      {/* Text field for entering product description */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <CheckoutTextField
          label="Description"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem((prevItem) => ({
              ...prevItem,
              desc: event.target.value,
            }))
          }
          value={item.desc}
          m={0}
          required
        />
      </Paper>
      {/* Text field for entering product quantity */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <CheckoutTextField
          label="Stock Quantity"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            setItem((prevItem) => ({
              ...prevItem,
              qty: Number(event.target.value),
            }))
          }
          value={item.qty === -1 ? '' : item.qty}
          m={0}
          type="number"
          required
          step="1"
        />
      </Paper>
      {/* Dropdown for selecting product category */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <FormControl
          sx={{ minWidth: 120, width: '100%' }}
          size="small"
          required
        >
          <InputLabel
            htmlFor="category-select"
            sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
          >
            Category
          </InputLabel>
          {categoryListLoading ? (
            <Select id="category-select" label="Category">
              <Loading />
            </Select>
          ) : (
            <Select
              id="category-select"
              label="Category"
              onChange={(event: SelectChangeEvent) => {
                setItem((prevItem) => ({
                  ...prevItem,
                  categoryId: Number(event.target.value),
                }));
                setItemList(Number(event.target.value));
              }}
              value={item.categoryId === -1 ? '' : String(item.categoryId)}
            >
              {categoryList &&
                categoryList
                  .filter((c) =>
                    adminUser.some(
                      (u) => u.isSuperAdmin || u.categoryId === c.id
                    )
                  )
                  .map((cat) => (
                    <MenuItem key={cat.id} value={cat.id}>
                      {cat.name}
                    </MenuItem>
                  ))}
            </Select>
          )}
        </FormControl>
      </Paper>
      {/* switch to add a product to an existing group */}
      {item.categoryId !== -1 && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <MenuItem
            sx={{
              alignItems: 'center',
              height: { xs: 80, sm: 40 },
              display: { xs: 'block', sm: 'flex' },
            }}
          >
            <ListItemText>
              <Stack direction="row">
                <Typography>Add to an existing group</Typography>
              </Stack>
            </ListItemText>

            <Switch
              checked={isGroupExist}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setIsGroupExist(event.target.checked)
              }
            />
          </MenuItem>
        </Paper>
      )}
      {/* Rendering the dropdown to select a group */}
      {item.categoryId !== -1 && isGroupExist && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <FormControl
            sx={{ minWidth: 120, width: '100%' }}
            size="small"
            required
          >
            <InputLabel
              htmlFor="group-select"
              sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
            >
              Group
            </InputLabel>

            <Select
              id="group-select"
              label="Group"
              onChange={(event: SelectChangeEvent) =>
                setItem((prevItem) => ({
                  ...prevItem,
                  groupId: Number(event.target.value),
                }))
              }
              value={item.groupId === -1 ? '' : String(item.groupId)}
            >
              {itemList &&
                itemList.map((item) => (
                  <MenuItem key={item.id} value={item.groupId}>
                    <ListItemIcon>
                      <AvatarGroup>
                        {item.hasColor ? (
                          totalItemList
                            ?.filter((i) => i.groupId === item.groupId)
                            .map((item) => (
                              <ListItemAvatar key={item.id}>
                                <Avatar
                                  alt={item.name}
                                  src={firstImageSrc(item.images)}
                                />
                              </ListItemAvatar>
                            ))
                        ) : (
                          <ListItemAvatar>
                            <Avatar
                              alt={item.name}
                              src={firstImageSrc(item.images)}
                            />
                          </ListItemAvatar>
                        )}
                      </AvatarGroup>
                    </ListItemIcon>
                    <ListItemText></ListItemText>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ fontSize: { xs: 8, sm: 16 } }}
                    >
                      {item.name}
                    </Typography>
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Paper>
      )}
      {/* switch for selecting whether the product has size */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <MenuItem
          sx={{
            alignItems: 'center',
            height: { xs: 80, sm: 40 },
            display: { xs: 'block', sm: 'flex' },
          }}
        >
          <ListItemText>
            <Stack direction="row">
              <Typography>Has Size</Typography>
            </Stack>
          </ListItemText>
          <Switch
            checked={item.hasSize}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setItem((prevItem) => ({
                ...prevItem,
                hasSize: event.target.checked,
              }))
            }
          />
        </MenuItem>
      </Paper>
      {/* text field for entering size if product has size */}
      {item.hasSize && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <CheckoutTextField
            label="Size"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setItem((prevItem) => ({
                ...prevItem,
                size: event.target.value,
              }))
            }
            value={item.size}
            m={0}
          />
        </Paper>
      )}
      {/* switch for selecting whether the product has color */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <MenuItem
          sx={{
            alignItems: 'center',
            height: { xs: 80, sm: 40 },
            display: { xs: 'block', sm: 'flex' },
          }}
        >
          <ListItemText>
            <Stack direction="row">
              <Typography>Has Color</Typography>
            </Stack>
          </ListItemText>

          <Switch
            checked={item.hasColor}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setItem((prevItem) => ({
                ...prevItem,
                hasColor: event.target.checked,
              }))
            }
          />
        </MenuItem>
      </Paper>
      {/* text field for entering color if product has color */}
      {item.hasColor && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <CheckoutTextField
            label="Color"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setItem((prevItem) => ({
                ...prevItem,
                color: event.target.value,
              }))
            }
            value={item.color}
            m={0}
          />
        </Paper>
      )}
      {/* switch for selecting whether the product has price */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <MenuItem
          sx={{
            alignItems: 'center',
            height: { xs: 80, sm: 40 },
            display: { xs: 'block', sm: 'flex' },
          }}
        >
          <ListItemText>
            <Stack direction="row">
              <Typography>Has Price</Typography>
            </Stack>
          </ListItemText>

          <Switch
            checked={item.hasPrice}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setItem((prevItem) => ({
                ...prevItem,
                hasPrice: event.target.checked,
                price: event.target.checked ? prevItem.price : null,
              }))
            }
          />
        </MenuItem>
      </Paper>
      {/* text field for entering price if product has price */}
      {item.hasPrice && (
        <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
          <CheckoutTextField
            label="Price"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setItem((prevItem) => ({
                ...prevItem,
                price: Number(event.target.value),
              }))
            }
            value={item.price}
            type="number"
            m={0}
            startAdornment="$"
            step="0.01"
          />
        </Paper>
      )}
      {/* Rendering dropzone for uploading images */}
      <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
        <Dropzone
          onChange={(incomingFiles: any[]) =>
            setItem((prevItem) => ({
              ...prevItem,
              images: incomingFiles,
            }))
          }
          value={item.images}
          accept="image/*"
          label="Drag'n drop files here or click to browse"
        >
          {item.images?.map((image, index) => (
            <FileMosaic key={index} {...image} preview />
          ))}
        </Dropzone>
      </Paper>
      {/* submit button */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        mt={10}
        ml={2}
        mr={2}
      >
        <LoadingButton
          variant="contained"
          type="submit"
          loading={submitLoading}
        >
          Submit Product
        </LoadingButton>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default AddProduct;
