import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  NativeSelect,
  TextField,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import ProfilePhoto from 'app/common/modals/ProfilePhoto';
import { no_image_png } from 'app/common/options/noImage';
import { firstImageSrc } from 'app/common/util/util';
import { IOrder, IOrderItem, IOrderStatus } from 'app/models/order';
import { IEmployee } from 'app/models/user';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import { useOrderStore } from 'app/stores/orderStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FormAutocomplete from './FormAutocomplete';
import FormField from './FormField';
import FormHeader from './FormHeader';
import FormSection from './FormSection';

const StarterPack = () => {
  const {
    employeeList,
    employeeListLoading,
    setEmployeeList,
    adminUser,
    officeList,
    officeListLoading,
    setOfficeList,
    user,
  } = useUserStore();

  const { itemList, itemListLoading, setItemList } = useItemStore();
  const { submitOrder } = useOrderStore();
  const { setSnackBar } = useModalStore();

  const initialOrderState: IOrder = {
    requester: '',
    requesterEmail: '',
    status: 'Requested' as IOrderStatus,
    items: [],
    office: '',
    shipName: '',
    addrLn1: '',
    addrLn2: '',
    city: '',
    state: '',
    zip: '',
    comments: '',
    reason: '',
    reasonDesc: '',
    forWhom: '',
    priority: false,
    priorityDate: undefined,
    isPriceable: false,
    employeeNumber: '',
    isMarketingStarterPack: true,
    officeEmployeeCount: undefined,
    categoryId: 2,
  };

  const [contactEmployee, setContactEmployee] = useState<IEmployee | null>(
    null
  );
  const [order, setOrder] = useState<IOrder>(initialOrderState);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  /* Function to handle submit request */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();

    setSubmitLoading(true);

    submitOrder(order)
      .then((response) => {
        if (response?.status === 200) {
          navigate('/confirmation', { state: { order: response?.data } });
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Order submitting is not completed.`,
          });
        }
        setSubmitLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Fetch officeList, employeeList, ppeManagers on component mount */
  useEffect(() => {
    setOfficeList();
    setEmployeeList();
    setItemList(2);
  }, [setOfficeList, setEmployeeList, setItemList]);

  useEffect(() => {
    if (itemList && user && employeeList.length) {
      const updatedItems: IOrderItem[] = itemList
        .filter((i) => i.id !== 162)
        .map((i) => ({
          id: i.id as number,
          itemId: i.id,
          qty: 0,
          item: i,
          price: 0,
          amount: 0,
        }));

      setOrder((prevOrder) => ({
        ...prevOrder,
        items: updatedItems,
        requester: user.displayName as string,
        requesterEmail: user.userPrincipalName as string,
        employeeNumber: employeeList.find(
          (e) => e.email.toLowerCase() === user.userPrincipalName?.toLowerCase()
        )?.employeeNumber,
      }));
    }
  }, [itemList, user, employeeList]);

  console.log(order);
  console.log(itemList);

  if (itemListLoading || officeListLoading || order.items.length === 0)
    return <Loading />;

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <FormHeader text="Marketing Starter Pack" />
      <Box m={4}>
        <Grid container spacing={2}>
          <FormSection sectionTitle="Office Info">
            <FormField
              label={<Button variant="text">Office Contact</Button>}
              control={
                <FormAutocomplete
                  loading={employeeListLoading}
                  value={contactEmployee}
                  onChange={(event, selectedEmployee: IEmployee | null) => {
                    setOrder((prevOrder) => {
                      if (selectedEmployee) {
                        setContactEmployee(selectedEmployee);

                        const updatedOrder: IOrder = {
                          ...prevOrder,

                          forWhom: selectedEmployee.displayName,
                        };
                        return updatedOrder;
                      }
                      return prevOrder;
                    });
                  }}
                  options={[...employeeList].sort((a, b) => {
                    const isASelected = a.displayName === order.forWhom;
                    const isBSelected = b.displayName === order.forWhom;

                    if (isASelected && !isBSelected) {
                      return -1;
                    }
                    if (!isASelected && isBSelected) {
                      return 1;
                    }

                    return employeeList.indexOf(a) - employeeList.indexOf(b);
                  })}
                  renderInput={(params) => {
                    return (
                      <TextField
                        {...params}
                        variant="standard"
                        sx={{
                          '& .MuiFormLabel-asterisk': {
                            color: 'warning.main',
                          },
                        }}
                        required
                        InputProps={{
                          ...params.InputProps,
                          style: {
                            fontSize: '1rem',
                            fontWeight: 500,
                          },
                          startAdornment: (
                            <InputAdornment position="start">
                              <ProfilePhoto
                                email={contactEmployee?.email}
                                displayName={contactEmployee?.displayName}
                                size={24}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    );
                  }}
                />
              }
            />
            <FormField
              label={<Button variant="text">Office Location</Button>}
              control={
                <FormControl
                  sx={{ minWidth: 120, width: '100%' }}
                  size="medium"
                  required
                >
                  <InputLabel
                    htmlFor="office-grouped-native-select"
                    variant="standard"
                    sx={{
                      '& .MuiFormLabel-asterisk': { color: 'warning.main' },
                    }}
                  >
                    Office Location
                  </InputLabel>
                  <NativeSelect
                    id="office-grouped-native-select"
                    onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                      setOrder((prevOrder) => ({
                        ...prevOrder,
                        office: event.target.value,
                      }));
                    }}
                    value={order.office}
                  >
                    <option aria-label="None" value="" />
                    {officeList &&
                      Object.keys(officeList).map((state) => (
                        <optgroup label={state} key={state}>
                          {officeList[state].map((city, index) => (
                            <option value={`${city}, ${state}`} key={index}>
                              {city}, {state}
                            </option>
                          ))}
                        </optgroup>
                      ))}
                  </NativeSelect>
                </FormControl>
              }
            />
            <FormField
              label={<Button variant="text">Office Employee Count</Button>}
              control={
                <TextField
                  variant="standard"
                  type="number"
                  label="Employee Count"
                  required
                  onChange={(event) =>
                    setOrder((prevState) => ({
                      ...prevState,
                      officeEmployeeCount: Number(event.target.value),
                    }))
                  }
                />
              }
            />
          </FormSection>

          <FormSection sectionTitle="Item Info">
            {order.items.map((orderItem, index) => (
              <FormField
                key={index}
                label={
                  <Link
                    to={`/inventory/${orderItem.item.categoryId}/${orderItem.item.id}`}
                  >
                    <Button
                      variant="text"
                      startIcon={
                        <Box
                          component="img"
                          src={firstImageSrc(orderItem.item.images)}
                          height={50}
                          width={50}
                          onError={(e) => {
                            e.currentTarget.src = no_image_png;
                          }}
                          alt={orderItem.item.name}
                          sx={{
                            objectFit: 'contain',
                            '&:hover': {
                              opacity: 0.8,
                            },
                          }}
                        />
                      }
                    >
                      {orderItem.item.name}
                    </Button>
                  </Link>
                }
                control={
                  <TextField
                    variant="standard"
                    type="number"
                    label="Quantity"
                    required
                    InputProps={{
                      inputProps: { min: 0 },
                    }}
                    onChange={(event) => {
                      setOrder((prevOrder) => {
                        const updatedItems = [...prevOrder.items];
                        updatedItems[index] = {
                          ...orderItem,
                          qty: Number(event.target.value),
                        };
                        return {
                          ...prevOrder,
                          items: updatedItems,
                        };
                      });
                    }}
                  />
                }
              />
            ))}
          </FormSection>
        </Grid>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" mt={10}>
        <LoadingButton
          variant="contained"
          type="submit"
          loading={submitLoading}
        >
          Submit
        </LoadingButton>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default StarterPack;
