import AddBoxIcon from '@mui/icons-material/AddBox';
import AddCommentIcon from '@mui/icons-material/AddComment';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PrintIcon from '@mui/icons-material/Print';
import PushPinIcon from '@mui/icons-material/PushPin';
import ScheduleIcon from '@mui/icons-material/Schedule';
import { LoadingButton } from '@mui/lab';
import Timeline from '@mui/lab/Timeline';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import {
  Alert,
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { no_image_png } from 'app/common/options/noImage';
import { shippingMethods } from 'app/common/options/shippingOptions';
import { firstImageSrc, useSmallScreen } from 'app/common/util/util';
import { IItem } from 'app/models/item';
import { IOrder, IOrderItem } from 'app/models/order';
import { useModalStore } from 'app/stores/modalStore';
import { useOrderStore } from 'app/stores/orderStore';
import { useUserStore } from 'app/stores/userStore';
import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';
import OrderContent from './OrderContent';

const OrderDetails = () => {
  /* State and functions from custom hooks */
  const { orderId }: any = useParams();
  const { adminUser } = useUserStore();
  const {
    order,
    orderLoading,
    setOrder,
    modifyOrder,
    shipOrder,
    returnOrder,
    approveOrder,
    rejectOrder,
  } = useOrderStore();
  const smallScreen = useSmallScreen();
  const navigate = useNavigate();
  const { setOrderPreviewOpen, setSnackBar } = useModalStore();

  const [isModified, setIsModified] = useState<boolean>(false);
  const componentRef = useRef(null);
  const [modifiedOrder, setModifiedOrder] = useState<IOrder>({} as IOrder);
  const admin = adminUser.find((u) => u.categoryId === order?.categoryId);
  const [approveLoading, setApproveLoading] = useState<boolean>(false);
  const [shipLoading, setShipLoading] = useState<boolean>(false);
  const [returnLoading, setReturnLoading] = useState<boolean>(false);
  const [rejectLoading, setRejectLoading] = useState<boolean>(false);

  /* Function to remove items from the order */
  const removeItemsFromOrder = (newItem: IItem, quantity: number) => {
    const items = modifiedOrder.items;
    let updatedItems: IOrderItem[] = [];

    items?.forEach((i) => {
      if (i.item.id === newItem.id && i.qty > quantity) {
        updatedItems.push({
          ...i,
          qty: i.qty - quantity,
          amount: i.price ? i.price * (i.qty - quantity) : 0,
        });
      } else if (i.item.id !== newItem.id) {
        updatedItems.push(i);
      }
    });
    let subTotal = updatedItems.reduce((acc, i) => acc + i.price * i.qty, 0);

    setModifiedOrder((prevOrder) => ({
      ...prevOrder,
      items: updatedItems,
      subTotal: subTotal,
      redeemAmount:
        subTotal < order?.redeemAmount! ? subTotal : prevOrder.redeemAmount,
      payrollDeduction:
        subTotal > prevOrder.redeemAmount!
          ? subTotal - prevOrder.redeemAmount!
          : prevOrder.payrollDeduction,
      adjustedCredits:
        subTotal < order?.redeemAmount!
          ? order?.redeemAmount! - subTotal
          : prevOrder.adjustedCredits,
    }));
  };

  /* Function to add items to the order */
  const addItemsToOrder = (newItem: IItem, quantity: number) => {
    const items = modifiedOrder.items;
    let updatedItems: IOrderItem[] = [];

    items?.forEach((i) => {
      if (i.item.id === newItem.id) {
        updatedItems.push({
          ...i,
          qty: i.qty + quantity,
          amount: i.price ? i.price * (i.qty + quantity) : 0,
        });
      } else if (i.item.id !== newItem.id) {
        updatedItems.push(i);
      }
    });
    let subTotal = updatedItems.reduce((acc, i) => acc + i.price * i.qty, 0);

    setModifiedOrder((prevOrder) => ({
      ...prevOrder,
      items: updatedItems,
      subTotal: subTotal,
      redeemAmount:
        subTotal >= prevOrder?.redeemAmount!
          ? Math.min(subTotal, prevOrder?.redeemAmount!)
          : prevOrder.redeemAmount,
      payrollDeduction:
        subTotal > prevOrder.redeemAmount!
          ? subTotal - prevOrder.redeemAmount!
          : prevOrder.payrollDeduction,
      adjustedCredits:
        subTotal < order?.redeemAmount!
          ? order?.redeemAmount! - subTotal
          : prevOrder.adjustedCredits,
    }));
  };

  /* Function to handle shipping the order */
  const handleShipOrder = () => {
    setShipLoading(true);

    let updatedOrder: IOrder = {
      ...modifiedOrder,
      shipper: admin,
      shipperId: admin?.id,
    };

    shipOrder(updatedOrder)
      .then((response) => {
        if (response?.status === 200) {
          setOrder(orderId);
          setSnackBar({
            open: true,
            type: 'success',
            content: `Order has been marked shipped.`,
          });
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Order making shipped is not completed.`,
          });
        }
        setShipLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Function to handle returning the order */
  const handleReturnOrder = () => {
    setReturnLoading(true);
    returnOrder(modifiedOrder)
      .then((response) => {
        if (response?.status === 200) {
          setOrder(orderId);
          setSnackBar({
            open: true,
            type: 'success',
            content: `Order has been marked returned.`,
          });
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Order making returned is not completed.`,
          });
        }
        setReturnLoading(true);
      })
      .catch((error) => console.log(error));
  };

  /* Function to handle approving the order */
  const handleApproveOrder = () => {
    setApproveLoading(true);
    if (!admin || !admin.id) {
      setSnackBar({
        open: true,
        type: 'error',
        content: `Approver Id cannot be verified: Order marking approved is not completed.`,
      });
      return;
    }

    let updatedOrder: IOrder = {
      ...modifiedOrder,
      approver: admin,
      approverId: admin.id,
    };

    approveOrder(updatedOrder)
      .then((response) => {
        if (response?.status === 200) {
          setOrder(orderId);
          setSnackBar({
            open: true,
            type: 'success',
            content: `Order has been marked approved.`,
          });
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Order marking approved is not completed.`,
          });
        }
        setApproveLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Function to handle rejecting the order */
  const handleRejectOrder = () => {
    setRejectLoading(true);
    if (!admin || !admin.id) {
      setSnackBar({
        open: true,
        type: 'error',
        content: `Rejecter Id cannot be verified: Order marking rejected is not completed.`,
      });
      return;
    }

    let updatedOrder: IOrder = {
      ...modifiedOrder,
      rejecter: admin,
      rejecterId: admin.id,
    };

    rejectOrder(updatedOrder)
      .then((response) => {
        if (response?.status === 200) {
          setOrder(orderId);
          setSnackBar({
            open: true,
            type: 'success',
            content: `Order has been marked rejected.`,
          });
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Order making rejected is not completed.`,
          });
        }
        setRejectLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Function to handle modifying the order */
  const handleModifyOrder = () => {
    setApproveLoading(true);
    if (!admin || !admin.id) {
      setSnackBar({
        open: true,
        type: 'error',
        content: `Approver Id cannot be verified: Order modified approval is not completed.`,
      });
      return;
    }

    let updatedOrder: IOrder = {
      ...modifiedOrder,
      approver: admin,
      approverId: admin.id,
    };

    modifyOrder(updatedOrder)
      .then((response) => {
        if (response?.status === 200) {
          setOrder(orderId);
          setSnackBar({
            open: true,
            type: 'success',
            content: `Order has been modified and approved.`,
          });
        } else {
          setSnackBar({
            open: true,
            type: 'error',
            content: `Order modifying and approval are not completed.`,
          });
        }
        setApproveLoading(false);
      })
      .catch((error) => console.log(error));
  };

  /* Fetch order details on component mount */
  useEffect(() => {
    setOrder(orderId);
  }, [orderId, setOrder, modifyOrder]);

  /* Update order details when fetched */
  useEffect(() => {
    if (order) {
      setModifiedOrder(order);
    }
  }, [order]);

  /* Render loading spinner if order is still loading */
  /* Render NotFound component if order is not found */
  if (orderLoading) {
    return <Loading />;
  } else if (!order) {
    return <NotFound />;
  }

  return (
    <Box mx={2} mt={2}>
      {/* Back to table and print order buttons */}
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            setOrderPreviewOpen(false);
            navigate('/admin/order');
          }}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Order
            </Button>
          )}
        />
      </Box>
      {/* Timeline component displaying order status */}
      <Grid container spacing={smallScreen ? 0 : 2}>
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Timeline position="right">
            <TimelineItem>
              <TimelineOppositeContent color="text.secondary">
                {new Date(order.requested as Date).toLocaleDateString()}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineDot />
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>Requested</TimelineContent>
            </TimelineItem>
            {order.status === 'Requested' ? (
              <>
                <Box m={2}>
                  <TimelineItem>
                    <TimelineOppositeContent color="text.secondary">
                      If order needs to be adjusted/Modified
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineDot />
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                      <Box>
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ mr: 1, width: 100 }}
                          onClick={() => {
                            setIsModified(true);
                            setModifiedOrder(order);
                          }}
                          startIcon={<EditIcon color="info" />}
                        >
                          Modify
                        </Button>
                      </Box>
                    </TimelineContent>
                  </TimelineItem>
                  {/* Table displaying modified items */}
                  {isModified &&
                    modifiedOrder &&
                    (modifiedOrder.items?.length ? (
                      <TableContainer component={Paper} elevation={3}>
                        <Table aria-label="simple table">
                          {/* Table header */}
                          <TableHead>
                            <TableRow>
                              <TableCell>Product</TableCell>
                              <TableCell>Name</TableCell>
                              <TableCell>Size</TableCell>
                              <TableCell>color</TableCell>
                              <TableCell align="center">Quantity</TableCell>
                              {order?.isPriceable && (
                                <TableCell align="center">Amount</TableCell>
                              )}
                              <TableCell></TableCell>
                            </TableRow>
                          </TableHead>
                          {/* Table body */}
                          <TableBody>
                            {modifiedOrder.items?.map((orderItem) => (
                              <TableRow key={orderItem.id}>
                                <TableCell component="th" scope="row">
                                  <Link
                                    to={`/inventory/${orderItem.item.categoryId}/${orderItem.item.id}`}
                                    onClick={() => setOrderPreviewOpen(false)}
                                  >
                                    <Box
                                      component="img"
                                      src={firstImageSrc(orderItem.item.images)}
                                      height={50}
                                      width={50}
                                      onError={(e) => {
                                        e.currentTarget.src = no_image_png;
                                      }}
                                      alt={orderItem.item.name}
                                      sx={{
                                        cursor: 'pointer',
                                        objectFit: 'contain',
                                        '&:hover': {
                                          opacity: 0.8,
                                        },
                                      }}
                                    />
                                  </Link>
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {orderItem.item.name}
                                </TableCell>
                                <TableCell>{orderItem.item.size}</TableCell>
                                <TableCell>{orderItem.item.color}</TableCell>
                                <TableCell align="center">
                                  <Box display="flex" justifyContent="flex-end">
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      alignItems="center"
                                      justifyContent="center"
                                    >
                                      <Button
                                        variant="outlined"
                                        disableRipple
                                        size="small"
                                        startIcon={
                                          <IndeterminateCheckBoxIcon
                                            sx={{
                                              m: { xs: 0, sm: 1 },
                                              '&:hover': {
                                                color: 'secondary.main',
                                              },
                                              color: 'green',
                                            }}
                                            onClick={() =>
                                              removeItemsFromOrder(
                                                orderItem.item,
                                                1
                                              )
                                            }
                                          />
                                        }
                                        endIcon={
                                          <AddBoxIcon
                                            sx={{
                                              m: { xs: 0, sm: 1 },
                                              '&:hover': {
                                                color: 'secondary.main',
                                              },
                                              color: 'green',
                                            }}
                                            onClick={() =>
                                              addItemsToOrder(orderItem.item, 1)
                                            }
                                          />
                                        }
                                        sx={{
                                          color: 'black',
                                          border: 'none',
                                          '&:hover': {
                                            bgcolor: 'transparent',
                                          },
                                        }}
                                      >
                                        {orderItem.qty}
                                      </Button>
                                    </Box>
                                  </Box>
                                </TableCell>
                                {order.isPriceable && (
                                  <TableCell align="center">
                                    {orderItem.amount.toFixed(2)}
                                  </TableCell>
                                )}
                                <TableCell align="center">
                                  <IconButton
                                    aria-label="delete"
                                    size="large"
                                    color="primary"
                                    onClick={() =>
                                      removeItemsFromOrder(
                                        orderItem.item,
                                        orderItem.qty
                                      )
                                    }
                                  >
                                    <CancelIcon />
                                  </IconButton>
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                          {/* Table footer */}
                          <TableFooter>
                            {order?.isPriceable && modifiedOrder.subTotal && (
                              <>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                  >
                                    Subtotal:
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                  >
                                    ${modifiedOrder.subTotal.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                  >
                                    Credits:
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{
                                      fontSize: 14,
                                    }}
                                  >
                                    -$
                                    {modifiedOrder.redeemAmount
                                      ? modifiedOrder.redeemAmount.toFixed(2)
                                      : 0}
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell></TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                  >
                                    Total:
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                                  >
                                    $
                                    {modifiedOrder.redeemAmount
                                      ? (
                                          modifiedOrder.subTotal -
                                          modifiedOrder.redeemAmount
                                        ).toFixed(2)
                                      : modifiedOrder.subTotal.toFixed(2)}
                                  </TableCell>
                                </TableRow>
                              </>
                            )}
                          </TableFooter>
                        </Table>
                      </TableContainer>
                    ) : (
                      <Alert variant="outlined" severity="warning">
                        Empty modified order is not allowed. Refresh page or
                        click modify to handle the order again.
                      </Alert>
                    ))}
                </Box>
                {/* Conditional rendering for adjusted credit information */}
                {order.isPriceable &&
                  modifiedOrder.items?.length &&
                  modifiedOrder.redeemAmount &&
                  order.redeemAmount &&
                  modifiedOrder.redeemAmount !== order.redeemAmount && (
                    <Box m={2}>
                      <Alert variant="outlined" severity="info">
                        Adjusted credit in requested's CedCoin balance: $
                        {order.redeemAmount} - ${modifiedOrder.redeemAmount} = $
                        {order.redeemAmount - modifiedOrder.redeemAmount}.
                      </Alert>
                    </Box>
                  )}
                <Box m={2}>
                  <List
                    component={Paper}
                    elevation={3}
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      padding: 2,
                    }}
                  >
                    {/* change order admin comments */}
                    <ListItem>
                      <ListItemIcon>
                        <AddCommentIcon />
                      </ListItemIcon>
                      <TextField
                        id="AdminComments"
                        name="AdminComments"
                        label="Admin Comments"
                        fullWidth
                        multiline
                        size="medium"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start"></InputAdornment>
                          ),
                        }}
                        onChange={(
                          event: React.ChangeEvent<HTMLInputElement>
                        ) =>
                          setModifiedOrder((prevOrder) => ({
                            ...prevOrder,
                            adminComments: event.target.value,
                          }))
                        }
                        value={modifiedOrder.adminComments ?? ''}
                      />
                    </ListItem>
                    {/* ListItem for approve/reject buttons */}
                    <ListItem>
                      <ListItemIcon></ListItemIcon>
                      <ListItemText></ListItemText>
                      <Box>
                        <LoadingButton
                          variant="contained"
                          size="small"
                          sx={{ m: 1 }}
                          onClick={
                            isModified ? handleModifyOrder : handleApproveOrder
                          }
                          startIcon={<CheckIcon color="success" />}
                          disabled={
                            isModified && modifiedOrder.items?.length === 0
                          }
                          loading={approveLoading}
                        >
                          {isModified ? 'Modify and Approve' : 'Approve'}
                        </LoadingButton>
                        <LoadingButton
                          variant="contained"
                          size="small"
                          sx={{ m: 1 }}
                          onClick={handleRejectOrder}
                          startIcon={<CloseIcon color="error" />}
                          disabled={
                            isModified && modifiedOrder.items?.length === 0
                          }
                          loading={rejectLoading}
                        >
                          Reject
                        </LoadingButton>
                      </Box>
                    </ListItem>
                  </List>
                </Box>
              </>
            ) : order.status === 'Restock' ? (
              <>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary"></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Restock</TimelineContent>
                </TimelineItem>
                <Box
                  component={Paper}
                  elevation={3}
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    padding: 2,
                    m: 2,
                  }}
                >
                  This order has been restock. No further action required.
                </Box>
              </>
            ) : (
              <TimelineItem>
                <TimelineOppositeContent color="text.secondary">
                  {new Date(
                    order.approved ?? (order.rejected as Date)
                  ).toLocaleDateString()}
                </TimelineOppositeContent>
                <TimelineSeparator>
                  <TimelineDot />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  {order.approved ? 'Approved' : 'Rejected'}
                </TimelineContent>
              </TimelineItem>
            )}
            {/* Conditional rendering for 'Approved' status */}
            {order.status === 'Approved' && (
              <Box m={2}>
                <List
                  component={Paper}
                  elevation={3}
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    padding: 2,
                  }}
                >
                  {/* change order shipping method */}
                  <ListItem>
                    <ListItemIcon>
                      <LocalShippingIcon />
                    </ListItemIcon>

                    <FormControl
                      sx={{ minWidth: 120, width: '100%' }}
                      size="medium"
                    >
                      <InputLabel
                        htmlFor="reason-grouped-native-select"
                        sx={{
                          '& .MuiFormLabel-asterisk': { color: 'warning.main' },
                        }}
                      >
                        Shipping Method
                      </InputLabel>
                      <Select
                        id="reason-grouped-native-select"
                        label="Shipping Method"
                        native
                        onChange={(event: SelectChangeEvent) =>
                          setModifiedOrder((prevOrder) => ({
                            ...prevOrder,
                            shippingMethod: event.target.value,
                          }))
                        }
                        value={modifiedOrder.shippingMethod ?? ''}
                      >
                        <option aria-label="None" value="" />
                        {shippingMethods?.map((s) => (
                          <option value={s} key={s}>
                            {s}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </ListItem>
                  {/* change order ETA */}
                  <ListItem>
                    <ListItemIcon>
                      <ScheduleIcon />
                    </ListItemIcon>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="ETA Date"
                        value={
                          modifiedOrder.eta ? dayjs(modifiedOrder.eta) : null
                        }
                        onChange={(newValue: Dayjs | null) =>
                          setModifiedOrder((prevOrder) => ({
                            ...prevOrder,
                            eta: newValue ? newValue.toDate() : undefined,
                          }))
                        }
                        sx={{ width: '100%' }}
                        disablePast
                        slotProps={{
                          actionBar: {
                            actions: ['clear'],
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </ListItem>
                  {/* change order tracking info */}
                  <ListItem>
                    <ListItemIcon>
                      <PushPinIcon />
                    </ListItemIcon>
                    <TextField
                      id="TrackingInfo"
                      name="TrackingInfo"
                      label="Tracking Info"
                      fullWidth
                      size="medium"
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setModifiedOrder((prevOrder) => ({
                          ...prevOrder,
                          trackingInfo: event.target.value,
                        }))
                      }
                      value={modifiedOrder.trackingInfo ?? ''}
                    />
                  </ListItem>
                  <ListItem>
                    <ListItemIcon></ListItemIcon>
                    <ListItemText></ListItemText>
                    <Box mt={1}>
                      <LoadingButton
                        variant="contained"
                        size="small"
                        onClick={handleShipOrder}
                        loading={shipLoading}
                      >
                        Mark Shipped
                      </LoadingButton>
                    </Box>
                  </ListItem>
                </List>
              </Box>
            )}
            {/* Conditional rendering for 'Rejected' status */}
            {order.status === 'Rejected' && (
              <Box
                component={Paper}
                elevation={3}
                sx={{
                  width: '100%',
                  bgcolor: 'background.paper',
                  padding: 2,
                  m: 2,
                }}
              >
                This order has been rejected. No further action required.
              </Box>
            )}
            {/* Conditional rendering for 'Shipped/Received/Picked up' status */}
            {(order.status === 'Shipped' ||
              order.status === 'Received' ||
              order.status === 'Picked up') && (
              <>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    {new Date(order.shipped as Date).toLocaleDateString()}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Shipped</TimelineContent>
                </TimelineItem>
                {order.status === 'Shipped' && (
                  <Box
                    component={Paper}
                    elevation={3}
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      padding: 2,
                      m: 2,
                    }}
                  >
                    Waiting for requester's confirmation in email to change
                    status to "Received" or "Picked up" or "Returned"
                  </Box>
                )}
              </>
            )}
            {/* Conditional rendering for 'Received/Returned/Picked up' status */}
            {(order.status === 'Received' ||
              order.status === 'Returned' ||
              order.status === 'Picked up') && (
              <>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary">
                    {new Date(order.received as Date).toLocaleDateString()}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>Received</TimelineContent>
                </TimelineItem>
                {order.status === 'Received' && (
                  <Box
                    component={Paper}
                    elevation={3}
                    sx={{
                      width: '100%',
                      bgcolor: 'background.paper',
                      padding: 2,
                      m: 2,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'cetner',
                    }}
                  >
                    <LoadingButton
                      variant="contained"
                      size="small"
                      onClick={handleReturnOrder}
                      loading={returnLoading}
                    >
                      Mark Returned
                    </LoadingButton>
                  </Box>
                )}
              </>
            )}
            {/* Conditional rendering for 'Returned/Picked up' status */}
            {(order.status === 'Returned' || order.status === 'Picked up') && (
              <>
                <TimelineItem>
                  <TimelineOppositeContent color="text.secondary"></TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineDot />
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent>{order.status}</TimelineContent>
                </TimelineItem>
                <Box
                  component={Paper}
                  elevation={3}
                  sx={{
                    width: '100%',
                    bgcolor: 'background.paper',
                    padding: 2,
                    m: 2,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'cetner',
                  }}
                >
                  This order has been {order.status}. No further action
                  required.
                </Box>
              </>
            )}
          </Timeline>
        </Grid>
        {/* ordercontent component */}
        <Grid item xs={12} sm={12} md={12} lg={6}>
          <Box mb={2}>
            <OrderContent order={order} ref={componentRef} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrderDetails;
