import QueryStatsIcon from '@mui/icons-material/QueryStats';
import {
  Avatar,
  Box,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import { DataGrid, GridColDef, GridRowId, GridToolbar } from '@mui/x-data-grid';
import MessageContainer from 'app/common/modals/MessageContainer';
import { firstImageSrc, stringAvatar } from 'app/common/util/util';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useOrderStore } from 'app/stores/orderStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';

const OrderMetrics = () => {
  /* State and functions from custom hooks */
  const { orderItemSumList, orderItemSumListLoading, setOrderItemSumList } =
    useOrderStore();
  const { adminUser } = useUserStore();
  const { categoryList } = useCategoryStore();

  /* Define component state variables */
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(
    adminUser[0].categoryId
  );

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'Item#',
      width: 70,
      valueGetter: (value, row) => row.orderItem.item.id,
    },
    {
      field: 'images',
      headerName: 'Image',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params) => (
        <Avatar
          src={firstImageSrc(params.row.orderItem.item.images)}
          sx={{
            cursor: 'pointer',
            objectFit: 'contain',
            '&:hover': {
              opacity: 0.8,
            },
          }}
          {...stringAvatar(params.row.orderItem.item.name)}
        />
      ),
    },
    {
      field: 'name',
      headerName: 'Name',
      width: 400,
      valueGetter: (value, row) => row.orderItem.item.name,
    },
    {
      field: 'size',
      headerName: 'Size',
      width: 100,
      valueGetter: (value, row) => row.orderItem.item.size,
    },
    {
      field: 'color',
      headerName: 'Color',
      width: 100,
      valueGetter: (value, row) => row.orderItem.item.color,
    },
    {
      field: 'unshippedSum',
      headerName: 'Total Number In Unshipped Orders',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      width: 100,
      valueGetter: (value, row) => row.unshippedSum,
    },
    {
      field: 'sum',
      headerName: 'Total Number In All Orders',
      align: 'center',
      headerAlign: 'center',
      flex: 1,
      width: 100,
      valueGetter: (value, row) => row.sum,
    },
  ];

  /* Fetch order item sum list based on selected category when component mounts */
  useEffect(() => {
    if (adminUser.length) {
      setOrderItemSumList(adminUser[0].categoryId);
    }
  }, [setOrderItemSumList, adminUser]);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<QueryStatsIcon />} message="Order Metrics" />
      </Box>
      {/* Category selection */}
      <Box m={2}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Category
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedCategory}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedCategory(
                Number((event.target as HTMLInputElement).value)
              );
              setOrderItemSumList(
                Number((event.target as HTMLInputElement).value)
              );
            }}
          >
            {categoryList
              ?.filter((c) => adminUser.some((u) => u.categoryId === c.id))
              .map((c) => (
                <FormControlLabel
                  key={c.id}
                  value={c.id}
                  control={<Radio />}
                  label={c.name}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Box>
      {/* Data grid displaying order item sum list */}
      <Box m={2}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={orderItemSumList.filter((o) => o.orderItem !== null)}
            columns={columns}
            getRowId={(row) => row.orderItem.id}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
              setSelectionModel([...newSelection])
            }
            rowSelectionModel={selectionModel}
            autoHeight
            loading={orderItemSumListLoading}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
          />
        </Box>
      </Box>
      {/* footer gap filler */}
      <Box height={128}></Box>
    </Box>
  );
};

export default OrderMetrics;
