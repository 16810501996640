import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import {
  Alert,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Chip,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  Stack,
  Tab,
  Tabs,
  TextField,
  Toolbar,
  Typography,
} from '@mui/material';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { no_image_png } from 'app/common/options/noImage';
import { customSizeOrder } from 'app/common/options/sizeOptions';
import { firstImageSrc, stringAvatar } from 'app/common/util/util';
import { IItem } from 'app/models/item';
import { useCartStore } from 'app/stores/cartStore';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

const ItemDetails = () => {
  /* State and functions from custom hooks */
  const { catId, itemId }: any = useParams();
  const { adminUser, user, locations } = useUserStore();
  const {
    item,
    itemLoading,
    itemGroupList,
    itemGroupTotalStock,
    setItem,
    setItemGroupList,
  } = useItemStore();
  const { category, categoryLoading, setCategory } = useCategoryStore();
  const {
    items,
    customizedItems,
    cartCategory,
    addItemsToCart,
    setCartCategory,
    replaceCart,
  } = useCartStore();
  const { setSnackBar, replaceCartOpen, setReplaceCartOpen } = useModalStore();

  /* Define component state variables */
  const [orderQty, setOrderQty] = useState<number>(1);
  const [buttonClicked, setButtonClicked] = useState<boolean>(false);
  const [isSizeSelected, setIsSizeSelected] = useState<boolean>(false);
  const uniqueColorsMap: { [key: string]: boolean } = {};
  const [selectedItem, setSelectedItem] = useState<IItem>();
  const stationaryPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 1)
  );

  const marketingStandardStockPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 2)
  );

  /* Function to handle adding items to cart */
  const handleAddItemsToCart = (event: React.FormEvent) => {
    event.preventDefault();
    setButtonClicked(true);

    if (!selectedItem) return;

    if (
      (items.length > 0 || customizedItems.length > 0) &&
      cartCategory &&
      cartCategory.id !== selectedItem.categoryId &&
      isSizeSelected === selectedItem.hasSize
    ) {
      setReplaceCartOpen(true);
    } else if (isSizeSelected === selectedItem.hasSize) {
      setCartCategory(selectedItem?.category);
      addItemsToCart(selectedItem, orderQty);
      setSnackBar({
        open: true,
        type: 'success',
        content: (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography>
              You added {orderQty} {selectedItem.size} {selectedItem.color}
            </Typography>
            <Box
              m={1}
              component="img"
              bgcolor="white"
              alt={selectedItem?.name}
              height={50}
              width={50}
              loading="lazy"
              sx={{
                cursor: 'pointer',
                objectFit: 'contain',
                '&:hover': {
                  opacity: 0.8,
                },
              }}
              src={firstImageSrc(selectedItem?.images)}
              onError={(e) => {
                e.currentTarget.src = no_image_png;
              }}
            />
            <Typography>to the cart.</Typography>
          </Box>
        ),
      });
    }
  };

  /* Fetch item and category data on component mount */
  useEffect(() => {
    setItem(itemId);
    setCategory(catId);
    setItemGroupList(itemId);
  }, [catId, itemId, setItem, setCategory, setItemGroupList]);

  /* Update selected item when item changes */
  useEffect(() => {
    if (item) setSelectedItem(item);
  }, [item]);

  /* Render loading spinner if data is loading */
  /* Render not found if data is not available or only available for admin, or only available for ced admins*/
  if (catId === '1' && !stationaryPermission) {
    return (
      <NotFound error="You have not been granted permission for this page" />
    );
  }

  if (catId === '2' && !marketingStandardStockPermission) {
    return (
      <NotFound error="You have not been granted permission for this page" />
    );
  }

  if (categoryLoading || itemLoading) {
    return <Loading />;
  } else if (String(item?.categoryId) !== catId) {
    return <NotFound />;
  }

  return (
    <Box>
      {/* breadcrumbs bar */}
      <Toolbar
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          borderTop: '1px solid black',
          borderBottom: '1px solid black',
        }}
      >
        <Breadcrumbs aria-label="breadcrumb">
          <Box
            component={Link}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="caption">HOME</Typography>
          </Box>
          <Box
            component={Link}
            to={'/inventory/' + catId}
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            <Typography variant="caption">{category?.name}</Typography>
          </Box>
          <Typography color="text.primary" variant="caption">
            {item?.name}
          </Typography>
        </Breadcrumbs>
      </Toolbar>
      {/* item details */}
      <Grid container columns={{ xs: 2, sm: 2, md: 4 }}>
        {/* left content */}
        <Grid item xs={2} sm={2} md={2}>
          {item?.isOrderable ? (
            <Box
              component="img"
              bgcolor="white"
              alt={selectedItem?.name}
              height="calc(100vh - 64px - 64px - 64px)"
              loading="lazy"
              sx={{
                objectFit: 'contain',
                p: 2,
                width: { xs: '100vw', sm: '100vw', md: '50vw' },
              }}
              src={firstImageSrc(selectedItem?.images)}
              onError={(e) => {
                e.currentTarget.src = no_image_png;
              }}
            />
          ) : (
            <Box sx={{ position: 'relative' }}>
              <Box
                component="img"
                bgcolor="white"
                alt={selectedItem?.name}
                height="calc(100vh - 64px - 64px - 64px)"
                loading="lazy"
                sx={{
                  objectFit: 'contain',
                  p: 2,
                  width: { xs: '100vw', sm: '100vw', md: '50vw' },
                }}
                src={firstImageSrc(selectedItem?.images)}
                onError={(e) => {
                  e.currentTarget.src = no_image_png;
                }}
              />
              <Box
                sx={{
                  position: 'absolute',
                  bottom: '50%',
                  left: 0,
                  width: '100%',
                  bgcolor: 'rgba(0, 0, 0, 0.54)',
                  color: 'white',
                  padding: '10px',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Typography sx={{ fontSize: { xs: 36, md: 60 } }}>
                  Coming Soon
                </Typography>
              </Box>
            </Box>
          )}
        </Grid>
        {/* right content */}
        <Grid
          item
          xs={2}
          sm={2}
          md={2}
          minHeight={600}
          bgcolor="secondary.dark"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Typography
              component="div"
              sx={{ fontSize: 22, fontWeight: 500 }}
              m={4}
              textAlign="center"
            >
              {item?.name}
            </Typography>
            <Chip
              label="Event Use Only"
              color="info"
              variant="outlined"
              size="small"
            />
          </Box>

          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box display="flex" justifyContent="center" alignItems="center">
              <Typography variant="body2" mr={2}>
                In Stock:
              </Typography>
              <Typography variant="subtitle2">
                {isSizeSelected ? selectedItem?.qty : itemGroupTotalStock}
              </Typography>
            </Box>
            <Stack
              component="form"
              onSubmit={handleAddItemsToCart}
              display="flex"
              justifyContent="center"
            >
              <Tabs
                value={isSizeSelected ? selectedItem?.size : false}
                onChange={(event: React.SyntheticEvent, newValue: string) => {
                  setSelectedItem(
                    itemGroupList.find(
                      (i) =>
                        i.size === newValue && i.color === selectedItem?.color
                    )
                  );
                  setIsSizeSelected(true);
                }}
                aria-label="basic tabs example"
                centered
                sx={{ ml: { xs: 0, sm: 10 }, mr: { xs: 0, sm: 10 }, mt: 2 }}
              >
                {item?.hasSize &&
                  itemGroupList
                    .filter((i) => {
                      if (i.size && !uniqueColorsMap[i.size]) {
                        uniqueColorsMap[i.size] = true;
                        return true;
                      }
                      return false;
                    })
                    .sort(
                      (a, b) =>
                        customSizeOrder.indexOf(a.size!.toUpperCase()) -
                        customSizeOrder.indexOf(b.size!.toUpperCase())
                    )
                    .map((i) => (
                      <Tab
                        key={i.id}
                        label={i.size}
                        value={i.size}
                        disabled={
                          !itemGroupList.some(
                            (item) =>
                              item.color === selectedItem?.color &&
                              i.size === item.size
                          )
                        }
                        sx={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          minWidth: { xs: 40, sm: 60, md: 40, lg: 60 },
                        }}
                      />
                    ))}
              </Tabs>
              {/* validation warning */}
              <Collapse
                in={
                  selectedItem?.hasSize &&
                  selectedItem?.size !== null &&
                  buttonClicked &&
                  !isSizeSelected
                }
              >
                <Alert
                  severity="warning"
                  variant="outlined"
                  sx={{ ml: 8, mr: 8, mt: 2 }}
                >
                  Please select a size before adding to cart
                </Alert>
              </Collapse>
              {item?.hasColor && (
                <Tabs
                  value={selectedItem?.color}
                  onChange={(event: React.SyntheticEvent, newValue: string) =>
                    setSelectedItem(
                      itemGroupList.find(
                        (i) =>
                          i.color === newValue && i.size === selectedItem?.size
                      )
                    )
                  }
                  aria-label="basic tabs example"
                  centered
                  sx={{ ml: { xs: 0, sm: 10 }, mr: { xs: 0, sm: 10 }, mt: 2 }}
                >
                  {itemGroupList
                    .filter((i) => {
                      if (i.color && !uniqueColorsMap[i.color]) {
                        uniqueColorsMap[i.color] = true;
                        return true;
                      }
                      return false;
                    })
                    .map((i) => (
                      <Tab
                        key={i.id}
                        icon={
                          <Avatar
                            src={firstImageSrc(i.images)}
                            {...stringAvatar(i.color!)}
                          />
                        }
                        label={i.color}
                        value={i.color}
                        sx={{
                          fontSize: 10,
                          fontWeight: 'bold',
                          minWidth: { xs: 40, sm: 60, md: 40, lg: 60 },
                        }}
                      />
                    ))}
                </Tabs>
              )}
              {/* Price  */}
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ m: 4 }}
              >
                {category?.isPriceable && item?.price && (
                  <Typography variant="subtitle2">
                    ${item?.price.toFixed(2)}
                  </Typography>
                )}
              </Box>
              {/* Quantity control button  */}
              <Box
                sx={{
                  ml: 8,
                  mr: 8,
                  mt: 2,
                }}
              >
                <ButtonGroup
                  variant="outlined"
                  aria-label="outlined button group"
                  fullWidth
                  sx={{ color: 'red' }}
                >
                  <Button
                    sx={{ borderColor: 'black' }}
                    onClick={() => {
                      if (orderQty > 1) setOrderQty(orderQty - 1);
                    }}
                  >
                    <RemoveIcon
                      sx={{ '&:hover': { color: 'secondary.main' } }}
                    />
                  </Button>
                  <TextField
                    id="outlined-controlled"
                    variant="outlined"
                    label="Quantity"
                    size="small"
                    type="number"
                    sx={{
                      fieldset: { borderColor: 'black' },
                      borderRadius: 50,
                      '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button':
                        {
                          display: 'none',
                        },
                      '& input[type=number]': {
                        MozAppearance: 'textfield',
                      },
                      width: 400,
                    }}
                    InputProps={{
                      inputProps: {
                        min: 1,
                        style: {
                          textAlign: 'center',
                        },
                      },
                    }}
                    value={orderQty}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setOrderQty(Number(event.target.value));
                    }}
                  />

                  <Button
                    sx={{ borderColor: 'black' }}
                    onClick={() => setOrderQty(orderQty + 1)}
                  >
                    <AddIcon sx={{ '&:hover': { color: 'secondary.main' } }} />
                  </Button>
                </ButtonGroup>
              </Box>

              {/* size control button */}
              <Button
                variant="contained"
                sx={{ ml: 8, mr: 8, mt: 2 }}
                type="submit"
                disabled={
                  item?.isOrderable === false ||
                  selectedItem?.isOrderable === false ||
                  selectedItem?.isDiscontinued
                }
              >
                {isSizeSelected || !item?.hasSize
                  ? 'Add to Cart'
                  : 'Select a Size'}
              </Button>
              {item?.isOrderable === false ||
              selectedItem?.isOrderable === false ||
              selectedItem?.isDiscontinued ? (
                <Alert severity="info" sx={{ mt: 2, ml: 8, mr: 8 }}>
                  This product is coming soon.
                </Alert>
              ) : null}
            </Stack>

            {/* item description */}
            <Box m={4}>
              <Divider />
              <Typography variant="body2">{item && item.desc}</Typography>
            </Box>
            <Box height={128}></Box>
          </Box>
          {/* replace cart popup */}
          <Dialog
            open={replaceCartOpen}
            onClose={() => setReplaceCartOpen(false)}
            scroll="body"
            fullWidth
            maxWidth="md"
          >
            <DialogTitle id="responsive-dialog-title">
              Replace the cart?
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                The product you're adding is from a different category than the
                products in the cart. All products in the cart must be from one
                category. Do you want to replace the cart?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => setReplaceCartOpen(false)}>
                No
              </Button>
              <Button
                autoFocus
                onClick={() => {
                  if (selectedItem) {
                    replaceCart(selectedItem, orderQty);
                    setReplaceCartOpen(false);
                    setSnackBar({
                      open: true,
                      type: 'success',
                      content: (
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                          }}
                        >
                          <Typography>
                            You replaced the cart with {orderQty}{' '}
                            {selectedItem.size} {selectedItem.color}
                          </Typography>
                          <Box
                            m={1}
                            component="img"
                            bgcolor="white"
                            alt={selectedItem?.name}
                            height={50}
                            width={50}
                            loading="lazy"
                            sx={{
                              cursor: 'pointer',
                              objectFit: 'contain',
                              '&:hover': {
                                opacity: 0.8,
                              },
                            }}
                            src={firstImageSrc(selectedItem?.images)}
                            onError={(e) => {
                              e.currentTarget.src = no_image_png;
                            }}
                          />
                        </Box>
                      ),
                    });
                  }
                }}
              >
                Yes
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ItemDetails;
