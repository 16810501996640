export const reasons = [
  { key: 8, text: 'Field Apparel', value: 'Field Apparel' },
  { key: 1, text: 'Office stock', value: 'Office stock' },
  { key: 2, text: 'Client meeting', value: 'Client meeting' },
  { key: 3, text: 'Tradeshow', value: 'Tradeshow' },
  { key: 4, text: 'Event', value: 'Event' },
  { key: 5, text: 'Employee gift', value: 'Employee gift' },
  { key: 6, text: 'Golf event', value: 'Golf event' },
  { key: 7, text: 'Other', value: 'Other' },
];
