import { ICategory } from 'app/models/category';
import { IItem } from 'app/models/item';
import { IOrderItem } from 'app/models/order';
import { IRequestEmployee } from 'app/models/request';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface ICartStore {
  /* items in cart */
  items: IOrderItem[];
  totalAmount: number;
  addItemsToCart: (newItem: IItem, quantity: number) => void;
  removeItemsFromCart: (newItem: IItem, quantity: number) => void;
  emptyCart: () => void;
  replaceCart: (newItem: IItem, quantity: number) => void;

  /* cart category */
  cartCategory?: ICategory;
  setCartCategory: (cartCategory: ICategory | undefined) => void;

  /* customizedItems in cart*/
  customizedItems: IOrderItem[];
  addCustomizedItemsToCart: (newItem: IItem, quantity: number) => void;
  removeCustomizedItemsFromCart: (newItem: IItem, quantity: number) => void;
  replaceCartWithCustomizedItems: (newItems: IItem[]) => void;

  /* ppe request employee for checkout */
  cartRequestEmployee?: IRequestEmployee;
  setCartRequestEmployee: (employee: IRequestEmployee) => void;
}

export const useCartStore = create<ICartStore>()(
  persist(
    (set, get) => ({
      /* items in cart */
      items: [] as IOrderItem[],
      totalAmount: 0,
      addItemsToCart: (newItem, quantity) => {
        const items = get().items;
        const totalAmount = get().totalAmount;
        const cartItem = items.find((i) => i.item.id === newItem.id);

        if (cartItem) {
          const updatedItems = items.map((i) =>
            i.item.id === newItem.id
              ? {
                  ...i,
                  qty: i.qty + quantity,
                  amount: i.price ? i.price * (i.qty + quantity) : 0,
                }
              : i
          );
          set({
            items: updatedItems,
            totalAmount:
              newItem.price && newItem.hasPrice
                ? totalAmount + quantity * newItem.price
                : 0,
          });
        } else {
          set({
            items: [
              ...items,
              {
                id: newItem.id!,
                itemId: newItem.id!,
                qty: quantity,
                item: newItem,
                price: newItem.price ?? 0,
                amount: newItem.price ? newItem.price * quantity : 0,
              },
            ],
            totalAmount:
              newItem.price && newItem.hasPrice
                ? totalAmount + quantity * newItem.price
                : 0,
          });
        }
      },

      removeItemsFromCart: (newItem, quantity) => {
        const items = get().items;
        let updatedItems: IOrderItem[] = [];

        items.forEach((i) => {
          if (i.item.id === newItem.id && i.qty > quantity) {
            updatedItems.push({
              ...i,
              qty: i.qty - quantity,
              amount: i.price ? i.price * (i.qty - quantity) : 0,
            });
          } else if (i.item.id !== newItem.id) {
            updatedItems.push(i);
          }
        });
        let totalAmount = updatedItems.reduce(
          (acc, i) => acc + (i.item.price ?? 0) * i.qty,
          0
        );

        set({
          items: updatedItems,
          totalAmount: totalAmount,
        });
      },

      emptyCart: () => {
        set({
          items: [],
          customizedItems: [],
          totalAmount: 0,
          cartCategory: undefined,
          cartRequestEmployee: undefined,
        });
      },

      replaceCart: (newItem, quantity) => {
        const updatedItems: IOrderItem[] = [];
        updatedItems.push({
          id: newItem.id!,
          itemId: newItem.id!,
          qty: quantity,
          item: newItem,
          price: newItem.price ?? 0,
          amount: newItem.price ? newItem.price * quantity : 0,
        });
        set({
          items: updatedItems,
          customizedItems: [],
          totalAmount: newItem.price ? newItem.price * quantity : 0,
          cartCategory: newItem.category,
        });
      },

      /* cart category */
      setCartCategory: (cartCategory) => {
        set({
          cartCategory: cartCategory,
        });
      },

      /* ppe request employee for checkout */
      setCartRequestEmployee: (employee) => {
        set({
          cartRequestEmployee: employee,
        });
      },

      /* CustomizedItem in cart */
      customizedItems: [] as IOrderItem[],
      addCustomizedItemsToCart: (newItem, quantity) => {
        const items = get().customizedItems;
        const cartItem = items.find((i) => i.customizedItemId === newItem.id);
        if (cartItem) {
          const updatedItems = items.map((i) =>
            i.customizedItemId === newItem.id
              ? {
                  ...i,
                  qty: i.qty + quantity,
                  amount: 0,
                }
              : i
          );
          set({
            customizedItems: updatedItems,
          });
        } else {
          set({
            customizedItems: [
              ...items,
              {
                id: newItem.id!,
                itemId: -1,
                qty: quantity,
                item: newItem,
                price: 0,
                amount: 0,
                customizedItemId: newItem.id!,
              },
            ],
          });
        }
      },

      removeCustomizedItemsFromCart: (newItem, quantity) => {
        const items = get().customizedItems;
        let updatedItems: IOrderItem[] = [];

        items.forEach((i) => {
          if (i.customizedItemId === newItem.id && i.qty > quantity) {
            updatedItems.push({
              ...i,
              qty: i.qty - quantity,
              amount: 0,
            });
          } else if (i.customizedItemId !== newItem.id) {
            updatedItems.push(i);
          }
        });

        set({
          customizedItems: updatedItems,
        });
      },

      replaceCartWithCustomizedItems: (newItems) => {
        const updatedItems: IOrderItem[] = [];
        newItems.forEach((newItem) => {
          updatedItems.push({
            id: newItem.id!,
            itemId: -1,
            qty: newItem.qty,
            item: newItem,
            price: 0,
            amount: 0,
            customizedItemId: newItem.id!,
          });
        });

        set({
          items: [],
          customizedItems: updatedItems,
          totalAmount: 0,
        });
      },
    }),
    {
      name: 'cart-storage',
    }
  )
);
