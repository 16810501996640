import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import { Alert, Box, Link, Typography } from '@mui/material';
import MessageContainer from 'app/common/modals/MessageContainer';
import React from 'react';
import QuestionAnswer from './QuestionAnswer';

const Faq = () => {
  return (
    <Box>
      <Box m={2}>
        <MessageContainer
          icon={<LiveHelpIcon />}
          message="Frequent Asked Questions"
        />
      </Box>
      <Box m={2}>
        <Alert severity="info" variant="filled" sx={{ fontSize: 24 }}>
          The site formerly called “The Shopping Cart” has been renamed “CED
          Storefront”. The rollout includes sites for Marketing, Events and
          Field Apparel. The purpose of the Storefront is to make obtaining CED
          branded products more convenient for those in need. Field apparel is
          maintained by the Workplace Services Dept. The field apparel section
          is pretty consistent with how it has worked in the past, but we wanted
          to add some clarity for those not familiar with it.
        </Alert>
      </Box>
      <Box m={2}>
        <QuestionAnswer
          question="How do I access the storefront?"
          answer={
            <Typography>
              The storefront can be found on the CED Hub under Your CED /
              Employee Resources or by clicking here.{' '}
              <Link href="https://storefront.collierseng.com/">
                https://storefront.collierseng.com/
              </Link>
            </Typography>
          }
        />
        <QuestionAnswer
          question="Who is eligible for an allowance?"
          answer={
            <Typography>
              The Field Apparel Store is intended for those in positions that
              have been determined as full time in the field.
            </Typography>
          }
        />
        <QuestionAnswer
          question="How much is the allowance and what items does it cover?"
          answer={
            <Typography>
              $250, this does not include basic PPE required to perform the job.
              This is a perk to purchase additional items, such as a duffel bag
              or hat.
            </Typography>
          }
        />
        <QuestionAnswer
          question="What about basic PPE?"
          answer={
            <Typography>
              PPE is automatically provided based on positional requirements.
              These items are determined and approved by the Health and Safety
              Dept.
            </Typography>
          }
        />
        <QuestionAnswer
          question="How long is it valid?"
          answer={
            <Typography>
              Allowances expire 12/31 of every year. New allowances are issued
              January of the following year. Balance DOES NOT carry over.
            </Typography>
          }
        />
        <QuestionAnswer
          question="How does the employee get notified?"
          answer={
            <Typography>
              As new employees are added to the full time field category, they
              receive an email notifying them that they have a $250 allowance to
              spend in the CED field apparel storefront.
            </Typography>
          }
        />
        <QuestionAnswer
          question="What if they find more than $250 worth of items to purchase?"
          answer={
            <Typography>
              There is an option at checkout to pay for the purchase overage
              using a payroll deduction.
            </Typography>
          }
        />
        <QuestionAnswer
          question="What if an employee is not on the list, as a manager I feel they qualify?"
          answer={
            <Typography>
              If you feel an employee was missed, reach out to Lisa DeBenedetto
              to discuss. If the employee is definitely a full time field and
              just didn’t receive the email, please reach out to{' '}
              <Link href="mailto:fieldapparel@collierseng.com">
                fieldapparel@collierseng.com
              </Link>
            </Typography>
          }
        />
        <QuestionAnswer
          question="Any other questions?"
          answer={
            <Typography>
              Please email{' '}
              <Link href="mailto:fieldapparel@collierseng.com">
                fieldapparel@collierseng.com
              </Link>
            </Typography>
          }
        />
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default Faq;
