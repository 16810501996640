import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import DoneIcon from '@mui/icons-material/Done';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { LoadingButton } from '@mui/lab';
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  ListSubheader,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MessageContainer from 'app/common/modals/MessageContainer';
import { VisuallyHiddenInput, stringAvatar } from 'app/common/util/util';
import { ICedCoinOrder } from 'app/models/cedCoin';
import { IEmployee } from 'app/models/user';
import { useCedCoinStore } from 'app/stores/cedCoinStore';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import dayjs, { Dayjs } from 'dayjs';
import { default as CoinTextField } from 'features/checkout/CheckoutTextField';
import React, { ChangeEvent, useEffect, useState } from 'react';
import * as XLSX from 'xlsx';

const AddCedCoins = () => {
  /* State and functions from custom hooks */
  const { setSnackBar, setAddCedCoinOpen } = useModalStore();
  const {
    accumulatedCoinItemList,
    submitCedCoinOrder,
    setTotalCedCoinItemList,
    setCedCoinItemList,
  } = useCedCoinStore();

  const { adminUser, employeeList, employeeListLoading, setEmployeeList } =
    useUserStore();

  /* Define component state variables */
  const [selectedEmployees, setSelectedEmployees] = useState<IEmployee[]>([]);
  const [issuedAmount, setIssuedAmount] = useState<string>('');
  const [expiredTime, setExpiredTime] = useState<Dayjs | null>(
    dayjs().endOf('year')
  );
  const [isUploadMode, setIsUploadMode] = useState<boolean>(false);
  const [file, setFile] = useState<File | null>(null);
  const [headers, setHeaders] = useState<string[]>([]);
  const [selectedHeader, setSelectedHeader] = useState<string>('');
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  /* Function to read the Excel file and extract headers */
  const readHeadersFromExcel = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to read
      const sheet = workbook.Sheets[sheetName];
      const headers: string[] = XLSX.utils.sheet_to_json(sheet, {
        header: 1,
        range: 'A1:Z1', // Assuming headers are in columns A to Z
      })[0] as string[]; // Get the headers from the first row

      setHeaders(headers); // Set the headers state
    };

    reader.readAsBinaryString(file);
  };

  /* Function to handle file change event */
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];

    if (selectedFile) {
      setFile(selectedFile);
      readHeadersFromExcel(selectedFile);
    }
  };

  /* Function to handle header select change event */
  const handleHeaderSelectChange = (e: SelectChangeEvent) => {
    const selectedHeader = e.target.value;
    const selectedHeaderIndex = headers.indexOf(selectedHeader); // Find the index of the selected header

    setSelectedHeader(selectedHeader);

    if (file && selectedHeaderIndex !== -1) {
      readExcel(file, selectedHeaderIndex); // Pass the selected header index to readExcel
    }
  };

  /* Function to read Excel file and filter selected employees */
  const readExcel = (file: File, selectedHeaderIndex: number) => {
    const reader = new FileReader();

    reader.onload = (e: ProgressEvent<FileReader>) => {
      const data = e.target?.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet is the one you want to read
      const sheet = workbook.Sheets[sheetName];

      const columnData: string[] = XLSX.utils
        .sheet_to_json(sheet, {
          header: 1,
          range: 1, // Start from the second row (index 1)
        })
        .map((row: any) => row[selectedHeaderIndex].trim()); // Get the column data for the selected email header

      const selectedEmployees = employeeList.filter(
        (employee) =>
          columnData.includes(employee.email) && employee.email !== ''
      ); // Filter the employee list based on the email column data

      setSelectedEmployees(selectedEmployees); // Set the selectedEmployees state
    };

    reader.readAsBinaryString(file);
  };

  /* Handle form submission */
  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    if (selectedEmployees && issuedAmount.length && expiredTime) {
      const cedCoinOrder: ICedCoinOrder = {
        cedCoinItems: selectedEmployees.map((e) => {
          return {
            email: e.email,
            name: e.displayName
              ? e.displayName
              : `${e.firstName} ${e.lastName}`,
            issuedAmount: Number(issuedAmount) > 0 ? Number(issuedAmount) : 0,
            expiredTime: expiredTime?.toDate(),
            balance: Number(issuedAmount),
            jobTitle: e.jobTitle,
            office: e.office,
            department: e.department,
            discipline: e.discipline,
            employeeNumber: e.employeeNumber,
            issuer: adminUser[0].name,
          };
        }),
        issuedAmount: Number(issuedAmount),
        expiredTime: expiredTime?.toDate(),
        issuer: adminUser[0].name,
      };

      if (cedCoinOrder.issuer === '') return;
      submitCedCoinOrder(cedCoinOrder)
        .then((response) => {
          if (response?.status === 200) {
            setTotalCedCoinItemList();
            setCedCoinItemList(adminUser[0].email);
            setSnackBar({
              open: true,
              type: 'success',
              content: `CedCoin have been adjusted successfully.`,
            });
            setAddCedCoinOpen(false);
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `CedCoin adjusting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetch employee list on component mount */
  useEffect(() => {
    setEmployeeList();
  }, [setEmployeeList]);

  return (
    <Box component="form" onSubmit={handleSubmit}>
      {/* Header */}
      <Box m={2}>
        <MessageContainer
          icon={<CurrencyExchangeIcon />}
          message="New CedCoin"
        />
      </Box>
      {/* Upload mode switch */}
      <Box m={2}>
        <FormControlLabel
          control={
            <Switch
              name="Upload Files"
              checked={isUploadMode}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIsUploadMode(event.target.checked);
                if (!event.target.checked) {
                  setSelectedEmployees([]);
                }
              }}
            />
          }
          label="Upload excel file with email column"
        />
      </Box>
      {/* Upload button and file upload indicator */}
      <Box m={2}>
        {isUploadMode && (
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
          >
            Upload file
            <VisuallyHiddenInput type="file" onChange={handleFileChange} />
          </Button>
        )}
        {isUploadMode && file && (
          <IconButton sx={{ color: 'green' }} aria-label="add to shopping cart">
            <DoneIcon />
          </IconButton>
        )}
      </Box>
      {/* Select header for email column */}
      {isUploadMode && file && (
        <Box m={2} sx={{ bgcolor: 'secondary.dark' }}>
          <FormControl
            fullWidth
            size="small"
            required
            sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
          >
            <InputLabel id="demo-simple-select-label">
              Select Header For Email Column
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedHeader}
              label="Select Header For Email Column"
              onChange={handleHeaderSelectChange}
            >
              {headers.map((h, index) => (
                <MenuItem value={h} key={index}>
                  {h}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      )}
      {/* CedCoin Details */}
      {(!isUploadMode || (isUploadMode && file && selectedHeader)) && (
        <Box>
          {/* Employee options */}
          <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
            <FormControl
              sx={{ minWidth: 120, width: '100%' }}
              size="small"
              required
            >
              <Autocomplete
                id="employee-select-demo"
                size="small"
                sx={{ '& .MuiFormLabel-asterisk': { color: 'warning.main' } }}
                options={employeeList}
                onChange={(_, option: IEmployee[]) =>
                  setSelectedEmployees([...option])
                }
                value={selectedEmployees}
                autoHighlight
                multiple
                loading={employeeListLoading}
                disableCloseOnSelect
                getOptionLabel={(option) =>
                  option.displayName
                    ? option.displayName
                    : `${option.firstName} ${option.lastName}`
                }
                renderOption={(props, option, { selected }) => (
                  <Box
                    width="100%"
                    sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                    component="li"
                    {...props}
                    key={option.email}
                  >
                    <Checkbox
                      icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    <Tooltip title={option.email} placement="bottom">
                      <Typography>
                        {option.displayName
                          ? option.displayName
                          : `${option.firstName} ${option.lastName}`}
                      </Typography>
                    </Tooltip>
                  </Box>
                )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Employees"
                    required
                    inputProps={{
                      ...params.inputProps,
                      required: selectedEmployees.length === 0,
                    }}
                    key={params.id}
                  />
                )}
              />
            </FormControl>
          </Paper>
          {/* Input for entering amounts */}
          <CoinTextField
            label="Amounts"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setIssuedAmount(event.target.value)
            }
            value={issuedAmount}
            m={2}
            required
            startAdornment="$"
            type="number"
            step="0.01"
          />
          {/* Date picker for selecting expiration date */}
          <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Expired Date"
                value={expiredTime}
                onChange={(newValue: Dayjs | null) => setExpiredTime(newValue)}
                disablePast
                sx={{
                  width: '100%',
                  '& .MuiFormLabel-asterisk': { color: 'warning.main' },
                }}
                slotProps={{
                  textField: {
                    size: 'small',
                    required: true,
                  },
                }}
              />
            </LocalizationProvider>
          </Paper>
        </Box>
      )}
      {/* List to display selected employees with balance */}
      {selectedEmployees.length > 0 && issuedAmount && (
        <Box sx={{ m: 2, overflow: 'auto', maxHeight: 400 }}>
          <List
            dense
            subheader={
              <ListSubheader component="div" id="nested-list-subheader">
                After submitting, the CedCoin balance will change:
              </ListSubheader>
            }
            sx={{ bgcolor: 'secondary.dark' }}
          >
            {selectedEmployees.map((e) => (
              <Box key={e.aiod}>
                <Divider variant="inset" component="li" />
                <ListItem alignItems="center">
                  <ListItemAvatar>
                    <Avatar
                      {...stringAvatar(
                        e.displayName
                          ? e.displayName
                          : `${e.firstName} ${e.lastName}`
                      )}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      e.displayName
                        ? e.displayName
                        : `${e.firstName} ${e.lastName}`
                    }
                    secondary={
                      <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Chip
                          component="span"
                          icon={<MonetizationOnIcon color="secondary" />}
                          label={
                            accumulatedCoinItemList
                              .find((i) => i.email === e.email)
                              ?.balance.toFixed(2) ?? '0.00'
                          }
                        />
                        <ArrowForwardIcon />
                        <Chip
                          component="span"
                          icon={<MonetizationOnIcon color="secondary" />}
                          label={
                            accumulatedCoinItemList.find(
                              (i) => i.email === e.email
                            )?.balance !== undefined
                              ? (
                                  accumulatedCoinItemList.find(
                                    (i) => i.email === e.email
                                  )?.balance! + Number(issuedAmount)
                                ).toFixed(2)
                              : Number(issuedAmount).toFixed(2)
                          }
                        />
                      </Box>
                    }
                  />
                </ListItem>
              </Box>
            ))}
          </List>
        </Box>
      )}
      {/* submit button */}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="right"
        mt={10}
        ml={2}
        mr={2}
      >
        <LoadingButton
          variant="contained"
          type="submit"
          loading={submitLoading}
        >
          Submit
        </LoadingButton>
      </Box>
      <Box height={128}></Box>
    </Box>
  );
};

export default AddCedCoins;
