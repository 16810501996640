import SearchIcon from '@mui/icons-material/Search';
import {
  Autocomplete,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  TextField,
} from '@mui/material';
import { no_image_png } from 'app/common/options/noImage';
import { firstImageSrc } from 'app/common/util/util';
import { IItem } from 'app/models/item';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const SearchMenu = () => {
  /* State and functions from custom hooks */
  const { searchOpen, setSearchOpen } = useModalStore();
  const { itemSearchList, itemSearchListLoading, setItemSearchList } =
    useItemStore();
  const navigate = useNavigate();

  /* Define component state variables */
  const [autoCompleteValue, setAutoCompleteValue] = useState<IItem | null>(
    null
  );

  /* Fetch item list for search on component mount */
  useEffect(() => {
    setItemSearchList();
  }, [setItemSearchList]);

  return (
    <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
      {/* search button */}
      <IconButton
        size="large"
        color="primary"
        onClick={() => setSearchOpen(true)}
      >
        <SearchIcon />
      </IconButton>
      {/* search popup */}
      <Dialog
        open={searchOpen}
        onClose={() => setSearchOpen(false)}
        scroll="body"
        fullWidth
        maxWidth="md"
      >
        <DialogContent>
          <Autocomplete
            options={itemSearchList}
            getOptionLabel={(option: IItem) => option.name}
            id="auto-complete"
            autoComplete
            includeInputInList
            onChange={(event, newValue: IItem | null) =>
              setAutoCompleteValue(newValue)
            }
            loading={itemSearchListLoading}
            renderOption={(props, option) => (
              <Box
                component="li"
                width="100%"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...props}
              >
                <Box
                  component="img"
                  bgcolor="white"
                  loading="lazy"
                  alt={option?.name}
                  sx={{
                    objectFit: 'contain',
                    width: { xs: 20, sm: 30, md: 40 },
                  }}
                  src={firstImageSrc(option.images)}
                  onError={(e) => {
                    e.currentTarget.src = no_image_png;
                  }}
                />
                {option.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Search Products"
                variant="standard"
                onKeyDown={(event: any) => {
                  if (event.key === 'Enter') {
                    event.preventDefault();
                    setSearchOpen(false);
                    navigate(
                      `/inventory/${autoCompleteValue?.categoryId}/${autoCompleteValue?.id}`
                    );
                  }
                }}
              />
            )}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default SearchMenu;
