import CategoryIcon from '@mui/icons-material/Category';
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import GradingIcon from '@mui/icons-material/Grading';
import ListAltIcon from '@mui/icons-material/ListAlt';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PaymentsIcon from '@mui/icons-material/Payments';
import QueryStatsIcon from '@mui/icons-material/QueryStats';
import ViewListIcon from '@mui/icons-material/ViewList';
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Paper,
} from '@mui/material';
import { useUserStore } from 'app/stores/userStore';
import React from 'react';
import { Link } from 'react-router-dom';

interface AdminListItemProps {
  to: string;
  primary: string;
  icon: JSX.Element;
}

/* Functional component for each list item in the admin menu */
const AdminListItem = ({ to, primary, icon }: AdminListItemProps) => (
  <ListItem disablePadding>
    <ListItemButton component={Link} to={to}>
      <ListItemIcon>{icon}</ListItemIcon>
      <ListItemText primary={primary} />
    </ListItemButton>
  </ListItem>
);

/* Main admin menu component */
const AdminBoardMenu = () => {
  /* State and functions from custom hooks */
  const { adminUser } = useUserStore();

  return (
    <Paper elevation={3} sx={{ m: 2 }}>
      <List
        sx={{ width: '100%', bgcolor: 'background.pager' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            Admin
          </ListSubheader>
        }
      >
        <AdminListItem
          to="/admin/order"
          primary="Orders"
          icon={<ListAltIcon />}
        />
        <AdminListItem
          to="/admin/product"
          primary="Products"
          icon={<CategoryIcon />}
        />
        <AdminListItem
          to="/admin/product/reorder"
          primary="Product List Reorder"
          icon={<ViewListIcon />}
        />

        {adminUser.some((u) => u.categoryId === 9 || u.isSuperAdmin) && (
          <AdminListItem
            to="/admin/cedcoin"
            primary="CedCoin"
            icon={<CurrencyExchangeIcon />}
          />
        )}
        <AdminListItem
          to="/admin/orderMetrics"
          primary="Order Metrics"
          icon={<QueryStatsIcon />}
        />
        {adminUser.some((u) => u.categoryId === 6 || u.isSuperAdmin) && (
          <AdminListItem
            to={`/admin/ppeRequest/10/0`}
            primary="PPE Request"
            icon={<GradingIcon />}
          />
        )}
        {adminUser.some((u) => u.categoryId === 6 || u.isSuperAdmin) && (
          <AdminListItem
            to={`/admin/ppeRequestOrder/10/0`}
            primary="PPE Request Orders"
            icon={<LocalShippingIcon />}
          />
        )}
        <AdminListItem
          to={`/admin/payrollDeduction`}
          primary="Payroll Deduction"
          icon={<PaymentsIcon />}
        />
      </List>
    </Paper>
  );
};

export default AdminBoardMenu;
