import AddIcon from '@mui/icons-material/Add';
import CategoryIcon from '@mui/icons-material/Category';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Menu,
  MenuItem,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridRowId,
} from '@mui/x-data-grid';
import CustomToolbar from 'app/common/modals/CustomToolbar';
import MessageContainer from 'app/common/modals/MessageContainer';
import { stringAvatar } from 'app/common/util/util';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import AddProduct from './AddProdcut';

const ProductTable = () => {
  /* State and functions from custom hooks */
  const { totalItemList, totalItemListLoading, setTotalItemList } =
    useItemStore();
  const { adminUser } = useUserStore();
  const { addProductOpen, setAddProductOpen } = useModalStore();

  /* Custom Toolbar component for DataGrid */
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);

  /* Close menu handler */
  const handleClose = () => {
    setSelectedRow(null);
    setAnchorEl(null);
    setSelectionModel([]);
  };

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Id#', width: 70 },
    {
      field: 'images',
      headerName: 'Image',
      align: 'center',
      headerAlign: 'center',
      renderCell: (params: GridRenderCellParams<any, string[]>) => (
        <Avatar
          src={
            process.env.REACT_APP_ATTACHMENTS_URL +
            '/' +
            params?.value?.filter((image: string) => image !== '')[0]
          }
          sx={{
            cursor: 'pointer',
            objectFit: 'contain',
            '&:hover': {
              opacity: 0.8,
            },
          }}
          {...stringAvatar(params.row.name)}
        />
      ),
    },
    { field: 'name', headerName: 'Name', width: 400 },

    {
      field: 'category',
      headerName: 'Category',
      width: 300,
      valueGetter: (value, row) => row.category.name,
    },
    {
      field: 'qty',
      headerName: 'Quantity',
      width: 100,
    },
    {
      field: 'size',
      headerName: 'Size',
      width: 100,
    },
    {
      field: 'color',
      headerName: 'Color',
      width: 100,
    },
    {
      field: 'price',
      headerName: 'Price',
      width: 100,
    },

    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={
              selectedRow === params.row.id ? 'long-menu' : undefined
            }
            aria-expanded={selectedRow === params.row.id ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setSelectedRow(params.row.id);
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={selectedRow === params.row.id}
            onClose={handleClose}
            sx={{ width: '20ch' }}
          >
            <MenuItem
              component={Link}
              to={`/admin/product/${params.row.categoryId}/${params.row.id}`}
            >
              Edit
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  useEffect(() => {
    setTotalItemList();
  }, [setTotalItemList]);

  return (
    <Box>
      <Box m={2}>
        <MessageContainer icon={<CategoryIcon />} message="Product" />
      </Box>

      <Box m={2}>
        <Box sx={{ height: 700, width: '100%' }}>
          <DataGrid
            rows={totalItemList.filter((item) =>
              adminUser.some(
                (u) => u.isSuperAdmin || u.categoryId === item.categoryId
              )
            )}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
              setSelectionModel([...newSelection])
            }
            rowSelectionModel={selectionModel}
            loading={totalItemListLoading}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
            }}
            slots={{
              toolbar: () => (
                <CustomToolbar
                  icon={<AddIcon />}
                  label="Add Product"
                  onClick={() => setAddProductOpen(true)}
                />
              ),
            }}
          />
          {/* add product popup */}
          <Dialog
            open={addProductOpen}
            onClose={() => setAddProductOpen(false)}
            scroll="body"
            fullWidth
            maxWidth="md"
          >
            <DialogContent>
              <AddProduct />
            </DialogContent>
          </Dialog>
        </Box>
      </Box>
      {/* footer gap filler */}
      <Box height={128}></Box>
    </Box>
  );
};

export default ProductTable;
