import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { no_image_png } from 'app/common/options/noImage';
import { customizedItemImage } from 'app/common/options/ppeOptions';
import { firstImageSrc, useExtraSmallScreen } from 'app/common/util/util';
import { useCartStore } from 'app/stores/cartStore';
import { useCedCoinStore } from 'app/stores/cedCoinStore';
import React from 'react';
import { Link } from 'react-router-dom';

const CheckoutOrder = () => {
  /* State and functions from custom hooks */
  const { items, cartCategory, totalAmount, customizedItems } = useCartStore();
  const { userCedCoinBalance } = useCedCoinStore();
  const extraSmallScreen = useExtraSmallScreen();

  return (
    <Paper sx={{ m: 2, bgcolor: 'secondary.dark' }}>
      {/* Render the table if there are items in the cart */}
      {(items.length || customizedItems.length) && (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Product</TableCell>
              {!extraSmallScreen && <TableCell align="right"></TableCell>}
              <TableCell align="right">Size</TableCell>
              <TableCell align="right">Color</TableCell>
              {cartCategory?.isPriceable && (
                <TableCell align="right">Amount</TableCell>
              )}
              {customizedItems.length > 0 && (
                <TableCell align="right">Make</TableCell>
              )}
              {customizedItems.length > 0 && (
                <TableCell align="right">Model</TableCell>
              )}
              <TableCell align="right">Quantity</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {items.map((orderItem, index) => (
              <TableRow
                key={index}
                sx={{
                  width: '100%',
                }}
              >
                <TableCell component="th" scope="row">
                  <Link
                    to={`/inventory/${orderItem.item.categoryId}/${orderItem.item.id}`}
                  >
                    <Box
                      component="img"
                      src={firstImageSrc(orderItem.item.images)}
                      height={50}
                      width={50}
                      onError={(e) => {
                        e.currentTarget.src = no_image_png;
                      }}
                      alt={orderItem.item.name}
                      sx={{
                        cursor: 'pointer',
                        objectFit: 'contain',
                        '&:hover': {
                          opacity: 0.8,
                        },
                      }}
                    />
                  </Link>
                </TableCell>
                {!extraSmallScreen && (
                  <TableCell component="th" scope="row">
                    <Typography variant="subtitle2">
                      {orderItem.item.name}
                    </Typography>
                  </TableCell>
                )}

                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {orderItem.item.size}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {orderItem.item.color}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">{orderItem.qty}</Typography>
                </TableCell>
                {cartCategory?.isPriceable && (
                  <TableCell align="right">
                    <Typography variant="subtitle2">
                      ${(orderItem.price * orderItem.qty).toFixed(2)}
                    </Typography>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
          <TableBody>
            {customizedItems.map((orderItem, index) => (
              <TableRow
                key={index}
                sx={{
                  '&:last-child td, &:last-child th': {
                    border: 0,
                  },
                }}
              >
                <TableCell component="th" scope="row">
                  <Box
                    component="img"
                    src={firstImageSrc(orderItem.item.images)}
                    height={50}
                    width={50}
                    onError={(e) => {
                      e.currentTarget.src = customizedItemImage;
                    }}
                    alt={orderItem.item.name}
                    sx={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      '&:hover': {
                        opacity: 0.8,
                      },
                    }}
                  />
                </TableCell>
                <TableCell component="th" scope="row">
                  <Typography variant="subtitle2">
                    {orderItem.item.name}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {orderItem.item.size}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {orderItem.item.color}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {orderItem.item.make}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">
                    {orderItem.item.model}
                  </Typography>
                </TableCell>
                <TableCell align="right">
                  <Typography variant="subtitle2">{orderItem.qty}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            {cartCategory?.isPriceable && (
              <>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {!extraSmallScreen && <TableCell></TableCell>}
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    Subtotal:
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    ${totalAmount.toFixed(2)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {!extraSmallScreen && <TableCell></TableCell>}
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    Credits:
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{
                      fontSize: 14,
                      color: userCedCoinBalance > 0 ? 'red' : 'black',
                    }}
                  >
                    -$
                    {userCedCoinBalance > 0
                      ? userCedCoinBalance > totalAmount
                        ? totalAmount.toFixed(2)
                        : userCedCoinBalance.toFixed(2)
                      : 0}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  {!extraSmallScreen && <TableCell></TableCell>}
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    Total:
                  </TableCell>
                  <TableCell
                    align="right"
                    sx={{ fontWeight: 'bold', fontSize: 14 }}
                  >
                    $
                    {userCedCoinBalance > 0
                      ? userCedCoinBalance > totalAmount
                        ? 0
                        : (totalAmount - userCedCoinBalance).toFixed(2)
                      : totalAmount.toFixed(2)}
                  </TableCell>
                </TableRow>
              </>
            )}
          </TableFooter>
        </Table>
      )}
    </Paper>
  );
};

export default CheckoutOrder;
