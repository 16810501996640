import { Avatar, Box, Button, Stack, Typography } from '@mui/material';
import Loading from 'app/common/modals/Loading';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useModalStore } from 'app/stores/modalStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Category = () => {
  const { categoryList, categoryListLoading } = useCategoryStore();
  const { adminUser, user, locations, locationsLoading, setLocations } =
    useUserStore();
  const { setHomepageDisclaimerOpen } = useModalStore();
  const navigate = useNavigate();

  const stationaryPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 1)
  );

  const marketingStandardStockPermission: boolean = locations.some(
    (l) =>
      l.officeLeadsEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      l.officeLeadsAdminEmail?.toLowerCase() ===
        user?.userPrincipalName?.toLowerCase() ||
      adminUser.some((u) => u.categoryId === 2)
  );

  // TODO: replace with data from database
  const categoryAvatarUrl: { [key: number]: string } = {
    6: '/assets/KN95-mask.jpg',
    9: '/assets/field_gear.jpg',
    7: '/assets/ced_white_hat.jpg',
    5: '/assets/ced_teardrop_banner.jpg',
    2: '/assets/ced-admin.jpg',
    1: '/assets/envelope.jpg',
  };

  const handleCatNavigation = (id: number) => {
    if (id !== 6 && id !== 2 && id !== 1) {
      setHomepageDisclaimerOpen(true, id);
    } else {
      navigate(`/inventory/${id}`);
    }
  };

  /* Fetch admin group users on component mount */
  useEffect(() => {
    setLocations();
  }, [setLocations]);

  console.log(stationaryPermission);

  return (
    <Box bgcolor="secondary.dark" mt={8}>
      <Stack direction="row" justifyContent="center">
        <Typography variant="subtitle2" margin={4}>
          SHOP BY CATEGORY
        </Typography>
      </Stack>

      <Box
        sx={{
          display: { xs: 'block', lg: 'flex' },
          alignItems: { xs: 'center', lg: 'flex-start' },
          justifyContent: 'space-around',
          pb: 4,
          ml: 18,
          mr: 18,
        }}
      >
        {categoryListLoading || locationsLoading ? (
          <Loading />
        ) : (
          categoryList &&
          categoryList
            .filter(
              (c) =>
                (c.id !== 2 &&
                  c.id !== 1 &&
                  (!c.adminOnly || adminUser.length > 0)) ||
                (c.id === 2 && marketingStandardStockPermission) ||
                (c.id === 1 && stationaryPermission)
            )
            .map((cat) => (
              <Box
                key={cat.id}
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'row', lg: 'column' },
                  alignItems: 'center',
                  justifyContent: { xs: 'flex-start', lg: 'center' },
                  mb: { xs: 2, lg: 0 },
                }}
              >
                <Avatar
                  onClick={() => handleCatNavigation(cat.id)}
                  alt={cat.name}
                  src={categoryAvatarUrl[`${cat.id}`] as string}
                  sx={{
                    width: 100,
                    height: 100,
                    m: 2,
                    cursor: 'pointer',
                    '&:hover': { border: '1px solid black' },
                  }}
                />

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Button>
                    <Typography
                      variant="body2"
                      color="black"
                      sx={{ fontSize: { xs: 20, lg: 15 } }}
                      onClick={() => handleCatNavigation(cat.id)}
                    >
                      {cat.name}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            ))
        )}
      </Box>
    </Box>
  );
};

export default Category;
