import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#25408F', //blue
      dark: '#192C64', //dark blue
      light: '#2185d0', //light blue
    },
    secondary: {
      main: '#F5BD02', //yellow
      dark: '#F5F5F7', //grey
      light: '#000000', //black
    },
    statusShipped: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusRestock: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusReturned: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusPickedup: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusCanceled: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusReceived: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusApproved: {
      main: '#023020', //color
      light: '#DFF6DD', //bgcolor
      dark: 'rgba(2, 48, 32, 0.12)', //bgcolorHover
    },
    statusRejected: {
      main: '#ff5733', //color
      light: '#FDE9E7', //bgcolor
      dark: 'rgba(255, 87, 51, 0.12)', //bgcolorHover
    },
    statusRequested: {
      main: '#8b8000', //color
      light: '#FFF4CE', //bgcolor
      dark: 'rgba(139, 128, 0, 0.12)', //bgcolorHover
    },
    statusReviewed: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusPending: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusAdditionalReview: {
      main: '#007FFF', //color
      light: '#f0f7ff', //bgcolor
      dark: 'rgba(0, 127, 255, 0.12)', //bgcolorHover
    },
    statusAwaitingAdditionalReview: {
      main: '#ff5733', //color
      light: '#FDE9E7', //bgcolor
      dark: 'rgba(255, 87, 51, 0.12)', //bgcolorHover
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: ['Open Sans', 'sans-serif'].join(','),
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          margin: 0,
          '&::-webkit-scrollbar': {
            display: 'none',
          } /* Chrome, Edge */,
          scrollbarWidth: 'none' /* Firefox */,
          minHeight: '100vh',
          position: 'relative',
        },

        '@page': {
          size: 'auto',
          margin: '5mm',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontSize: 12,
        },
      },
    },
  },
});

export default theme;
