import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import {
  Autocomplete,
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderInputParams,
  Box,
  PopperProps,
} from '@mui/material';
import { IEmployee } from 'app/models/user';

import React, { JSXElementConstructor, ReactNode } from 'react';

interface FormAutocompleteProps {
  loading?: boolean;
  value: any;
  onChange: (...args: any[]) => void;
  options: IEmployee[];
  renderInput: (params: AutocompleteRenderInputParams) => ReactNode;
  onClose?: (...args: any[]) => void;
  open?: boolean;
  disableCloseOnSelect?: boolean;
  PopperComponent?: JSXElementConstructor<PopperProps>;
  multiple?: boolean;
  renderTags?: (
    value: IEmployee[],
    getTagProps: AutocompleteRenderGetTagProps,
    ownerState: AutocompleteOwnerState<IEmployee, boolean, false, false, 'div'>
  ) => ReactNode;
}

const FormAutocomplete = ({
  loading,
  value,
  onChange,
  options,
  renderInput,
  onClose,
  open,
  disableCloseOnSelect,
  PopperComponent,
  multiple,
  renderTags,
}: FormAutocompleteProps) => {
  return (
    <Autocomplete
      clearIcon={false}
      open={open}
      disableCloseOnSelect={disableCloseOnSelect}
      multiple={multiple}
      PopperComponent={PopperComponent}
      loading={loading}
      autoHighlight
      value={value}
      onChange={onChange}
      onClose={onClose}
      renderTags={renderTags}
      id="controllable-states-demo"
      options={options}
      getOptionLabel={(option: IEmployee) => option.displayName}
      renderInput={renderInput}
      renderOption={(props, option, { selected }) => {
        const { key, ...optionProps } = props;

        return (
          <li
            key={option.email}
            {...optionProps}
            style={{
              borderBottom: `1px solid #eaecef`,
            }}
          >
            <Box
              component={DoneIcon}
              sx={{
                width: 17,
                height: 17,
                mr: '5px',
                ml: '-2px',
              }}
              style={{
                visibility: selected ? 'visible' : 'hidden',
              }}
            />
            <Box
              component="span"
              sx={{
                width: 14,
                height: 14,
                flexShrink: 0,
                borderRadius: '3px',
                mr: 1,
                mt: '2px',
              }}
            />

            <Box
              sx={(t) => ({
                flexGrow: 1,
                '& span': {
                  color: '#8b949e',
                  ...t.applyStyles('light', {
                    color: '#586069',
                  }),
                },
              })}
            >
              {option.displayName}
            </Box>
            <Box
              component={CloseIcon}
              sx={{
                opacity: 0.6,
                width: 18,
                height: 18,
              }}
              style={{
                visibility: selected ? 'visible' : 'hidden',
              }}
            />
          </li>
        );
      }}
    />
  );
};

export default FormAutocomplete;
