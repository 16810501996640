import { Grid, ListItem, ListItemText } from '@mui/material';
import React from 'react';

interface OrderListItemProps {
  leftLabel: string;
  rightLabel?: string;
}

const OrderListItem = ({ leftLabel, rightLabel }: OrderListItemProps) => {
  return (
    <ListItem alignItems="flex-start">
      <Grid container>
        <Grid item xs={12} sm={4}>
          <ListItemText
            primary={leftLabel}
            primaryTypographyProps={{
              fontWeight: 500,
              fontSize: 15,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <ListItemText primary={rightLabel} />
        </Grid>
      </Grid>
    </ListItem>
  );
};

export default OrderListItem;
