import CheckIcon from '@mui/icons-material/Check';
import GradeIcon from '@mui/icons-material/Grade';
import ListAltIcon from '@mui/icons-material/ListAlt';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridColumnVisibilityModel,
  GridFilterInputSingleSelectProps,
  GridFilterItem,
  GridRenderCellParams,
  GridRowId,
  GridToolbar,
} from '@mui/x-data-grid';
import UseGetQuery from 'app/api/useGetQuery';
import Loading from 'app/common/modals/Loading';
import MessageContainer from 'app/common/modals/MessageContainer';
import StatusIcon from 'app/common/modals/StatusIcon';
import { customizedItemImage } from 'app/common/options/ppeOptions';
import { firstImageSrc, stringAvatar } from 'app/common/util/util';
import { IOrder, IOrderItem, IOrderStatus } from 'app/models/order';
import { useCategoryStore } from 'app/stores/categoryStore';
import { useItemStore } from 'app/stores/itemStore';
import { useModalStore } from 'app/stores/modalStore';
import { useOrderStore } from 'app/stores/orderStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import OrderPreview from './OrderPreview';

const OrderTable = () => {
  /* State and functions from custom hooks */
  const {
    orderList,
    orderListLoading,
    order,
    orderLoading,
    setOrder,
    setOrderListInCategory,
  } = useOrderStore();
  const { adminUser } = useUserStore();
  const { orderPreviewOpen, setOrderPreviewOpen } = useModalStore();
  const { itemListForFilter, setItemListForFilter } = useItemStore();
  const { categoryList } = useCategoryStore();

  /* Define component state variables */
  const [selectedRow, setSelectedRow] = useState<null | number>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectionModel, setSelectionModel] = useState<GridRowId[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<number>(
    adminUser[0].categoryId
  );
  const [columnVisibilityModel, setColumnVisibilityModel] =
    useState<GridColumnVisibilityModel>({
      marketingStarterPack: selectedCategory === 2,
      payrollDeduction: selectedCategory === 9,
      payrollDeductionEmailSent: selectedCategory === 9,
    });

  /* Close menu handler */
  const handleClose = () => {
    setSelectedRow(null);
    setAnchorEl(null);
    setSelectionModel([]);
  };

  /* Custom item filter input */
  const ItemsInputValue = (props: GridFilterInputSingleSelectProps) => {
    const { item, applyValue } = props;
    return (
      <FormControl>
        <InputLabel id="demo-simple-select-label">Item</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={item.value ?? ''}
          defaultValue=""
          label="Item"
          autoWidth
          onChange={(event: SelectChangeEvent) =>
            applyValue({ ...item, value: event.target.value })
          }
        >
          {itemListForFilter
            .filter((i) => i.categoryId === selectedCategory)
            .map((i) => (
              <MenuItem value={i.id} key={i.id} sx={{ maxWidth: '100%' }}>
                <ListItemIcon sx={{ m: 1 }}>
                  <Avatar
                    src={firstImageSrc(i.images)}
                    sx={{
                      cursor: 'pointer',
                      objectFit: 'contain',
                      '&:hover': {
                        opacity: 0.8,
                      },
                    }}
                    {...stringAvatar(i.name)}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={i.name}
                  secondary={`${i.size}, ${i.color ?? ''}`}
                ></ListItemText>
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
  };

  /* Columns configuration for DataGrid */
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'Order#', width: 70 },
    {
      field: 'requesterEmail',
      headerName: 'Requester',
      width: 180,
      renderCell: (params: GridRenderCellParams<any, string>) => {
        let response = UseGetQuery(params?.value);
        if (response.loading)
          return <Skeleton variant="rectangular" width={60} height={10} />;
        return (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Avatar
              sx={{
                color: 'white',
                width: 30,
                height: 30,
                mr: 1,
              }}
              src={response.data as string}
              {...stringAvatar(params.row.requester)}
            />
            {params.row.requester}
          </Box>
        );
      },
    },
    { field: 'office', headerName: 'Office', width: 180 },
    { field: 'payrollDeduction', headerName: 'Payroll Deduction', width: 180 },
    {
      field: 'payrollDeductionEmailSent',
      headerName: 'Payroll Email Sent',
      width: 180,
      align: 'center',

      renderCell: (params: GridRenderCellParams<any, IOrder>) => {
        if (params.row.payrollDeductionEmailSent) {
          return (
            <Button
              variant="text"
              size="small"
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              color="success"
              startIcon={<CheckIcon />}
            ></Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'items',
      headerName: 'Items',
      width: 300,
      type: 'singleSelect',
      filterOperators: [
        {
          value: 'contains',
          getApplyFilterFn: (filterItem: GridFilterItem) => {
            if (filterItem.value == null || filterItem.value === '') {
              return null;
            }

            return (value, row, column, apiRef) => {
              return row.items.some(
                (i: IOrderItem) => i.item?.id === filterItem.value
              );
            };
          },

          InputComponent: ItemsInputValue,
        },
      ],

      valueGetter: (value, row) =>
        row.items.map((i: IOrderItem) => {
          if (i.item) return i.item.id;
        }),
      renderCell: (params: GridRenderCellParams<any, string>) => (
        <AvatarGroup max={4} sx={{ flexDirection: 'row' }}>
          {params.row.items.map((i: IOrderItem) => (
            <Avatar
              key={i.id}
              alt={i.item ? i.item.name : i.customizedItem?.name}
              src={i.item ? firstImageSrc(i.item.images) : customizedItemImage}
              sx={{ ml: '-8px !important' }}
            />
          ))}
        </AvatarGroup>
      ),
    },
    {
      field: 'requested',
      headerName: 'Requested Date',
      width: 250,
      valueFormatter: (value) => new Date(value).toLocaleDateString(),
    },
    {
      field: 'status',
      headerName: 'Status',
      headerAlign: 'center',
      width: 200,
      renderCell: (params: GridRenderCellParams<any, IOrderStatus>) => (
        <Button
          variant="contained"
          size="small"
          style={{ marginLeft: 16 }}
          tabIndex={params.hasFocus ? 0 : -1}
          sx={{
            width: 120,
            bgcolor: `status${params?.value?.replaceAll(' ', '')}.light`,
            color: `status${params?.value?.replaceAll(' ', '')}.main`,
            borderColor: `status${params?.value?.replaceAll(' ', '')}.main`,
            '&:hover': {
              bgcolor: `status${params?.value?.replaceAll(' ', '')}.dark`,
            },
            justifyContent: 'left',
          }}
          startIcon={<StatusIcon status={params.value} />}
        >
          {params?.value}
        </Button>
      ),
    },
    {
      field: 'marketingStarterPack',
      headerName: 'Marketing Stater Pack',
      headerAlign: 'center',
      align: 'center',
      width: 180,
      renderCell: (params: GridRenderCellParams<any, IOrderStatus>) => {
        if (params.row.isMarketingStarterPack) {
          return (
            <Button
              variant="text"
              size="small"
              style={{ marginLeft: 16 }}
              tabIndex={params.hasFocus ? 0 : -1}
              color="secondary"
              startIcon={<GradeIcon />}
            ></Button>
          );
        } else {
          return <></>;
        }
      },
    },
    {
      field: 'action',
      headerName: 'Action',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => (
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            aria-controls={
              selectedRow === params.row.id ? 'long-menu' : undefined
            }
            aria-expanded={selectedRow === params.row.id ? 'true' : undefined}
            aria-haspopup="true"
            onClick={(event: React.MouseEvent<HTMLElement>) => {
              setSelectedRow(params.row.id);
              setAnchorEl(event.currentTarget);
            }}
          >
            <MoreVertIcon />
          </IconButton>

          <Menu
            id="long-menu"
            MenuListProps={{
              'aria-labelledby': 'long-button',
            }}
            anchorEl={anchorEl}
            open={selectedRow === params.row.id}
            onClose={handleClose}
            sx={{ width: '20ch' }}
          >
            <MenuItem
              onClick={() => {
                setOrder(params.row.id);
                setOrderPreviewOpen(true);
                handleClose();
              }}
            >
              Preview
            </MenuItem>
            <MenuItem component={Link} to={`/admin/order/${params.row.id}`}>
              Manage
            </MenuItem>
          </Menu>
        </div>
      ),
    },
  ];

  /* Fetching order list and item list for filter on component mount */
  useEffect(() => {
    setOrderListInCategory(adminUser[0].categoryId);
    setItemListForFilter();
  }, [setOrderListInCategory, setItemListForFilter, adminUser]);

  /* Handle changes to category selection */
  useEffect(() => {
    setColumnVisibilityModel((prevModel) => ({
      ...prevModel,
      marketingStarterPack: selectedCategory === 2,
      payrollDeduction: selectedCategory === 9,
      payrollDeductionEmailSent: selectedCategory === 9,
    }));
  }, [selectedCategory]);

  console.log(columnVisibilityModel);
  console.log(orderList);

  return (
    <Box>
      {/* Header */}
      <Box m={2}>
        <MessageContainer icon={<ListAltIcon />} message="Order" />
      </Box>
      {/* Category filter */}
      <Box m={2}>
        <FormControl>
          <FormLabel id="demo-controlled-radio-buttons-group">
            Category
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedCategory}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSelectedCategory(
                Number((event.target as HTMLInputElement).value)
              );
              setOrderListInCategory(
                Number((event.target as HTMLInputElement).value)
              );
            }}
          >
            {categoryList
              ?.filter((c) => adminUser.some((u) => u.categoryId === c.id))
              .map((c) => (
                <FormControlLabel
                  key={c.id}
                  value={c.id}
                  control={<Radio />}
                  label={c.name}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </Box>
      {/* Order Table */}
      <Box m={2}>
        <Box sx={{ width: '100%' }}>
          <DataGrid
            rows={orderList}
            columns={columns}
            checkboxSelection
            onRowSelectionModelChange={(newSelection) =>
              setSelectionModel([...newSelection])
            }
            rowSelectionModel={selectionModel}
            autoHeight
            loading={orderListLoading}
            pageSizeOptions={[10, 50, 100]}
            sx={{
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
              columns: {
                columnVisibilityModel,
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            columnVisibilityModel={columnVisibilityModel} // Bind column visibility model here
            onColumnVisibilityModelChange={(newModel) =>
              setColumnVisibilityModel(newModel)
            } // Optional: Allow external changes to visibility
          />
        </Box>
      </Box>
      {/* order popup preview */}
      <Box>
        <Dialog
          open={orderPreviewOpen}
          onClose={() => {
            setOrderPreviewOpen(false);
            setSelectionModel([]);
          }}
          scroll="body"
          fullWidth
          maxWidth="md"
        >
          <DialogContent>
            {orderLoading ? <Loading /> : <OrderPreview order={order} />}
          </DialogContent>
        </Dialog>
      </Box>
      {/* footer gap filler */}
      <Box height={128}></Box>
    </Box>
  );
};

export default OrderTable;
