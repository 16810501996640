import { useMsal } from '@azure/msal-react';
import AddBoxIcon from '@mui/icons-material/AddBox';
import CancelIcon from '@mui/icons-material/Cancel';
import GradeIcon from '@mui/icons-material/Grade';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import PrintIcon from '@mui/icons-material/Print';
import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
} from '@mui/lab';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import Loading from 'app/common/modals/Loading';
import NotFound from 'app/common/modals/NotFound';
import { IRequest } from 'app/models/request';
import { useModalStore } from 'app/stores/modalStore';
import { useRequestStore } from 'app/stores/requestStore';
import { useUserStore } from 'app/stores/userStore';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ReactToPrint from 'react-to-print';

const RequestOrderDetails = () => {
  /* State and functions from custom hooks */
  const { requestPageSize, requestPage, requestId }: any = useParams();
  const {
    requestForReview,
    requestForReviewLoading,
    setRequestForReview,
    shipRequestOrder,
  } = useRequestStore();
  const { requestOrderShippedOpen, setRequestOrderShippedOpen } =
    useModalStore();
  const { employeeList, setEmployeeList } = useUserStore();
  const { setSnackBar } = useModalStore();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const { accounts } = useMsal();

  /* Define component state variables */
  const [request, setRequest] = useState<IRequest>({} as IRequest);
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const options = ['', 'Shipped', 'Additional Review'];

  const handleClick = () => {
    console.info(`You clicked ${options[selectedIndex]}`);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    setSubmitLoading(true);

    if (request) {
      shipRequestOrder(request)
        .then((response) => {
          if (response?.status === 200) {
            setRequestOrderShippedOpen(true);
          } else {
            setSnackBar({
              open: true,
              type: 'error',
              content: `Request Order submitting is not completed.`,
            });
          }
          setSubmitLoading(false);
        })
        .catch((error) => console.log(error));
    }
  };

  /* Fetch request details on component mount */
  useEffect(() => {
    setRequestForReview(requestId);
    setEmployeeList();
  }, [requestId, setRequestForReview, setEmployeeList]);

  /* Update request details when fetched */
  useEffect(() => {
    if (requestForReview && employeeList) {
      const updatedRequest = {
        ...requestForReview,
        requestEmployees: requestForReview.requestEmployees.map((e) => {
          let matchingEmail;

          if (e.email === undefined || e.email === null || e.email === '') {
            matchingEmail = employeeList.find(
              (employee) => e.employeeNumber === employee.employeeNumber
            )?.email;
          } else {
            matchingEmail = e.email;
          }

          return {
            ...e,
            requestItems: e.requestItems.map((i) => {
              if (
                i.shippedQuantity !== undefined &&
                i.shippedQuantity !== null
              ) {
                return i;
              } else {
                return { ...i, shippedQuantity: i.quantity };
              }
            }),
            email: matchingEmail,
          };
        }),
      };
      setRequest(updatedRequest);
    }
  }, [requestForReview, employeeList]);

  /* Render loading state while request details are loading */
  /* Render not found state if request details are not available */
  if (requestForReviewLoading || Object.keys(request).length === 0) {
    return <Loading />;
  } else if (!requestForReview) {
    return <NotFound />;
  }

  console.log(selectedIndex);
  console.log(request);

  return (
    <Box mx={2} mt={2} component="form" onSubmit={handleSubmit}>
      {/* Buttons for navigation and printing */}
      <Box
        m={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Button
          variant="contained"
          size="small"
          startIcon={<KeyboardReturnIcon />}
          onClick={() => {
            navigate(
              `/admin/ppeRequestOrder/${requestPageSize}/${requestPage}`
            );
          }}
        >
          Back to table
        </Button>
        <ReactToPrint
          content={() => componentRef.current}
          trigger={() => (
            <Button variant="contained" size="small" startIcon={<PrintIcon />}>
              Print Request
            </Button>
          )}
        />
      </Box>
      <Box ref={componentRef}>
        {/* Request header */}
        <Box
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          <Toolbar
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              margin: 2,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                Request ID # : {request?.id}
              </Typography>
              {request.newHire && (
                <Button
                  variant="text"
                  startIcon={<GradeIcon />}
                  color="secondary"
                  sx={{ m: 1 }}
                >
                  New Hire
                </Button>
              )}
            </Box>
            {/* show request status */}
            <Button
              variant="contained"
              size="small"
              style={{ marginLeft: 16 }}
              sx={{
                bgcolor: `status${request?.status?.replaceAll(' ', '')}.light`,
                color: `status${request?.status?.replaceAll(' ', '')}.main`,
                '&:hover': {
                  bgcolor: `status${request?.status?.replaceAll(' ', '')}.dark`,
                },
              }}
            >
              {request?.status}
            </Button>
          </Toolbar>

          <TableContainer component={Paper} elevation={3}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow
                  sx={{
                    '& th': {
                      backgroundColor: 'secondary.dark',
                    },
                  }}
                >
                  <TableCell>Request Date</TableCell>
                  <TableCell>Requester</TableCell>
                  <TableCell>Requester Email</TableCell>
                  <TableCell>Discipline</TableCell>
                  <TableCell>DSC</TableCell>
                  <TableCell>Reviewer</TableCell>
                  <TableCell>Reviewed Date</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>
                    {new Date(
                      request?.createdTime as Date
                    ).toLocaleDateString()}
                  </TableCell>
                  <TableCell>{request?.requester}</TableCell>
                  <TableCell>{request?.requesterEmail}</TableCell>
                  <TableCell>{request?.discipline}</TableCell>
                  <TableCell>{request?.dsc}</TableCell>
                  <TableCell>{request?.reviewer}</TableCell>
                  {request?.reviewed && (
                    <TableCell>
                      {new Date(request.reviewed as Date).toLocaleDateString()}
                    </TableCell>
                  )}
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        <Box
          sx={{
            position: 'fixed',
            bottom: '0px',
            width: '100%',
            background: 'transparent',
            display: 'none',
            fontSize: '10px',
            '@media print': {
              display: 'block',
            },
          }}
        >
          Request ID # : {request?.id}
        </Box>
        {/* Request details table for each employee */}
        <Box m={2}>
          {request?.requestEmployees?.map((e, employeeIndex) => (
            <Box key={e.id}>
              {/* print page break */}
              <Box
                sx={{
                  '@media print': {
                    display: 'block',
                    pageBreakBefore: 'always',
                  },
                  display: 'none',
                }}
              >
                <Toolbar
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    backgroundColor: 'white',
                    margin: 2,
                  }}
                  className="print-content"
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                      Request ID # : {request?.id}
                    </Typography>
                    {request.newHire && (
                      <Button
                        variant="text"
                        startIcon={<GradeIcon />}
                        color="secondary"
                        sx={{ m: 1 }}
                      >
                        New Hire
                      </Button>
                    )}
                  </Box>
                  <Button
                    variant="contained"
                    size="small"
                    style={{ marginLeft: 16 }}
                    sx={{
                      bgcolor: `status${request?.status?.replaceAll(
                        ' ',
                        ''
                      )}.light`,
                      color: `status${request?.status?.replaceAll(
                        ' ',
                        ''
                      )}.main`,
                      '&:hover': {
                        bgcolor: `status${request?.status?.replaceAll(
                          ' ',
                          ''
                        )}.dark`,
                      },
                    }}
                  >
                    {request?.status}
                  </Button>
                </Toolbar>
                <TableContainer component={Paper} elevation={3}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          '& th': {
                            backgroundColor: 'secondary.dark',
                          },
                        }}
                      >
                        <TableCell>Request Date</TableCell>
                        <TableCell>Requester</TableCell>
                        <TableCell>Requester Email</TableCell>
                        <TableCell>Discipline</TableCell>
                        <TableCell>DSC</TableCell>
                        <TableCell>Reviewer</TableCell>
                        <TableCell>Reviewed Date</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>
                          {new Date(
                            request?.createdTime as Date
                          ).toLocaleDateString()}
                        </TableCell>
                        <TableCell>{request?.requester}</TableCell>
                        <TableCell>{request?.requesterEmail}</TableCell>
                        <TableCell>{request?.discipline}</TableCell>
                        <TableCell>{request?.dsc}</TableCell>
                        <TableCell>{request?.reviewer}</TableCell>
                        {request?.reviewed && (
                          <TableCell>
                            {new Date(
                              request.reviewed as Date
                            ).toLocaleDateString()}
                          </TableCell>
                        )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Chip label={employeeIndex + 1} sx={{ m: 1 }} />
                  </Box>
                </Box>
                <TableContainer component={Paper} elevation={2}>
                  <Table aria-label="simple table">
                    <TableHead>
                      <TableRow
                        sx={{
                          '& th': {
                            color: 'white',
                            backgroundColor: 'primary.main',
                          },
                        }}
                      >
                        <TableCell>Employee Number</TableCell>
                        <TableCell>Recipient Name</TableCell>
                        <TableCell>Co Brand</TableCell>
                        <TableCell>Office</TableCell>
                        <TableCell>Ship To</TableCell>
                        <TableCell>Training Completed</TableCell>
                        {request.newHire && <TableCell>WorkFit</TableCell>}

                        <TableCell>Project No</TableCell>
                        <TableCell>Task</TableCell>
                        <TableCell>Org</TableCell>
                        <TableCell>Comments</TableCell>
                        {(e?.status === 'Approved' ||
                          e?.status === 'Additional Review') && (
                          <TableCell>Shipper</TableCell>
                        )}
                        {(e?.status === 'Approved' ||
                          e?.status === 'Additional Review') && (
                          <TableCell>Shipped Date</TableCell>
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell>{e.employeeNumber}</TableCell>
                        <TableCell>{e.name}</TableCell>
                        <TableCell>{e.coBrand}</TableCell>
                        <TableCell>{e.office}</TableCell>
                        <TableCell>{e.shipTo}</TableCell>
                        <TableCell>
                          {e.trainingCompleted ? 'Yes' : 'No'}
                        </TableCell>
                        {request.newHire && (
                          <TableCell>{e.workFit ? 'Yes' : 'No'}</TableCell>
                        )}

                        <TableCell>{e.projectNo}</TableCell>
                        <TableCell>{e.task}</TableCell>
                        <TableCell>{e.org}</TableCell>
                        <TableCell>{e.comments}</TableCell>
                        {(e?.status === 'Approved' ||
                          e?.status === 'Additional Review') && (
                          <TableCell width={120}>{e.shipper}</TableCell>
                        )}
                        {(e?.status === 'Approved' ||
                          e?.status === 'Additional Review') &&
                          e.shippedTime && (
                            <TableCell>
                              {new Date(
                                e.shippedTime as Date
                              ).toLocaleDateString()}
                            </TableCell>
                          )}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                {/* Request details table for each item */}
                <Box m={2}>
                  <TableContainer component={Paper} elevation={1} key={e.id}>
                    <Table aria-label="simple table">
                      <TableHead>
                        <TableRow
                          sx={{
                            '& th': {
                              backgroundColor: 'secondary.dark',
                            },
                          }}
                        >
                          <TableCell>Category</TableCell>
                          <TableCell>Item</TableCell>
                          <TableCell>Type</TableCell>
                          <TableCell>Size</TableCell>
                          <TableCell>Color</TableCell>
                          <TableCell>Make</TableCell>
                          <TableCell>Model</TableCell>
                          <TableCell align="center">
                            Requested Quantity
                          </TableCell>
                          {(e?.status === 'Approved' ||
                            e?.status === 'Additional Review') && (
                            <TableCell align="center">
                              Shipped Quantity
                            </TableCell>
                          )}
                          {(e?.status === 'Approved' ||
                            e?.status === 'Additional Review') && (
                            <TableCell></TableCell>
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {e?.requestItems
                          ?.filter((i) => i.shippedQuantity !== 0)
                          .map((i, itemIndex) => (
                            <TableRow key={itemIndex}>
                              <TableCell>
                                {i.isStandard ? 'Standard' : 'Non-Standard'}
                              </TableCell>
                              <TableCell>{i.item}</TableCell>
                              <TableCell>{i.type}</TableCell>
                              <TableCell>{i.size}</TableCell>
                              <TableCell>{i.color}</TableCell>
                              <TableCell>{i.make}</TableCell>
                              <TableCell>{i.model}</TableCell>
                              <TableCell align="center" sx={{ height: 81 }}>
                                {i.quantity}
                              </TableCell>
                              {e?.status === 'Approved' &&
                                (e.isOrderShipped ? (
                                  <TableCell align="center" sx={{ height: 81 }}>
                                    {i.shippedQuantity}
                                  </TableCell>
                                ) : (
                                  <TableCell align="center">
                                    <Button
                                      disableRipple
                                      size="small"
                                      startIcon={
                                        <IndeterminateCheckBoxIcon
                                          sx={{
                                            m: { xs: 0, sm: 1 },
                                            '&:hover': {
                                              color: 'secondary.main',
                                            },
                                            color: 'green',
                                          }}
                                          onClick={() =>
                                            setRequest((prevRequest) => ({
                                              ...prevRequest,
                                              requestEmployees:
                                                prevRequest.requestEmployees.map(
                                                  (employee, empIndex) => {
                                                    if (
                                                      empIndex === employeeIndex
                                                    ) {
                                                      return {
                                                        ...employee,
                                                        requestItems:
                                                          employee.requestItems.map(
                                                            (item, idx) => {
                                                              if (
                                                                idx ===
                                                                itemIndex
                                                              ) {
                                                                if (
                                                                  !item.shippedQuantity
                                                                )
                                                                  return item;

                                                                if (
                                                                  item.shippedQuantity ===
                                                                    1 &&
                                                                  employee.requestItems.filter(
                                                                    (i) =>
                                                                      i.shippedQuantity !==
                                                                      0
                                                                  ).length > 1
                                                                ) {
                                                                  return {
                                                                    ...item,
                                                                    shippedQuantity: 0,
                                                                  };
                                                                }

                                                                return {
                                                                  ...item,
                                                                  shippedQuantity:
                                                                    item.shippedQuantity >
                                                                    1
                                                                      ? item.shippedQuantity -
                                                                        1
                                                                      : 1,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return employee;
                                                  }
                                                ),
                                            }))
                                          }
                                        />
                                      }
                                      endIcon={
                                        <AddBoxIcon
                                          sx={{
                                            m: { xs: 0, sm: 1 },
                                            '&:hover': {
                                              color: 'secondary.main',
                                            },
                                            color: 'green',
                                          }}
                                          onClick={() =>
                                            setRequest((prevRequest) => ({
                                              ...prevRequest,
                                              requestEmployees:
                                                prevRequest.requestEmployees.map(
                                                  (employee, empIndex) => {
                                                    if (
                                                      empIndex === employeeIndex
                                                    ) {
                                                      return {
                                                        ...employee,
                                                        requestItems:
                                                          employee.requestItems.map(
                                                            (item, idx) => {
                                                              if (
                                                                idx ===
                                                                itemIndex
                                                              ) {
                                                                if (
                                                                  !item.shippedQuantity
                                                                )
                                                                  return item;

                                                                return {
                                                                  ...item,
                                                                  shippedQuantity:
                                                                    item.shippedQuantity +
                                                                    1,
                                                                };
                                                              }
                                                              return item;
                                                            }
                                                          ),
                                                      };
                                                    }
                                                    return employee;
                                                  }
                                                ),
                                            }))
                                          }
                                        />
                                      }
                                      sx={{
                                        color: 'black',
                                        border: 'none',
                                        '&:hover': {
                                          bgcolor: 'transparent',
                                        },
                                      }}
                                    >
                                      {i.shippedQuantity ?? i.quantity}
                                    </Button>
                                  </TableCell>
                                ))}
                              {e?.status === 'Approved' &&
                                (!e.isOrderShipped ? (
                                  <TableCell align="center" width={80}>
                                    <IconButton
                                      aria-label="delete"
                                      size="large"
                                      color="primary"
                                      onClick={() =>
                                        setRequest((prevRequest) => ({
                                          ...prevRequest,
                                          requestEmployees:
                                            prevRequest.requestEmployees.map(
                                              (employee, empIndex) => {
                                                if (
                                                  empIndex === employeeIndex
                                                ) {
                                                  return {
                                                    ...employee,
                                                    requestItems:
                                                      employee.requestItems.map(
                                                        (item, idx) => {
                                                          if (
                                                            idx === itemIndex
                                                          ) {
                                                            if (
                                                              !item.shippedQuantity
                                                            )
                                                              return item;

                                                            return {
                                                              ...item,
                                                              shippedQuantity: 0,
                                                            };
                                                          }
                                                          return item;
                                                        }
                                                      ),
                                                  };
                                                }
                                                return employee;
                                              }
                                            ),
                                        }))
                                      }
                                      disabled={
                                        e.requestItems.filter(
                                          (i) => i.shippedQuantity !== 0
                                        ).length === 1
                                      }
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  </TableCell>
                                ) : (
                                  <TableCell width={80}></TableCell>
                                ))}
                              {e?.status === 'Additional Review' && (
                                <>
                                  <TableCell></TableCell>
                                  <TableCell width={80}></TableCell>
                                </>
                              )}
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Timeline>
                    {requestForReview.requestEmployees[employeeIndex]
                      ?.status === 'Approved' && (
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                bgcolor: `status${requestForReview.requestEmployees[
                                  employeeIndex
                                ]?.status?.replaceAll(' ', '')}.light`,
                              }}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                width: 150,
                                bgcolor: `status${requestForReview.requestEmployees[
                                  employeeIndex
                                ].status?.replaceAll(' ', '')}.light`,
                                color: `status${requestForReview.requestEmployees[
                                  employeeIndex
                                ].status?.replaceAll(' ', '')}.main`,
                                borderColor: `status${requestForReview.requestEmployees[
                                  employeeIndex
                                ].status?.replaceAll(' ', '')}.main`,
                                '&:hover': {
                                  bgcolor: `status${requestForReview.requestEmployees[
                                    employeeIndex
                                  ].status?.replaceAll(' ', '')}.dark`,
                                },
                              }}
                            >
                              {
                                requestForReview.requestEmployees[employeeIndex]
                                  ?.status
                              }
                            </Button>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                bgcolor:
                                  e.isOrderShipped || e.isReassessed
                                    ? `statusShipped.main`
                                    : 'none',
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent>
                            {requestForReview.requestEmployees[employeeIndex]
                              .isOrderShipped ? (
                              <Button
                                variant="contained"
                                size="small"
                                style={{ marginLeft: 16 }}
                                sx={{
                                  width: 150,
                                  bgcolor: `statusShipped.light`,
                                  color: `statusShipped.main`,
                                  borderColor: `statusShipped.main`,
                                  '&:hover': {
                                    bgcolor: `statusShipped.dark`,
                                  },
                                }}
                              >
                                {'Shipped'}
                              </Button>
                            ) : (
                              <React.Fragment>
                                <ButtonGroup
                                  variant="contained"
                                  ref={anchorRef}
                                  aria-label="Button group with a nested menu"
                                  sx={{
                                    ml: 2,
                                  }}
                                >
                                  <Button
                                    onClick={handleClick}
                                    sx={{
                                      width: 150,
                                      bgcolor: `statusShipped.light`,
                                      color: `statusShipped.main`,
                                      borderColor: `statusShipped.main`,
                                      '&:hover': {
                                        bgcolor: `statusShipped.dark`,
                                      },
                                    }}
                                    size="small"
                                  >
                                    {options[selectedIndex]}
                                  </Button>
                                  <Button
                                    size="small"
                                    aria-controls={
                                      open ? 'split-button-menu' : undefined
                                    }
                                    aria-expanded={open ? 'true' : undefined}
                                    aria-label="select merge strategy"
                                    aria-haspopup="menu"
                                    onClick={handleToggle}
                                    sx={{
                                      bgcolor: `statusShipped.light`,
                                      color: `statusShipped.main`,
                                      borderColor: `statusShipped.main`,
                                      '&:hover': {
                                        bgcolor: `statusShipped.dark`,
                                      },
                                    }}
                                  >
                                    <ArrowDropDownIcon />
                                  </Button>
                                </ButtonGroup>
                                <Popper
                                  sx={{
                                    zIndex: 1,
                                  }}
                                  open={open}
                                  anchorEl={anchorRef.current}
                                  role={undefined}
                                  transition
                                  disablePortal
                                >
                                  {({ TransitionProps, placement }) => (
                                    <Grow
                                      {...TransitionProps}
                                      style={{
                                        transformOrigin:
                                          placement === 'bottom'
                                            ? 'center top'
                                            : 'center bottom',
                                      }}
                                    >
                                      <Paper>
                                        <ClickAwayListener
                                          onClickAway={handleClose}
                                        >
                                          <MenuList
                                            id="split-button-menu"
                                            autoFocusItem
                                          >
                                            {options.map(
                                              (option, optionIndex) => (
                                                <MenuItem
                                                  key={option}
                                                  selected={
                                                    optionIndex ===
                                                    selectedIndex
                                                  }
                                                  onClick={(event) => {
                                                    setRequest(
                                                      (prevRequest) => ({
                                                        ...prevRequest,
                                                        requestEmployees:
                                                          prevRequest.requestEmployees.map(
                                                            (
                                                              employee,
                                                              index
                                                            ) => {
                                                              if (
                                                                index ===
                                                                employeeIndex
                                                              ) {
                                                                return {
                                                                  ...employee,
                                                                  isOrderShipped:
                                                                    optionIndex ===
                                                                    1,
                                                                  shipper:
                                                                    optionIndex ===
                                                                    1
                                                                      ? accounts[0]
                                                                          .name
                                                                      : undefined,
                                                                  status:
                                                                    optionIndex ===
                                                                    2
                                                                      ? 'Additional Review'
                                                                      : requestForReview
                                                                          .requestEmployees[
                                                                          employeeIndex
                                                                        ]
                                                                          ?.status,
                                                                  isReassessed:
                                                                    optionIndex ===
                                                                    2,
                                                                  reassessedBy:
                                                                    optionIndex ===
                                                                    2
                                                                      ? accounts[0]
                                                                          .name
                                                                      : employee.reassessedBy,
                                                                };
                                                              }
                                                              return employee;
                                                            }
                                                          ),
                                                      })
                                                    );
                                                    setSelectedIndex(
                                                      optionIndex
                                                    );

                                                    setOpen(false);
                                                  }}
                                                  sx={{ height: 36 }}
                                                >
                                                  {option}
                                                </MenuItem>
                                              )
                                            )}
                                          </MenuList>
                                        </ClickAwayListener>
                                      </Paper>
                                    </Grow>
                                  )}
                                </Popper>
                              </React.Fragment>
                            )}
                          </TimelineContent>
                        </TimelineItem>
                      </>
                    )}
                    {requestForReview.requestEmployees[employeeIndex]
                      ?.status === 'Rejected' && (
                      <TimelineItem>
                        <TimelineSeparator>
                          <TimelineDot
                            sx={{
                              bgcolor: `status${e?.status?.replaceAll(
                                ' ',
                                ''
                              )}.light`,
                            }}
                          />
                        </TimelineSeparator>
                        <TimelineContent>
                          <Button
                            variant="contained"
                            size="small"
                            sx={{
                              ml: 2,
                              width: 150,
                              bgcolor: `status${e?.status?.replaceAll(
                                ' ',
                                ''
                              )}.light`,
                              color: `status${e?.status?.replaceAll(
                                ' ',
                                ''
                              )}.main`,
                              borderColor: `status${e?.status?.replaceAll(
                                ' ',
                                ''
                              )}.main`,
                              '&:hover': {
                                bgcolor: `status${e?.status?.replaceAll(
                                  ' ',
                                  ''
                                )}.dark`,
                              },
                            }}
                          >
                            {e?.status}
                          </Button>
                        </TimelineContent>
                      </TimelineItem>
                    )}
                    {requestForReview.requestEmployees[employeeIndex]
                      ?.status === 'Additional Review' && (
                      <>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                bgcolor: `statusApproved.light`,
                              }}
                            />
                            <TimelineConnector />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                width: 150,
                                bgcolor: `statusApproved.light`,
                                color: `statusApproved.main`,
                                borderColor: `statusApproved.main`,
                                '&:hover': {
                                  bgcolor: `statusApproved.dark`,
                                },
                              }}
                            >
                              Approved
                            </Button>
                          </TimelineContent>
                        </TimelineItem>
                        <TimelineItem>
                          <TimelineSeparator>
                            <TimelineDot
                              sx={{
                                bgcolor: `status${e?.status?.replaceAll(
                                  ' ',
                                  ''
                                )}.light`,
                              }}
                            />
                          </TimelineSeparator>
                          <TimelineContent>
                            <Button
                              variant="contained"
                              size="small"
                              sx={{
                                ml: 2,
                                width: 150,
                                bgcolor: `status${e?.status?.replaceAll(
                                  ' ',
                                  ''
                                )}.light`,
                                color: `status${e?.status?.replaceAll(
                                  ' ',
                                  ''
                                )}.main`,
                                borderColor: `status${e?.status?.replaceAll(
                                  ' ',
                                  ''
                                )}.main`,
                                '&:hover': {
                                  bgcolor: `status${e?.status?.replaceAll(
                                    ' ',
                                    ''
                                  )}.dark`,
                                },
                              }}
                            >
                              Additional Review
                            </Button>
                          </TimelineContent>
                        </TimelineItem>
                      </>
                    )}
                  </Timeline>
                </Box>
              </Box>
            </Box>
          ))}
        </Box>

        <Box>
          <Dialog
            open={requestOrderShippedOpen}
            onClose={() => setRequestOrderShippedOpen(false)}
            scroll="body"
            fullWidth
            maxWidth="md"
          >
            <DialogContent>
              <DialogContentText>Changes has been submitted.</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => {
                  setRequestOrderShippedOpen(false);
                  setRequestForReview(requestId);
                }}
              >
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
        {/* submit button */}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          mt={10}
          ml={2}
          mr={2}
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        >
          {requestForReview.requestEmployees.some(
            (e) => !e.isOrderShipped && e?.status === 'Approved'
          ) && (
            <LoadingButton
              variant="contained"
              type="submit"
              size="large"
              loading={submitLoading}
            >
              Submit
            </LoadingButton>
          )}
        </Box>

        {/* Space filler for footer top */}
        <Box
          height={128}
          sx={{
            '@media print': {
              display: 'none',
            },
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default RequestOrderDetails;
